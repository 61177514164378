import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
// @ts-ignore
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HIGHLIGHT_OPTIONS,
  HighlightModule,
  HighlightOptions,
} from 'ngx-highlightjs';
import { ObservableRequestComponent } from './components/observable-request/observable-request.component';
import { PromiseRequestComponent } from './components/promise-request/promise-request.component';
import { QrCodeComponent } from './components/shared/qr-code/qr-code.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { WebcamModule } from 'ngx-webcam';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DndDirective } from './directives/dnd.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { TopbarComponent } from './components/shared/topbar/topbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './services/auth.service';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { DynamicPageComponent } from './components/pages/dynamic-page/dynamic-page.component';
import { MenuListItemComponent } from './components/shared/menu-list-item/menu-list-item.component';
import { AuthInterceptorService } from './services/auth.interceptor.service';
import { HomeComponent } from './components/pages/home/home.component';
import { NavService } from './services/nav.service';
import { CustomBtnComponent } from './components/shared/custom-btn/custom-btn.component';
import { DataTableComponent } from './components/shared/data-table/data-table.component';
import { CustomTextFieldComponent } from './components/shared/custom-text-field/custom-text-field.component';
import { ActionBarComponent } from './components/shared/action-bar/action-bar.component';
import { AlertComponent } from './components/shared/alert/alert.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { EmailPreviewComponent } from './components/shared/email-preview/email-preview.component';
import { VirtualScrollComponent } from './components/shared/virtual-scroll/virtual-scroll.component';
import { UserSettingsComponent } from './components/shared/user-settings/user-settings.component';
import { CheckboxFilterComponent } from './components/shared/checkbox-filter/checkbox-filter.component';
import { BlankComponent } from './components/shared/blank/blank.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomCheckboxComponent } from './components/shared/custom-checkbox/custom-checkbox.component';
import { CustomFilterComponent } from './components/shared/filter/custom-filter/custom-filter.component';
import { MatSelectModule } from '@angular/material/select';
import { CustomDatepickerComponent } from './components/shared/custom-datepicker/custom-datepicker.component';
import { CustomSelectComponent } from './components/shared/custom-select/custom-select.component';
//import { CustomMultiselectComponent } from './components/shared/custom-multiselect/custom-multiselect.component';
import { CustomMultiselectModule } from './components/shared/custom-multiselect/custom-multiselect.module';
import { FilterPanelComponent } from './components/shared/filter/filter-panel/filter-panel.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { FilterSettingsComponent } from './components/shared/filter/filter-settings/filter-settings.component';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { CustomTablePaginatorComponent } from './components/shared/custom-table-paginator/custom-table-paginator.component';
import { RefactorNamePipe } from './pipes/refactor-name.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LaneSelectionComponent } from './components/pages/lane-selection/lane-selection.component';
import { CustomAutocompleteComponent } from './components/shared/custom-autocomplete/custom-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GeneralComponent } from './components/shared/template/general/general.component';
import { DndComponent } from './components/shared/dnd/dnd.component';
import { DialogComponent } from './components/shared/dialog/dialog.component';
import { HaveActionPipe } from './pipes/have-action.pipe';
import { ProblemDetailComponent } from './components/shared/template/problem-detail/problem-detail.component';
import { DamageDetailComponent } from './components/shared/template/damage-detail/damage-detail.component';
import { CustomSelectValuesComponent } from './components/shared/custom-select-values/custom-select-values.component';
import { DraftDialogComponent } from './components/shared/draft-dialog/draft-dialog.component';
import { FromOtmComponent } from './components/pages/from-otm/from-otm.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { MatNativeDateModule } from '@angular/material/core';
import { DocumentsComponent } from './components/pages/documents/documents.component';
import { BackNavigationDirective } from './directives/utils/back-navigation.directive';
import { CameraRollComponent } from './components/shared/camera-roll/camera-roll.component';
import { GetListTooltipPipe } from './pipes/get-list-tooltip.pipe';
import {NgxImageCompressService} from "ngx-image-compress";
import { ScorecardComponent } from './components/shared/template/scorecard/scorecard.component';
import { LongPressDirective } from './directives/utils/long-press.directive';
import { BtnMenuComponent } from './components/shared/dialog/btn-menu/btn-menu.component';
import { GetValueOnConditionPipe } from './pipes/get-value-on-condition.pipe';
import { ScorecardDetailComponent } from './components/shared/template/scorecard-detail/scorecard-detail.component';
import { RightBtnDirective } from './directives/utils/right-btn.directive';
import { ScorecardEfficiencyProblemDialogComponent } from './components/shared/scorecard-efficiency-problem-dialog/scorecard-efficiency-problem-dialog.component';
import { PncComponent } from './components/pages/pnc/pnc.component';
import { SerialComponent } from './components/pages/serial/serial.component';
import {SerialSelectionComponent} from "./components/pages/serial-selection/serial-selection.component";
import {GeneralService} from "./services/crud/general.service";
import {Observable} from "rxjs";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {CustomIconBtnComponent} from "./components/shared/custom-icon-btn/custom-icon-btn.component";
import {CheckForUpdateService} from "./services/check-for-update.service";
import {BackDialogComponent} from "./components/shared/dialog/back-dialog/back-dialog.component";
import {BadRequestComponent} from "./components/pages/bad-request/bad-request.component";
import {HttpSpinnerInterceptorService} from "./services/http-spinner.interceptor.service";
import {FileToPicturePipe} from "./pipes/file-to-picture.pipe";
import { DialogDownloadComponent } from './components/shared/dialog-download/dialog-download.component';
import { CameraRollV2Component } from './components/shared/camera-roll-v2/camera-roll-v2.component';
import { ScorecardRemarksComponent } from './components/pages/scorecard-remarks/scorecard-remarks.component';
import {TranslationService} from "./services/translation.service";

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {

}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.stsAuthority,
      redirectUri: `${window.location.origin}/ddtmobile`,
      postLogoutRedirectUri: `${window.location.origin}/ddtmobile`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
      iframeHashTimeout: 9000,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.clientScope.toLowerCase().split(' '),
    },
    loginFailedRoute: '/',
  };
}

export function httpTranslateLoader(http: HttpClient, translationService: TranslationService) {
  return new TranslationLoader(http, translationService);
}

export class TranslationLoader implements TranslateLoader {

  constructor(private http: HttpClient, private translationService: TranslationService) { }

  getTranslation(lang: string): Observable<any> {
    return this.translationService.getLanguage(lang);
  }

}

@NgModule({
  declarations: [
    AppComponent,
    ObservableRequestComponent,
    PromiseRequestComponent,
    QrCodeComponent,
    DndDirective,
    TopbarComponent,
    NotFoundComponent,
    DynamicPageComponent,
    MenuListItemComponent,
    HomeComponent,
    DataTableComponent,
    CustomBtnComponent,
    CustomTextFieldComponent,
    ActionBarComponent,
    AlertComponent,
    LoadingComponent,
    EmailPreviewComponent,
    VirtualScrollComponent,
    UserSettingsComponent,
    CheckboxFilterComponent,
    BlankComponent,
    CustomCheckboxComponent,
    CustomFilterComponent,
    CustomDatepickerComponent,
    CustomSelectComponent,
    FilterPanelComponent,
    FilterSettingsComponent,
    CustomTablePaginatorComponent,
    RefactorNamePipe,
    LaneSelectionComponent,
    CustomAutocompleteComponent,
    GeneralComponent,
    DndComponent,
    DialogComponent,
    HaveActionPipe,
    ProblemDetailComponent,
    DamageDetailComponent,
    DraftDialogComponent,
    CustomSelectValuesComponent,
    FromOtmComponent,
    DocumentsComponent,
    BackNavigationDirective,
    CameraRollComponent,
    GetListTooltipPipe,
    ScorecardComponent,
    LongPressDirective,
    BtnMenuComponent,
    GetValueOnConditionPipe,
    ScorecardDetailComponent,
    RightBtnDirective,
    ScorecardEfficiencyProblemDialogComponent,
    PncComponent,
    SerialComponent,
    SerialSelectionComponent,
    CustomIconBtnComponent,
    BackDialogComponent,
    BadRequestComponent,
    FileToPicturePipe,
    DialogDownloadComponent,
    //CustomMultiselectComponent,
    CameraRollV2Component,
    ScorecardRemarksComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatTooltipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    HighlightModule,
    ZXingScannerModule,
    WebcamModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    MatSelectModule,
    CustomMultiselectModule,
    DragDropModule,
    MatExpansionModule,
    MatDialogModule,
    AngularEditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient, TranslationService],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSpinnerInterceptorService,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        lineNumbers: true,
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
        themePath: 'node_modules/highlight.js/styles/github.css',
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/scss'),
          xml: () => import('highlight.js/lib/languages/xml'),
        },
      },
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    NavService,
    NgxImageCompressService,
    CheckForUpdateService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
