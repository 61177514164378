import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DndComponent} from "../dnd/dnd.component";
import {lastValueFrom} from "rxjs";
import {ALERT_TYPE} from "../alert/alert.enumerate";
import {GeneralService} from "../../../services/crud/general.service";
import {AlertService} from "../alert/alert.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import * as _ from 'lodash';
import * as saveAs from 'file-saver';
import {NgxImageCompressService, UploadResponse} from "ngx-image-compress";
import {compress, compressAccurately} from 'image-conversion';


interface Picture {
  pictureId: string;
  pictureName: string;
  pictureType:string ;
  pictureBlob: any;
  pictureUrl: SafeUrl;
}

@Component({
  selector: 'camera-roll',
  templateUrl: './camera-roll.component.html',
  styleUrls: ['./camera-roll.component.scss']
})
export class CameraRollComponent implements OnInit {

  pictures: Picture[] = [];

  isGettingImage: boolean = true;

  pictureMaxSize: number = 500;

  constructor(
    public dialogRef: MatDialogRef<CameraRollComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private generalDataService: GeneralService,
    public alertService: AlertService,
    private sanitizer: DomSanitizer,
    private imageCompress: NgxImageCompressService,
    
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    this.generalDataService.getDetail(this.data.apiUrl + '/info').subscribe({
      next: (res) => {

        this.pictureMaxSize = +res['oContent']
        this.alertService.add({
          type: ALERT_TYPE.INFO,
          message:
            'To comply with the requirements all uploaded images will be compressed to a size of '+ this.pictureMaxSize +' KB',
          timeout: 5000,
          selfClose: null,
        });
      },
      error: (err) => {
        this.alertService.add({
          type: ALERT_TYPE.INFO,
          message:
            'To comply with the requirements all uploaded images will be compressed to a size of '+ this.pictureMaxSize +' KB',
          timeout: 5000,
          selfClose: null,
        });
      }
    })

    
    this.getAllImagePreview();
    

  }



  uploadDoc() {
    
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        confirmBtn: 'Upload',
        cancelBtn: 'Close',
        type: 'image',
      },
    });
    dialogEvent.afterClosed().subscribe((dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        /*if(dndResponse.files.filter((_file: File) => _file.size > 500000).length > 0 || dndResponse.files.map((_file: File) => _file.size).reduce((sum, current) => sum + current.total, 0) > 2000000){
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message:
              'Unable to upload images larger than 500kB or a group of images larger than 2MB',
            timeout: 5000,
            selfClose: null,
          });
          return;
        }*/
        const images = Promise.all(dndResponse.files.map(async (file: File) => {
          const blob = await compressAccurately(file, this.pictureMaxSize);
          return new File([blob], file.name);
        })).then(imageArray => {
          console.log(imageArray);
          lastValueFrom(
            this.generalDataService.uploadPictures(
              this.data.apiUrl,
              imageArray,
              this.data.problemId
            )
          )
            .then((res) => {
              const pictures = _.cloneDeep(res['oContent']);
              this.appendPictures(pictures)
              //this.getImage(picture['PICTURE_ID'], picture['PICTURE_TYPE'], picture['PICTURE_NAME'])
              this.alertService.add({
                type: ALERT_TYPE.SUCCESS,
                message: 'File uploaded',
                timeout: 5000,
                selfClose: null,
              });
            })
            .catch((err) => {
              this.alertService.add({
                type: ALERT_TYPE.WARNING,
                message:
                  'Something went wrong, please try again or contact webmaster',
                timeout: 5000,
                selfClose: null,
              });
            }).finally(() => {
            //this.getAllImagePreview()
          })
        })


      }
    });
  }


  deletePicture(pictureId: string) {
    lastValueFrom(
      this.generalDataService.deletePicture(
        this.data.apiUrl,
        pictureId
      )
    )
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: 'Delete document success',
          timeout: 5000,
          selfClose: null,
        });
        this.data['content'] = this.data['content'].filter(p => p['PICTURE_ID'] != pictureId);
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Delete document was wrong',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.getAllImagePreview();
      });
  }

  private getAllImagePreview() {
    this.pictures = [];
    this.isGettingImage = true;
    /*this.data['content'].forEach(rawPicture => {
      const picture = _.cloneDeep(rawPicture);
      this.getImage(picture['PICTURE_ID'], picture['PICTURE_TYPE'], picture['PICTURE_NAME']);
    })*/

    this.data['content'].forEach(p => {
      
      this.generalDataService.downloadPictures(
        this.data.apiUrl+'/downloads',
        [ p['PICTURE_ID']]
      ).subscribe({
        next: value => {
          this.pictures = this.pictures.concat(value.map((rawPicture) => {
            const objectURL = _.cloneDeep('data:'+rawPicture['fileType']+';base64,' + rawPicture['fileBlob']);
            return {
              pictureId: rawPicture['fileId'],
              pictureType: rawPicture['fileType'],
              pictureName: rawPicture['fileName'],
              pictureBlob: rawPicture['fileBlob'],
              pictureUrl: _.cloneDeep(this.sanitizer.bypassSecurityTrustUrl(objectURL))
            }
          }))
          
        },
        error: err => {
          console.error('Unable to download image')
        },
        complete: () => {
        }
      })
    })
  }

  private getImage(pictureId: string, pictureType: string, pictureName: string){
    const subscription = this.generalDataService.downloadPicture(
      this.data.apiUrl+'/download',
      pictureId
    ).subscribe({
      next: value => {
        let objectURL = URL.createObjectURL(value);
        if(!(this.pictures.some(picture => picture.pictureId == pictureId))){
          this.pictures.push({
            pictureId: pictureId,
            pictureType: pictureType,
            pictureName: pictureName,
            pictureBlob: value,
            pictureUrl: this.sanitizer.bypassSecurityTrustUrl(objectURL)
          });
        }
      },
      error: err => {
        console.error('Unable to download image')
      },
      complete: () => {
        subscription.unsubscribe();
      }
    })
  }

  downloadPictures(pictureId: string, pictureName: string) {
    const subscription = this.generalDataService.downloadPictures(
      this.data.apiUrl+'/downloads',
      this.pictures.map(p => p.pictureId),
      true
    ).subscribe({
      next: value => {
        saveAs(value, 'download.zip');
      },
      error: err => {
        console.error('Unable to download image')
      },
      complete: () => {
        subscription.unsubscribe();
      }
    })
  }

  downloadPicture(pictureId: string, pictureName: string) {

    console.dir(pictureId)

    const subscription = this.generalDataService.downloadPicture(
      this.data.apiUrl+'/download',
      pictureId
    ).subscribe({
      next: value => {
        saveAs(value, pictureName);
      },
      error: err => {
        console.error('Unable to download image')
      },
      complete: () => {
        subscription.unsubscribe();
      }
    })
  }

  private appendPictures(pictures: any) {
    const subscription = this.generalDataService.downloadPictures(
      this.data.apiUrl+'/downloads',
      pictures.map(p => p['PICTURE_ID'])
    ).subscribe({
      next: value => {
        this.pictures = this.pictures.concat(value.map((rawPicture) => {
          let objectURL = 'data:'+rawPicture['fileType']+';base64,' + rawPicture['fileBlob'];
          return {
            pictureId: rawPicture['fileId'],
            pictureType: rawPicture['fileType'],
            pictureName: rawPicture['fileName'],
            pictureBlob: rawPicture['fileBlob'],
            pictureUrl: this.sanitizer.bypassSecurityTrustUrl(objectURL)
          }
        }))
        //saveAs(value, 'download.zip');
      },
      error: err => {
        console.error('Unable to download image')
      },
      complete: () => {
        subscription.unsubscribe();
      }
    })
  }

  update(){
    //location.reload()
    this.getAllImagePreview();
  }

  processFile(imageInput: HTMLInputElement) {
    const images = Promise.all(Array.from(imageInput.files ?? []).map(async (file: File) => {
      const blob = await compressAccurately(file, this.pictureMaxSize);
      return new File([blob], file.name);
    })).then(imageArray => {
      console.log(imageArray);
      lastValueFrom(
        this.generalDataService.uploadPictures(
          this.data.apiUrl,
          imageArray,
          this.data.problemId
        )
      )
        .then((res) => {
          const pictures = _.cloneDeep(res['oContent']);
          this.appendPictures(pictures)
          //this.getImage(picture['PICTURE_ID'], picture['PICTURE_TYPE'], picture['PICTURE_NAME'])
          this.alertService.add({
            type: ALERT_TYPE.SUCCESS,
            message: 'File uploaded',
            timeout: 5000,
            selfClose: null,
          });
        })
        .catch((err) => {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message:
              'Something went wrong, please try again or contact webmaster',
            timeout: 5000,
            selfClose: null,
          });
        }).finally(() => {
        //this.getAllImagePreview()
      })
    })
  }
}
