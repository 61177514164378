import {Injectable} from '@angular/core';
import {Location} from '@angular/common'
import {NavigationEnd, Router} from "@angular/router";
import {DialogComponent} from "../../components/shared/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";
import * as _ from 'lodash';
import {NavItem} from "../../models/NavItem";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = []

  private navItems: NavItem[] = []

  constructor(
    private router: Router,
    private location: Location,
    public dialog: MatDialog
  ) {
    // Listen to router events of type NavigationEnd to
    // manage an app-specific navigation history.
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length > 0) {
          const fromUrl = this.history.pop();
          const toUrl = event.urlAfterRedirects;
          if (((fromUrl?.split('?')[0]) ?? '') == (toUrl.split('?')[0])) {
            this.history.push(event.urlAfterRedirects);
          } else {
            this.history.push(fromUrl ?? '/');
            this.history.push(toUrl);
          }
        } else {
          this.history.push(event.urlAfterRedirects);
        }

      }
    })
  }

  setNavigationItems(navItems: NavItem[]) {
    this.navItems = navItems;
  }

  getNavigationItems() {
    return this.navItems;
  }

  findNavItemElemByUrl(url: string){
    return this.find_nested_navitem_link(this.navItems, url);
  }

  private find_nested_navitem_link = (menuList: NavItem[], path) : NavItem | null => (
    menuList.reduce((found, item) => {
      if (found) return found
    if (item.children && item.children.length > 0) {
        return this.find_nested_link(item.children, path)
      }

      return item.route == path ? item : null
    }, null)
  )

  changeQueryParmas(url: string) {
    /*console.log(url)
    console.log(this.navItems)*/
    return this.find_nested_link(this.navItems, url);
  }

  private find_nested_link = (menuList: NavItem[], path) => (
    menuList.reduce((found, item) => {
      if (found) return found
      if (item.children && item.children.length > 0) {
        return this.find_nested_link(item.children, path)
      }

      return item.route == path ? item.pageId : null
    }, null)
  )


  /**
   * Manage back navigation.
   */
  back(): void {
    let historyNow = this.history.pop() || '/';

    // If the history still contains entries after popping
    // the current URL off of the stack, we can safely
    // navigate back. Otherwise we're falling back to the
    // application root.
    if (this.history.length > 0) {
      let historyOld = this.history.pop() || '/';
      if (historyNow.split('?')[0] == historyOld.split('?')[0]) {
        if (historyOld != '/') {
          this.history.push(historyOld);
          this.back()
        } else {
          this.router.navigateByUrl('/');
        }
      } else {
        this.router.navigateByUrl(historyOld);
      }

    } else {
      this.router.navigateByUrl('/');
    }
  }

  home(){
    this.router.navigateByUrl('/');
  }

  compareAndBack(masterFrom: any, compareTo: any) {
    if (!_.isEqual(masterFrom, compareTo)) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved changes',
          content: `If you leave this page the creations or changes you have made so far will not be saved. How would you like to proceed?`,
          cancelButtonLabel: 'Stay',
          cancelButtonColor: 'basic',
          confirmButtonLabel: 'Leave',
          confirmButtonColor: 'accent',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res && res == true) {
          this.back()
        }
      });
    } else {
      this.back()
    }
  }


  compareAndGoTo(api: string, param: { id: string; title: string; [key: string]: string },masterFrom: any, compareTo: any) {
    if (!_.isEqual(masterFrom, compareTo)) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved changes',
          content: `If you leave this page the creations or changes you have made so far will not be saved. How would you like to proceed?`,
          cancelButtonLabel: 'Stay',
          cancelButtonColor: 'basic',
          confirmButtonLabel: 'Leave',
          confirmButtonColor: 'accent',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res && res == true) {
          this.router.navigate([api], {queryParams: param})
        }
      });
    } else {
      this.router.navigate([api], {queryParams: param})
    }
  }

  goTo(api: string, param: { id: string; title: string }, compare: boolean = true) {
    if (compare) {
      if (localStorage.getItem('changes') == 'true') {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Unsaved changes',
            content: `If you leave this page the creations or changes you have made so far will not be saved. How would you like to proceed?`,
            cancelButtonLabel: 'Stay',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Leave',
            confirmButtonColor: 'accent',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res && res == true) {
            this.router.navigate([api], {queryParams: param})
          }
        });

      } else {
        this.router.navigate([api], {queryParams: param})
      }
    } else {
      this.router.navigate([api], {queryParams: param})
    }
  }

  size() {
    return this.history.length;
  }
}
