import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/services/crud/general.service';
import { LayoutServiceService } from 'src/app/services/layout.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TableControllerService } from 'src/app/services/table-controller.service';
import { AlertService } from '../alert/alert.service';
import { NavigationService } from 'src/app/services/utils/navigation.service';
import { Document, DocumentsComponent } from '../../pages/documents/documents.component';
import { last } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { ALERT_TYPE } from '../alert/alert.enumerate';
import * as saveAs from "file-saver";
@Component({
  selector: 'app-dialog-download',
  templateUrl: './dialog-download.component.html',
  styleUrls: ['./dialog-download.component.scss'],
  providers: [TableControllerService],
})
export class DialogDownloadComponent implements OnInit {

  lDoc : Document[] = []

  constructor(
    private route: ActivatedRoute,
    public layoutService: LayoutServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public generalService: GeneralService,
    public problemsTableController: TableControllerService,
    public alertService: AlertService,) {
    


   }

  ngOnInit(): void {
    this.lDoc= this.data['content']
  }

  download(name:string, id:string){
    lastValueFrom(
      this.generalService.downloadDocument("documents/other" + "/download", id)
    ).then((blob)=>{
      if(blob.size==0){
        this.alertService.add({
          type:ALERT_TYPE.WARNING,
          message: "There is no file associated with the scorecard" + id,
          timeout: 5000,
          selfClose: null,
        });
        return;
      }else{
        saveAs(blob,name);
      }
    })    .catch((err) => {
      this.alertService.add({
        type: ALERT_TYPE.WARNING,
        message: "Something went wrong during the download ",
        timeout: 5000,
        selfClose: null,

      });

    });

  }

  del(documentId:string,documentsId:string){


    lastValueFrom(
      this.generalService.deleteDocument("documents/other",documentsId,documentId) 
        )
        .then((blob) => {
          if (blob.size == 0) {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message:
                "There is no file associated with the scorecard" + documentId,
              timeout: 5000,
              selfClose: null,
            });
            return;
          } else {
            //saveAs(blob, nome);
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: "Resource deleted successfully",
              timeout:5000,
              selfClose:null,
              
            }),
            window.location.reload();
  
        }
        })
        .catch((err) => {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message: "Something went wrong during the delete ",
            timeout: 5000,
            selfClose: null,
    
          });
    
        });
  }

}
