import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, interval, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { MessagingService } from "./utils/messaging.service";
import {SpinnerService} from "./spinner.service";
import {AnnounceService} from "./announcements.service";
import { cloneDeep } from "lodash";

@Injectable()
export class HttpSpinnerInterceptorService implements HttpInterceptor {
  constructor(private spinner: SpinnerService, private aS: AnnounceService) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(!req.url.includes('util') && !req.url.includes('lang')){

      const queryparams = req.urlWithParams ?? '';
      const tablePattern = /table=%22([^%]+)%22;/;
      const tableMatch = queryparams.match(tablePattern);

      let tableSearchKey = '';
      if (tableMatch ) {
        const table = tableMatch[1];
        tableSearchKey = table;
      }

      //this.spinner.showWithTitle('data', tableSearchKey);
      return next.handle(req).pipe(map(event => {

        if(event instanceof HttpResponse){
          if(Object.keys(event.body).includes('announcements')){
            this.aS.replaceAllert((event.body?.announcements?.map(announce => {
              const self = cloneDeep({ // Store a reference to the object
                id: announce.id,
                priority: announce.priority,
                text: announce.text,
                message_goes_on: announce.message_goes_on,
                message_goes_off: announce.message_goes_off,
                block_users_starts: announce.block_users_starts,
                interval: interval(5000).pipe(tap(temp => {
                  if (new Date(self.block_users_starts) <= new Date() && new Date(self.message_goes_off) > new Date()) {
                    self.priority = 'custom'; // Access object's properties using sel
                    self.interval.unsubscribe();
                    this.spinner.hideAll();
                    //this.navigation.home();
                  } else if (new Date(announce.message_goes_off) <= new Date()) {
                    self.interval.unsubscribe();
                    this.spinner.hideAll();
                    //this.navigation.home();
                  }
                })).subscribe()
              });
              return self;
            }) ?? []))

            return event.clone({
              body: event.body?.data
            });
          }
        }


        return event;
      }))
        /*.pipe(tap({
          next: value => {
            if (value instanceof HttpResponse) {
              this.spinner.hide();
            }
          },
          error: err => {
            this.spinner.hide();
          }
        }));*/
    }else{
      return next.handle(req).pipe(map(event => {

        if(event instanceof HttpResponse){
          if(Object.keys(event.body).includes('announcements')){
            this.aS.replaceAllert((event.body?.announcements?.map(announce => {
              const self = cloneDeep({ // Store a reference to the object
                id: announce.id,
                priority: announce.priority,
                text: announce.text,
                message_goes_on: announce.message_goes_on,
                message_goes_off: announce.message_goes_off,
                block_users_starts: announce.block_users_starts,
                interval: interval(5000).pipe(tap(temp => {
                  if (new Date(self.block_users_starts) <= new Date() && new Date(self.message_goes_off) > new Date()) {
                    self.priority = 'custom'; // Access object's properties using sel
                    self.interval.unsubscribe();
                    this.spinner.hideAll();
                    //this.navigation.home();
                  } else if (new Date(announce.message_goes_off) <= new Date()) {
                    self.interval.unsubscribe();
                    this.spinner.hideAll();
                    //this.navigation.home();
                  }
                })).subscribe()
              });
              return self;
            }) ?? []))

            return event.clone({
              body: event.body?.data
            });
          }
        }


        return event;
      }))
    }

  }

}
