import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NavigationService} from "../../services/utils/navigation.service";
import {Required} from "../../utils/requiredInputs";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../components/shared/dialog/dialog.component";

@Directive({
  selector: '[backNavigation]'
})
export class BackNavigationDirective {

  @Input()
  masterFrom:  any;

  @Input()
  compareTo:  any;

  @Input()
  withoutcompare?:  boolean = false;

  @Input()
  overriderPath?:  string;

  constructor(private navigation: NavigationService, public dialog: MatDialog, private el: ElementRef) {

  }

  @HostListener('click')
  onClick(): void {
    if(this.withoutcompare){
      if(!!this.overriderPath){
        const navItem = this.navigation.findNavItemElemByUrl(this.overriderPath)
        this.navigation.goTo(this.overriderPath, {
          id: navItem?.pageId ?? '',
          title: navItem?.displayName ?? ''
        }, false)
      }else{
        this.navigation.back();
      }

    }else{
      if(!!this.overriderPath){
        const navItem = this.navigation.findNavItemElemByUrl(this.overriderPath)
        this.navigation.compareAndGoTo(this.overriderPath, {
          id: navItem?.pageId ?? '',
          title: navItem?.displayName ?? ''      
        }, this.masterFrom, this.compareTo);
      }else{
        
        
        

        this.navigation.compareAndBack(this.masterFrom, this.compareTo);
      }

    }
  }

}


export interface OVERRIDE_NAVIGATION {
  api: string,
  param: {id: string, title: string}
}
