import { EventEmitter, Injectable, Output } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { PageEventType } from '../components/shared/custom-table-paginator/custom-table-paginator.component';
import { IMultiSelectOption } from '../components/shared/custom-multiselect/types';

@Injectable()
export class TableControllerService {
  dataSet!: any[];
  deletedRows: any[] = [];

  row: any;
  rowNumber: number = -1;
  selectedRow: any[] = [];
  elemLoaded: Observable<number> = of(this.dataSet?.length);

  selection = new SelectionModel<any>(true, []);

  numElementsLoaded: number = 0;
  numElementsSize: number = 0;

  private errors: Map<number, Map<string, boolean>> = new Map<number, Map<string, boolean>>()

  @Output()
  onPaginationChange: EventEmitter<PageEventType> =
    new EventEmitter<PageEventType>();

  selectableValuesMap: Map<string, IMultiSelectOption[]> = new Map<
    string,
    IMultiSelectOption[]
  >();

  @Output()
  onSortChange: EventEmitter<{
    sorting: Map<string, { order: number; type: 'asc' | 'desc' }>;
    pageEvent: PageEventType;
  }> = new EventEmitter<{
    sorting: Map<string, { order: number; type: 'asc' | 'desc' }>;
    pageEvent: PageEventType;
  }>();

  constructor() {}

  onAddClick(newRowObj: any) {
    newRowObj.newRow = true;

    if (!!this.dataSet && this.dataSet.length > 0) {
      this.dataSet = [_.cloneDeep(newRowObj), ...this.dataSet];
    } else {
      this.dataSet = [_.cloneDeep(newRowObj)];
    }

    ////(newRowObj);
    return this.dataSet;
  }

  editRow(index: number) {
    ////(this.dataSet![index]);
    this.dataSet[index].backup = _.cloneDeep(this.dataSet[index]);
    this.dataSet[index].editing = true;
  }

  exitRow(index: number) {
    if (!!this.dataSet[index].newRow) {
      this.dataSet.splice(index, 1);
      //delete this.dataSet[index].newRow;
    } else {
      this.dataSet[index] = this.dataSet[index].backup;
      delete this.dataSet[index].editing;
    }
  }

  saveRow(index: number) {
    delete this.dataSet[index]['newRow'];
    this.dataSet[index].checked = true;

    return this.dataSet;
  }

  commitEdit(index: number) {
    delete this.dataSet[index]['editing'];
  }

  commitEditDetail(index: number) {
    if (!!this.dataSet) this.row = this.dataSet[index];
  }

  saveAll(rows: any) {
    ////('rows to save:', rows);
    ////('rows to delete:', this.deletedRows);
    this.deletedRows = [];
  }

  deleteRow(index: number) {
    if (this.dataSet[index].newRow == undefined) {
      this.deletedRows.push(this.dataSet[index]);
      //this.dataSet.splice(index, 1);
      ////('existing row deleted.');
    } else {
      //this.dataSet!.splice(index, 1);
      ////('new row deleted.');
    }

    return this.dataSet;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = !!this.selection ? this.selection.selected.length : 0;
    const numRows = !!this.dataSet ? this.dataSet.length : 0;
    return !!this.dataSet ? numSelected === numRows : false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSet);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  inError() {
    return [...this.errors.values()].some((value) => {
      return [...value.values()].some((value) => value)
    })
  }

  getErrors(i: number, columnName: any) {
    if (this.errors.has(i)) {
      if (this.errors.get(i)!.has(columnName)) {
        return this.errors.get(i)!.get(columnName);
      } else {
        this.errors.get(i)!.set(columnName, false);
        return this.errors.get(i)!.get(columnName);
      }
    } else {
      this.errors.set(i, (new Map<string, boolean>()).set(columnName, false));
      return this.errors.get(i)!.get(columnName);
    }
  }

  deleteErrors(i: number) {
    if (this.errors.has(i)) {
      this.errors.delete(i);
    }
  }

  setErrors($event: boolean, i: number, columnName: any) {
    this.errors.get(i)?.set(columnName, $event);
  }

  customizeAfterAddClick(newRowObj: any) {
    return newRowObj;
  }
}
