import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {
  FilterOption,
  FilterValue,
} from '../filter/custom-filter/filter.interface';
import { IMultiSelectOption } from '../custom-multiselect/types';
import {cloneDeep} from "lodash";

export interface SelectType {
  label: String;
  value: any;
}

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit {
  @Input()
  label: String | undefined;

  @Input()
  placeholder: String | undefined;

  @Input()
  values?: any[];

  @Input()
  defaultValue: any;

  @Input()
  disabled: Boolean | undefined = false;

  @Output()
  defaultValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  columnName?: string;

  @Output()
  columnChange: EventEmitter<string> = new EventEmitter<string>()

  @Input()
  extraClass: string = '';

  @Input()
  preventIdChange: boolean = false;

  localValue?: any;

  constructor() {}

  ngOnInit(): void {
    if(!this.defaultValue && this.values?.length){
      this.defaultValue = this.values[0].id;
      this.onChange(this.values[0].id);
    }
  }

  ngOnChanges(){
    if(!this.haveAction(this.defaultValue)){
      this.localValue=cloneDeep(this.defaultValue)
    }
    console.log(this.defaultValue)
  }

  onChange(event: any): void {
    if(!this.preventIdChange){
      if(this.values)
        this.defaultValue = this.values.find(elem => elem.id == event)
    }
    this.defaultValueChange.emit(event);
    this.columnChange.emit(this.columnName ?? '');
  }

  haveAction(value){

    return this.values?.some(option => option.id == value)
  }
}
