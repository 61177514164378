import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  firstValueFrom,
  lastValueFrom,
  Observable,
  ObservableNotification,
  of,
} from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { camelToSnakeCase } from '../../utils/generic';
import {cloneDeep} from "lodash";
import { Announce } from 'src/app/app.component';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private httpClient: HttpClient) {}

  getData(
    apiPath: string,
    id?: string,
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    if (!!id) params = params.set('id', id);

    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, { params });
  }

  getDetail(
    apiPath: string,
    id?: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    //(id)
    if (!!id) params = params.set('id', id);

    return this.httpClient.get<any>(environment.apiUrl + apiPath, { params });
  }

  postData(
    apiPath: string,
    rows: any[],
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.post<any[]>(
      environment.apiUrl + apiPath,
      rows,
      httpoptions
    );
  }

  deleteData(apiPath: string, deletedRows: any[], params: HttpParams = new HttpParams()) {
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.patch<any[]>(
      environment.apiUrl + apiPath,
      deletedRows,
      httpoptions
    );
  }

  exportDataExcel(
    apiPath: string,
    fileType: string,
    params: HttpParams,
    exportRows: any[]
  ) {
    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.post<any[]>(
      environment.apiUrl + apiPath + '/download/' + fileType,
      exportRows,
      httpoptions
    );
  }

  getSelectionOption(valuesFrom: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('q', valuesFrom),
    };
    return lastValueFrom(
      this.httpClient.get<any>(
        environment.apiUrl + 'util/autocomplete',
        httpHeader
        )

      );

  }

  getSelectionOptionForId(valuesFrom: string, filter: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('q', valuesFrom).set('f', filter),
    };

    return lastValueFrom(
      this.httpClient.get<any>(
        environment.apiUrl + 'util/autocomplete',
        httpHeader
      )
    );
  }

  getVersion() {
    return this.httpClient.get<any>(environment.apiUrl + 'util/version');
  }

  /*postDataDetail(apiUrl: string, params: HttpParams = new HttpParams(), detail: any) {
    if (!!detail) {
      return this.httpClient.post<any[]>(
        environment.apiUrl + apiPath,
        detail
      );
    }
    return of(null)
  }*/

  postDataDetail(
    apiPath: string,
    row: any,
    action?: string
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams();
    if (!!action) {
      params = params.set('a', action);
    }

    return this.httpClient.post<any[]>(environment.apiUrl + apiPath, row, {
      params,
    });
  }

  postSerialDetail(
    apiPath: string,
    row: any,
    problem: string,
    pnc: string
  ): Observable<any[]> {
    //(action)
    /*_.each(row, function(value, key) {
      const oldKey = _.cloneDeep(key)
      key = camelToSnakeCase(key) || key;
      row[key] = value;
      delete row[oldKey];
    });*/

    let params = new HttpParams().set("problemId", problem).set('pnc', pnc);
    return this.httpClient.post<any[]>(environment.apiUrl + apiPath, row, {
      params,
    });
  }

  deleteDetailData(apiUrl: string, idsToDelete: any, params: HttpParams = new HttpParams()) {
    const httpoptions: Object = {
      params,
    };
    return this.httpClient.patch<any[]>(
      environment.apiUrl + apiUrl,
      idsToDelete,
      httpoptions
    );
  }

  getAllertByValidity() {
    const params: HttpParams = new HttpParams()
    return this.httpClient.get<{
      oContent: Announce[]
      iResponseCode: number
      sResponseMessage: string
    }>(  environment.authApiUrl + 'util/announcements/DDTMOBILE', {params});
  }


  changeStatus(apiUrl: string, id: string, status: string) {
    const params = new HttpParams().set('a', status).set('id', id);
    return this.httpClient.put<any>(
      environment.apiUrl + apiUrl,
      {},
      { params }
    );
  }

  getDocuments(
    apiPath: string,
    type: string,
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    params = params.set('type', type);

    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, { params });
  }

  getDocumentsById(
    apiPath: string,
    params: HttpParams = new HttpParams()
  ): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, { params });
  }

  uploadDoc(s: string, documentsId: string, file: File) {
    const formData: FormData = new FormData();
    formData.append('document', file, file.name);
    const params = new HttpParams()
      .set('file_type', file.type)
      .set('documents_id', documentsId);
    return this.httpClient.post<any>(environment.apiUrl + s, formData, {
      params,
    });
  }

  deleteDocument(s: string, documentsId: string, documentId: string) {
    const params = new HttpParams()
      .set('documents_id', documentsId)
      .set('document_id', documentId);
    return this.httpClient.delete<any>(environment.apiUrl + s, { params });
  }

  deleteDocOrPhoto(doc: string) {
    const params = new HttpParams().set('doc', doc);
    return this.httpClient.delete<any>(environment.apiUrl + "documents/cards/delete/image/doc", { params });
  }

  downloadDocument(s: string, documentId: string) {
    let params = new HttpParams().set('id', documentId);

    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.get<any>(environment.apiUrl + s, httpoptions);
  }

  downloadCard(url: string, cardIds: string[]) {
    const httpoptions: Object = {
      responseType: 'blob',
    };
    return this.httpClient.post<any>(
      environment.apiUrl + url + '/',
      cardIds,
      httpoptions
    );
  }

  downloadSingleCard(url: string, cardIds: string) {
    const httpoptions: Object = {
      responseType: 'blob',
    };
    return this.httpClient.post<any>(
      environment.apiUrl + url + '/',
      cardIds,
      httpoptions
    );
  }

  deleteArchive(s: string, documentsId: string) {
    const params = new HttpParams().set('documents_id', documentsId);
    return this.httpClient.delete<any>(environment.apiUrl + s, { params });
  }

  downloadAllDocument(s: string, DAMAGE_ID: string) {
    let params = new HttpParams().set('id', DAMAGE_ID);
    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.get<any>(environment.apiUrl + s, httpoptions);
  }

  uploadLoadingCard(url: String, params: HttpParams, file: any) {
    const formData: FormData = new FormData();
    formData.append('document', file);
    return this.httpClient.post<any>(environment.apiUrl + url, formData, {
      params,
    });
  }

  getDataById(apiPath: string, key: string, value: any) {
    let params = new HttpParams().set(key, value);
    return this.httpClient.get<any[]>(environment.apiUrl + apiPath, { params });
  }

  uploadPictures(apiUrl, files: File[], problemId: string) {
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append('pictures', file, file.name);
    });
    let params = new HttpParams().set('problem', problemId);

    return this.httpClient.post<any>(environment.apiUrl + apiUrl, formData, {
      params,
    });
  }

  downloadPictures(apiUrl: string, pictureElement: string[], isZip?: boolean) {
    let params = new HttpParams();
    if (isZip) {
      params = params.set('t', 'zip');
      const httpoptions: Object = {
        responseType: 'blob',
        params,
      };
      return this.httpClient.post<any[]>(
        environment.apiUrl + apiUrl,
        pictureElement,
        httpoptions
      );
    } else {
      params = params.set('t', 'list');
      const httpoptions: Object = {
        params,
      };
      return this.httpClient.post<any[]>(
        environment.apiUrl + apiUrl,
        pictureElement,
        httpoptions
      );
    }
  }

  downloadPicture(apiUrl: string, pictureElement: string) {
    let params = new HttpParams().set('id', pictureElement);
    const httpoptions: Object = {
      responseType: 'blob',
      params,
    };
    return this.httpClient.get<any>(environment.apiUrl + apiUrl, httpoptions);
  }

  deletePicture(apiPath: string, pictureId: string) {
    let params = new HttpParams().set('id', pictureId);
    const httpoptions: Object = {
      params,
    };

    return this.httpClient.delete<any>(environment.apiUrl + apiPath, {
      params,
    });
  }

  getSelectionOptionWithFilter(valuesFrom: string, s: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('q', valuesFrom).set('f', s),
    };

    return lastValueFrom(
      this.httpClient.get<any>(
        environment.apiUrl + 'util/autocomplete',
        httpHeader
      )
    );

  }


  getLanguage(lang: string) {
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang),
    };
    return this.httpClient.get<any>(
      environment.apiUrl + 'util/lang',
      httpHeader
    ).pipe(
      map(res => {
        if(res && res['oContent'] && res['oContent']['content']){
          return JSON.parse(res['oContent']['content'])
        }
        return res
      })
    );
  }

  getAvailableLanguages() {
    const httpHeader: Object = {
      params: new HttpParams()
    };
    return this.httpClient.get<any>(
      environment.apiUrl + 'util/lang',
      httpHeader
    ).pipe(
      map(res => {

        let toReformat = cloneDeep(res)

        if(toReformat && toReformat['oContent']){
          return toReformat['oContent'].map(lang => {
            if(lang['content']){
              return {
                id: lang.id,
                content: JSON.parse(lang.content.replace(/\\"/g, '\''))
              }
            }
            return lang
          })
        }
        return toReformat
      })
    );
  }

  setLanguage(lang:string, content:any){
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang),
    };
    return this.httpClient.post<any>(
      environment.apiUrl + 'util/lang',
      content,
      httpHeader
    )
  }

  deleteLanguage(lang:string){
    const httpHeader: Object = {
      params: new HttpParams().set('l', lang),
    };
    return this.httpClient.delete<any>(
      environment.apiUrl + 'util/lang',
      httpHeader
    )
  }
  uploadAttachment(parent: string, parent2: string, parentType: string, path: string, file: File) {
    let params = new HttpParams()
      .set('parent', parent)
      .set('path', path)
      .set('type', parentType)
      .set('parent2', parent2)


    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<any>(environment.apiUrl + 'attachment', formData, { params });
  }
  deleteAttachment(id: string) {
    const params = new HttpParams()
      .set('id', id)
    return this.httpClient.delete<any>(environment.apiUrl + 'attachment', { params });
  }

  getAvailableUserContext(app: string): Observable<any>{
    const params: HttpParams = new HttpParams().set('userId', app)
    return this.httpClient.get<any>(environment.apiUrl + 'util/context', {params})
  }

  getLastSelectedUserContext(app: string): Observable<any>{
    const params: HttpParams = new HttpParams().set('userId', app)
    return this.httpClient.get<any>(environment.apiUrl + 'util/context/last', {params})
  }

  setUserContext(
    context: string,
    oldContext: string
  ): Observable<any[]> {
    const httpoptions: Object = {
      params: new HttpParams().set('value', context).set('oldValue',oldContext)
    };
    return this.httpClient.post<any>(
      environment.apiUrl + "util/change-context",
      context,
      httpoptions
    );

  }
}
