import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';
import {BehaviorSubject} from 'rxjs';
import {BrowserCodeReader} from '@zxing/browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent {
  currentDeviceIndex: number = 0;
  availableDevices: MediaDeviceInfo[] | undefined;
  deviceCurrent: MediaDeviceInfo | undefined;
  deviceSelected: string | undefined;
  openReader: boolean = true;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13
  ];

  hasDevices: boolean | undefined;
  hasPermission: boolean | undefined;

  qrResultString: string | null | undefined;
  shipment: string[] = [];

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = true;

  testing = true;

  deviceIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<QrCodeComponent>,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
    if (!this.hasDevices) {
      this.updateVideoInputDevices().then(res => {
        this.hasDevices = res;
      });
    }
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length > 0);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    if (resultString.length >= 22 && !resultString.includes(';')) {
      this.qrResultString = resultString.replace(' ', '');
      this.shipment[0] = resultString.substring(0, 3);
      this.shipment[1] = resultString.substring(3, 12);
      this.shipment[2] = resultString.substring(12, 14);
      this.shipment[3] = resultString.substring(14, 22);
      this.shipment[4] = resultString.substring(14, resultString.length);
    } else {
      if (resultString.includes(';')) {
        this.shipment[0] = resultString.split(';')[0]
      } else {
        if (resultString.length > 9) {
          this.shipment[0] = resultString.split('-')[0].slice(-9);
        } else {
          this.shipment[0] = resultString;
        }
      }
    }
  }

  async updateVideoInputDevices() {
    // permissions aren't needed to get devices, but to access them and their info
    const devices = (await BrowserCodeReader.listVideoInputDevices()) || [];
    const hasDevices = devices && devices.length > 0;

    // stores discovered devices and updates information

    this.availableDevices = devices;

    this.hasDevices = hasDevices;

    return Promise.resolve(hasDevices);

  }

  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices?.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  onNextDeviceClick() {
    if (this.availableDevices != undefined && this.availableDevices.length > 0) {
      if (((this.deviceIndex + 1) < this.availableDevices.length)) {
        this.deviceIndex += 1;
        const device = this.availableDevices[this.deviceIndex]
        this.onDeviceSelectChange(device.deviceId)
      } else {
        this.deviceIndex = 0;
        const device = this.availableDevices[this.deviceIndex]
        this.onDeviceSelectChange(device.deviceId)
      }
    }
  }

  openFormatsDialog() {
    const data = {
      formatsEnabled: this.formatsEnabled,
    };
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  openInfoDialog() {
    const data = {
      hasDevices: this.hasDevices,
      hasPermission: this.hasPermission,
    };
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  onClose() {
    this.openReader = false;
    this.dialogRef.close(this.qrResultString);
  }

  onCodeError(event: Error) {
    console.log(event.name, event.message);
    console.log(event.stack)
  }
}
