<!--<mat-form-field [appearance]="appearance">
  <mat-label>{{label}}</mat-label>
  <input matInput value="{{value}}" type="{{isNumber?'number':'text'}}">
</mat-form-field>-->
<!--
<div class="form-group">
  <div class="input-group">
    <label for="input-text" *ngIf="!!label">{{label}}</label>
    <input id="input-text" class="form-control input-text" disabled="{{disabled}}" type="{{isNumber?'number':'text'}}"
           [ngClass]="customClass"
           placeholder="{{placeholder}}" [(ngModel)]="value" (ngModelChange)="onValueChange()" (keyup.enter)="input.blur()" #input >
  </div>


</div>-->


<div class="form-group" *ngIf="regexps.length <= 0">
  <div class="input-group">
    <label for="input-text" *ngIf="!!label">{{label}}</label>
    <input id="input-text" class="form-control input-text" disabled="{{disabled}}" type="{{isNumber?'number':'text'}}" min="{{min}}"
           placeholder="{{placeholder}}" [(ngModel)]="value" (ngModelChange)="onValueChange()" (keyup.enter)="input.blur()" #input [ngClass]="customClass">
  </div>
</div>

<div class="form-group" *ngIf="regexps.length > 0">
  <div class="input-group">
    <label for="custom-input" *ngIf="!!label">{{label}}</label>
    <input id="custom-input" class="form-control input-text custom-input" disabled="{{disabled}}" type="{{isNumber?'number':'text'}}"
           placeholder="{{placeholder}}" [(ngModel)]="value" (ngModelChange)="onValueChange()" (keyup.enter)="input.blur()" #input
           (input)="onInputChange($event)" pattern="{{pattern}}" maxlength="{{maxlength}}" required="{{isMandatory}}" [ngClass]="customClass">
  </div>
</div>
