<form class="form-container">
  <mat-form-field appearance="fill">
    <mat-label>{{placeholder}}</mat-label>
    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id"
        (click)="onOptionChange(option)">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>


<!-- Autocomplete with custom-text-field
<form class="example-form">
  <custom-text-field type="text" placeholder="Search for a street" [formControl]="control" [matAutocomplete]="auto"
    class="example-input"></custom-text-field>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
      {{street}}
    </mat-option>
  </mat-autocomplete>
</form>
-->
