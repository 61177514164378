import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableNotification } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private httpClient: HttpClient) {}

  getWarehouseTypes(
    page: number,
    size: number,
    id?: string,
    search?: string
  ): Observable<any[]> {
    let params = new HttpParams();

    if (page != undefined) params = params.set('page', page);
    if (!!size) params = params.set('size', size);
    if (!!id) params = params.set('id', id);
    if (!!search) params = params.set('search', search);

    return this.httpClient.get<any[]>(
      environment.apiUrl + 'masterdata/warehouse/type',
      { params }
    );
  }

  getWarehouses(params: HttpParams) {
    return this.httpClient.get<any[]>(
      environment.apiUrl + 'masterdata/warehouse'
    );
  }

  getWarehousesWithNoParam() {
    return this.httpClient.get<any[]>(
      environment.apiUrl + 'masterdata/warehouse/all'
    );
  }

  findWarehouseEmailsTo(sWarehouseId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('sWarehouseId', sWarehouseId);
    return this.httpClient.get<any[]>(
      environment.apiUrl + 'masterdata/warehouse/mails/to',
      { params }
    );
  }

  findWarehouseEmailsCC(sWarehouseId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('sWarehouseId', sWarehouseId);
    return this.httpClient.get<any[]>(
      environment.apiUrl + 'masterdata/warehouse/mails/cc',
      { params }
    );
  }

  findWarehouseEmails(sWarehouseId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('sWarehouseId', sWarehouseId);
    return this.httpClient.get<any[]>(
      environment.apiUrl + 'masterdata/warehouse/mail/all',
      { params }
    );
  }
}
