import { Component, Inject, OnInit } from '@angular/core';
import {Action, Data, DataSet, Layout, SectionLayout} from "../../../../models/layout";
import {EfficiencyProblem, ProblemDetail, ScorecardDetail} from "../../../../models/response";
import {IMultiSelectOption} from "../../custom-multiselect/types";
import {Document} from "../../../pages/documents/documents.component";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LayoutServiceService} from "../../../../services/layout.service";
import {MatDialog, MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {TableControllerService} from "../../../../services/table-controller.service";
import {GeneralService} from "../../../../services/crud/general.service";
import {SpinnerService} from "../../../../services/spinner.service";
import {Location} from "@angular/common";
import {AlertService} from "../../alert/alert.service";
import {NavigationService} from "../../../../services/utils/navigation.service";
import {map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";
import {ALERT_TYPE} from "../../alert/alert.enumerate";
import {lastValueFrom, of} from "rxjs";
import {DialogComponent} from "../../dialog/dialog.component";
import {CameraRollComponent} from "../../camera-roll/camera-roll.component";
import {DraftDialogComponent} from "../../draft-dialog/draft-dialog.component";
import {DndComponent} from "../../dnd/dnd.component";
import * as saveAs from 'file-saver';
import * as _ from 'lodash';
import {BtnMenuComponent} from "../../dialog/btn-menu/btn-menu.component";
import { ScorecardEfficiencyProblemDialogComponent } from '../../scorecard-efficiency-problem-dialog/scorecard-efficiency-problem-dialog.component';
import {cloneDeep} from "lodash";
import { ProblemDetailComponent } from '../problem-detail/problem-detail.component';
import {OVERRIDE_NAVIGATION} from "../../../../directives/utils/back-navigation.directive";
import { ScorecardComponent } from '../scorecard/scorecard.component';
import { Console } from 'console';
import { DialogDownloadComponent } from '../../dialog-download/dialog-download.component';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { CameraRollV2Component } from '../../camera-roll-v2/camera-roll-v2.component';

@Component({
  selector: 'scorecard-detail',
  templateUrl: './scorecard-detail.component.html',
  styleUrls: ['./scorecard-detail.component.scss'],
  providers: [
    TableControllerService,
  ],
})
export class ScorecardDetailComponent implements OnInit {


  layout: Layout | undefined;

  _detail: ScorecardDetail = {
    CARRIER_ID: "",
    CONTAINER: "",
    SCORECARD_APPLIANCES_QTY: 0,
    SCORECARD_PACKAGES_QTY: 0,
    DAMAGE_ID: "",
    DAMAGE_TYPE_ID: "",
    DELIVERY_MODE_ID: "",
    DELIVERY_TYPE_ID: "",
    DESPATCHED_QTY: 0,
    INSERT_DATE: "",
    INSERT_USER: "",
    L_EFFICIENCY_PROBLEMS: [],
    L_PROBLEMS: [],
    OTM_SHIPMENT_ID: "",
    RECEIVING_DATE: "",
    RECEIVING_WEEK: "",
    RECEIVING_WH: "",
    REMARKS: "",
    SCORECARD_ID: "",
    SENDING_DATE: "",
    SENDING_WH: "",
    SHIPMENT_ID: "",
    STATUS: "",
    TRAILER_PLATE: "",
    TRUCK_PLATE: "",
    UPDATE_DATE: "",
    UPDATE_USER: "",
    WAGON: "",
    files: [],
    DESTINATION_WAREHOUSE_ID:"",

  };


  hasAttachment: boolean = false;

  get detail(): ScorecardDetail {
    return this._detail;
  }

  set detail(value: ScorecardDetail) {
    this._detail = value;
  }

  isEditable = (this.detail?.STATUS ?? 'CANCELED') != 'CANCELED';

  detailBck: ScorecardDetail | undefined;

  //isNew: string = 'false';

  pageId: string = '';
  scorecardId: string = '';
  shipmentId: string = '';
  //titlePage: string = 'Scorecard ' + this.scorecardId + ' detail';
  titlePage: string = '';


  apiParent: string = 'primary';

  apiUrl: string = 'scorecards/detail';

  isNew : string|null = 'false'
  lDoc = []

  efficiencyDataset = {
    multiselect: true,
    datas: [
      {
        columnLabel: 'Description',
        type: 'DTY_NVAR',
        editable: false,
        columnName: 'LABEL',
      },
    ],
  };

  disabledActions: {id: string, motivation: string}[] = [];

  damageType: string = '';

  optionListMap: Map<string, IMultiSelectOption[]> = new Map<string,
    IMultiSelectOption[]>();

  private history: string[] = [];

  attachments: Document[] = [];

  nuovo: string|null= "false"

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutServiceService,
    public dialog: MatDialog,
    public problemsTableController: TableControllerService,
    private generalDataService: GeneralService,
    private spinner: SpinnerService,
    private _location: Location,
    public alertService: AlertService,
    private navigation: NavigationService,
  ) {
    this.nuovo= localStorage.getItem("isNew")
    //this.route.queryParams.subscribe(params => {
      //this.shipmentId = params['shipment']

    //});

    this.route.queryParams.subscribe(params=>{
      this.titlePage = params['t']
      this.titlePage = (this.titlePage === 'warehousing') ? 'Warehouse ' : 'Primary ';

    })

  }

  ngOnInit(): void {
/*
    this.route.queryParams.subscribe(params=>{
      this.titlePage = params['t']
      this.titlePage = (this.titlePage === 'warehousing') ? 'Warehouse ' : 'Primary ';

    })
*/
    this.isNew= localStorage.getItem("isNew")



    this.spinner.hideAll();
    this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.pageId = params['id'] ?? [];
      this.scorecardId = params['scr'] ?? '';
      this.damageType = (params['t'] ?? '').toUpperCase();
      this.shipmentId = params['shipment']
      this.isNew = params['n'] ?? 'false';



      if(this.isNew && this.detail ){

        //this.detail.SHIPMENT_ID = params['shp'] ?? '';
       this.detail.SHIPMENT_ID = this.shipmentId ?? '';
      }

      //if(this.isNew) {

        //this.shipmentId = params['shp'] ?? '';
     // }

      const layoutsString = localStorage.getItem('layout');
      if (!!layoutsString) {
        const layouts = JSON.parse(layoutsString);
        if (!!layouts && !!layouts[this.pageId]) {
          const pageLayout = layouts[this.pageId];
          if (
            Math.abs(
              (new Date(pageLayout['instant']).getTime() -
                new Date().getTime()) /
              60000
            ) < 360
          ) {
            this.layout = pageLayout['instance'];
            if (!!this.layout) this.catchAllSelectedOptions(this.layout);
          } else {
            this.spinner.show();
            this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
              this.layout = res?.layouts[0] ?? undefined;
              const layoutsString = localStorage.getItem('layout');
              if (!!layoutsString) {
                let layouts = JSON.parse(layoutsString);
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              } else {
                let layouts = {};
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              }
              ////(this.layout)
              this.spinner.hide();
              if (!!this.layout) this.catchAllSelectedOptions(this.layout);
            });
          }
        } else {
          this.spinner.show();
          this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
            this.layout = res?.layouts[0] ?? undefined;
            const layoutsString = localStorage.getItem('layout');
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            }
            ////(this.layout)
            this.spinner.hide();
            if (!!this.layout) this.catchAllSelectedOptions(this.layout);
            /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                   this.pageActionset = this.pageLayouts[0].actionset ?? [];
                   this.pageDataset = this.pageLayouts[0].dataset ?? [];
                   ////(this.pageActionset)
                 }*/
          });
        }
      } else {
        this.spinner.show();
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          ////(this.layout)
          this.spinner.hide();
          if (!!this.layout) this.catchAllSelectedOptions(this.layout);
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                 this.pageActionset = this.pageLayouts[0].actionset ?? [];
                 this.pageDataset = this.pageLayouts[0].dataset ?? [];
                 ////(this.pageActionset)
               }*/
        });
      }
    });

    this.route.parent?.url
      .pipe(map((segments) => segments.join('/')))
      .subscribe((finalParentUrl) => {
        this.apiParent = finalParentUrl;
      });

    if (this.isNew == 'true') {
      const localDamage: ScorecardDetail | undefined = window.history.state;

      let params = new HttpParams().set('t', this.damageType);
      this.spinner.show();
      lastValueFrom(this.generalDataService
        .getData(
          this.apiParent + '/' + this.apiUrl + '/efficiency-problem',
          undefined,
          params
        )
        .pipe(
          map((sp) =>
            sp.map((entry) => ({
              ID: entry['ID'] ?? '',
              LABEL: entry['LABEL'] ?? '',
              SELECTED: entry['SELECTED'] ?? false,
            }))
          )
        ))
        .then((res: EfficiencyProblem[]) => {
          this.detail ? this.detail.L_EFFICIENCY_PROBLEMS = res : undefined;
        })
        .catch(err => console.log(err))
        .finally(() => this.spinner.hide());
      this.detail = {
        SCORECARD_ID: '',
        DAMAGE_ID: '',
        SENDING_WH: localDamage?.SENDING_WH ?? '',
        RECEIVING_WH: localDamage?.RECEIVING_WH ?? '',
        DAMAGE_TYPE_ID: this.damageType.toUpperCase(),
        SENDING_DATE: '',
        RECEIVING_DATE: '',
        RECEIVING_WEEK: '',
        DESPATCHED_QTY: 0,
        SCORECARD_APPLIANCES_QTY: 0,
        SCORECARD_PACKAGES_QTY: 0,
        STATUS: 'OPEN',
        INSERT_USER: '',
        INSERT_DATE: '',
        UPDATE_USER: '',
        UPDATE_DATE: '',
        SHIPMENT_ID: localDamage?.SHIPMENT_ID ?? this.shipmentId,
        DELIVERY_TYPE_ID: '',
        DELIVERY_MODE_ID: '',
        CARRIER_ID: '',
        WAGON: '',
        TRUCK_PLATE: '',
        OTM_SHIPMENT_ID: '',
        CONTAINER: '',
        TRAILER_PLATE: '',
        REMARKS: '',
        L_EFFICIENCY_PROBLEMS: [],
        L_PROBLEMS: [],
        files: localDamage?.files ?? [],
        DESTINATION_WAREHOUSE_ID:'',

      };
      this.isEditable = (this.detail?.STATUS ?? 'CANCELED') != 'CANCELED';
    } else {
      this.detail = window.history.state;
      this.detailBck = _.cloneDeep(window.history.state);
      this.refreshData(false);
    }

    if (!!this.detail && this.isNew == 'true') {
      if (!!this.detail?.SENDING_DATE)
        this.detail.SENDING_DATE =
          this.detail?.SENDING_DATE.split('-').join('/');
      if (!!this.detail?.RECEIVING_DATE)
        this.detail.RECEIVING_DATE =
          this.detail?.RECEIVING_DATE.split('-').join('/');
    }
  }

  ngOnDestroy() {
    localStorage.setItem('changes', 'false');
  }

  ngAfterContentChecked() {
    if (!_.isEqual(this.detailBck, this.detail)) {
      localStorage.setItem('changes', 'true');
    } else {
      localStorage.setItem('changes', 'false');
    }
  }

  refreshData(close: Boolean, isSearching: boolean = true) {
    if ((!this.scorecardId && this.isNew != 'true') || close) {
      //this.navigation.back();
    }

    this.refreshDocumentData();

    this.spinner.show();
    let params = new HttpParams().set('id', this.scorecardId);
    this.generalDataService
      .getDetail(this.apiParent + '/' + this.apiUrl, undefined, params)
      .pipe(map((res) => res['oContent']))
      .pipe(
        map((sp) => ({
          SCORECARD_ID: sp['SCORECARD_ID'],
          DAMAGE_ID: sp['DAMAGE_ID'],
          SENDING_WH: sp['SENDING_WH'],
          RECEIVING_WH: sp['RECEIVING_WH'],
          SENDING_DATE: sp['SENDING_DATE'],
          RECEIVING_DATE: sp['RECEIVING_DATE'],
          RECEIVING_WEEK: sp['RECEIVING_DATE'],
          DESPATCHED_QTY: sp['DESPATCHED_QTY'],
          SCORECARD_APPLIANCES_QTY: sp['SCORECARD_APPLIANCES_QTY'],
          SCORECARD_PACKAGES_QTY: sp['SCORECARD_PACKAGES_QTY'],
          STATUS: sp['STATUS'],
          INSERT_USER: sp['INSERT_USER'],
          INSERT_DATE: sp['INSERT_DATE'],
          UPDATE_USER: sp['UPDATE_USER'],
          UPDATE_DATE: sp['UPDATE_DATE'],
          SHIPMENT_ID: sp['SHIPMENT_ID'],
          DELIVERY_TYPE_ID: sp['DELIVERY_TYPE_ID'],
          DELIVERY_MODE_ID: sp['DELIVERY_MODE_ID'],
          CARRIER_ID: sp['CARRIER_ID'],
          WAGON: sp['WAGON'],
          TRUCK_PLATE: sp['TRUCK_PLATE'],
          OTM_SHIPMENT_ID: sp['OTM_SHIPMENT_ID'],
          CONTAINER: sp['CONTAINER'],
          TRAILER_PLATE: sp['TRAILER_PLATE'],
          REMARKS: sp['REMARKS'],
          DAMAGE_TYPE_ID: sp['DAMAGE_TYPE_ID'],
          L_EFFICIENCY_PROBLEMS: sp['L_EFFICIENCY_PROBLEMS'],
          L_PROBLEMS: sp['L_PROBLEMS'],
          files: sp["files"],
          DESTINATION_WAREHOUSE_ID: sp["DESTINATION_WAREHOUSE_ID"]
        }))
      )
      .subscribe(
        (res) => {


          this.spinner.hide();
          this.detail = res;
          this.isEditable = (this.detail?.STATUS ?? 'CANCELED') != 'CANCELED';

          this.detailBck = _.cloneDeep(res);

          /*this.efficiencyTableController.dataSet =
            this.detail.L_EFFICIENCY_PROBLEMS ?? [];
          this.efficiencyTableController.selection.select(
            this.efficiencyTableController.dataSet.filter(
              (entry) => entry['SELECTED']
            )
          );*/

          this.problemsTableController.dataSet = this.detail.L_PROBLEMS ?? [];
          this.problemsTableController.selection.clear();

         // this.checkAttachment()
          /*this.tableController.dataSet = !!this.tableController.dataSet
          ? hasSubArray(
            this.tableController.dataSet,
            res['oContent']['content']
          )
          : res['oContent']['content'];
        this.tableController.numElementsLoaded =
          this.tableController.dataSet.length;
        this.tableController.numElementsSize = res['oContent'].totalElements;
        this.spinner.hide();*/
        },
        (error) =>
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: 'Scorecard ' + this.scorecardId + ' no correctly loaded.',
            timeout: 20000,
            selfClose: null,
          })
      );
  }

  uploadFiles(files: File[]) {
  }

  showFiles() {
    if (!!this.detail && !!this.detail.SCORECARD_ID) {
      /*lastValueFrom(
        this.generalDataService.downloadAllDocument(
          this.apiParent + '/' + this.apiUrl + '/docs',
          this.detail.SCORECARD_ID
        )
      )
        .then((res) => {
        })
        .catch((err) => {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message: "Unable to load damage's documents",
            timeout: 10000,
            selfClose: null,
          });
        });*/
    }
  }

  goToProblem0(problem?: any) {

    if (!!this.detail && !!this.detail.SCORECARD_ID) {

      this.router.navigate(['page/' + this.apiParent + '/problems/detail'], {
        queryParams: {
          id:
            this.navigation.changeQueryParmas(
              'page/' + this.apiParent + '/problems/detail'
            ) ?? '',
          n: !problem,
          scr: this.detail.SCORECARD_ID,
          dmg: this.detail.DAMAGE_ID,
          problemId: problem?.PROBLEM_ID ?? 'null',
          dmgType: this.damageType,
          dmgStatus: this.detail.STATUS,
        },
      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved damage',
          content: `Please save the damage before creating the problem.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (!!res) {
          //salvo damage
        }
      });
    }
  }

  goToProblem(problem?: any) {

    if(problem) {


      const pageId =
        this.navigation.changeQueryParmas(
          'page/' + this.apiParent + '/scorecards/problem'
        ) ?? '';

      this.router.navigate(['page/' + this.apiParent + '/scorecards/problem'], {

        queryParams: {
          id: pageId,
          n: false,
          scr: this.detail?.SCORECARD_ID,
          problemId: problem?.SCORECARD_PROBLEM_ID ?? 'null',
          dmgType: this.damageType,
          dmgStatus: this.detail?.STATUS,
          shipment: this.detail.SHIPMENT_ID,
          //detail: JSON.stringify(problem)
        },
      })
    } else {

      const newProblem: ProblemDetail = {
        SCORECARD_PROBLEM_ID: null,
        SCORECARD_ID: this.detail?.SCORECARD_ID,
        CATEGORY_ID: null,
        REASON_ID: null,
        REASON_D_ID: null,
        PROBLEM_APPLIANCES_QTY: 0,
        PROBLEM_PACKAGES_QTY: 0,
        L_SCORECARD_PROBLEM_PNC: [],
        CAMERA_ROLL_ID:null
      }
      lastValueFrom(
        this.generalDataService.postData(
          this.apiParent + '/scorecards/problem',
          [newProblem],
          //new Http  Params().set('problemId', this.problemId)
        )
      )
        .then((res) => {
          const problemId = ((res['oContent'])[0])['SCORECARD_PROBLEM_ID'] ?? '';

          this.router.navigate(['page/' + this.apiParent + '/scorecards/problem'], {
            queryParams: {
              id:
                this.navigation.changeQueryParmas(
                  'page/' + this.apiParent + '/scorecards/problem'
                ) ?? '',
              n: true,
              scr: this.detail?.SCORECARD_ID,
              problemId: problemId ?? 'null',
              dmgType: this.damageType,
              dmgStatus: this.detail?.STATUS,
              shipment:this._detail?.SHIPMENT_ID,

            },
          })
        });
    }



    /*dialogRef.afterClosed().subscribe((res) => {
      if (!!res && res.data != null) {
        const problem: ProblemDetail = _.cloneDeep(res.data);
        let damageChange = _.cloneDeep(this.detail);
        if (problem.PROBLEM_ID) {
          damageChange ? damageChange.L_PROBLEMS = damageChange?.L_PROBLEMS?.map((tempProblem, index) => {
            if (tempProblem.PROBLEM_ID == problem.PROBLEM_ID) {
              if (this.detailBck && this.detailBck.L_PROBLEMS) {
                delete problem['isChanged'];
                if (!_.isEqual(this.detailBck.L_PROBLEMS[index], problem)) {
                  problem['isChanged'] = true;
                }
              }
              return problem;
            }
            return tempProblem;
          }) : undefined
        } else {
          const row = this.problemsTableController.rowNumber;
          console.log(row)
          if (row >= 0) {
            damageChange ? damageChange.L_PROBLEMS = (damageChange.L_PROBLEMS ?? []).map((p, index) => {
              if (index == row) {
                return problem
              }
              return p;
            }) : undefined;
            this.problemsTableController.rowNumber = -1;
          } else {
            problem['isChanged'] = true;
            if (damageChange) {
              if (damageChange.L_PROBLEMS) {
                damageChange.L_PROBLEMS = damageChange?.L_PROBLEMS?.concat([problem])
              } else {
                damageChange.L_PROBLEMS = [];
                damageChange.L_PROBLEMS = damageChange?.L_PROBLEMS?.concat([problem])
              }

            }
          }

        }

        if (damageChange) {
          damageChange.DAMAGED_APP_TOT_QTY = damageChange?.L_PROBLEMS?.reduce((acc: number, problem) => {
            return (acc + _.toNumber((problem.PROBLEM_APPLIANCES_QTY
            ) ?? 0))
          }, 0) ?? 0;
          damageChange.DAMAGED_PKG_TOT_QTY = damageChange?.L_PROBLEMS?.reduce((acc: number, problem) => {
            return (acc + _.toNumber((problem.PROBLEM_PACKAGES_QTY
            ) ?? 0))
          }, 0) ?? 0;
          this.detail = damageChange;
          this.problemsTableController.dataSet = damageChange.L_PROBLEMS ?? []
        }

      }
      this.problemsTableController.rowNumber = -1;
    });*/
  }

  onAddProblemClick0(problem?: any) {
    if (this.detail?.STATUS == 'CANCELED' && problem == null) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unable to add problem',
          content: `This damage is in the CANCEL CLAIM state so no PROBLEMs can be added.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
      });

      return;
    }

    if (
      this.isNew == 'false' &&
      this.detail != undefined &&
      this.detailBck != undefined &&
      Object.keys(this.detail).filter(
        (key) =>
          key != 'L_PROBLEMS' &&
          key != 'L_EFFICIENCY_PROBLEMS' &&
          this.detail![key] != this.detailBck![key]
      ).length < 1
    ) {
      this.goToProblem(problem);
      return;
    }

    if (this.isNew == 'true') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved changes',
          content: `You are creating a new damage, please save it before proceeding.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
      });

      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Unsaved changes',
        content: `Please save your damage before proceeding`,
        cancelButtonLabel: 'Close',
        cancelButtonColor: 'basic',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res == true) {
        this.goToProblem(problem);
      }
    });
  }

  onAddProblemClick(problem?: any, row: number = -1) {

    if (this.detail?.STATUS == 'CANCELLED' && problem == null) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unable to add problem',
          content: `This damage is in the CANCEL CLAIM state so no PROBLEMs can be added.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => { });

      return;
    }

    if (
      this.detail
    ) {
      this.goToProblem(problem);
      return;
    }
  }

 /* checkAttachment() {
    lastValueFrom(
      this.generalDataService.getDataById(
        'documents/other/documents',
        'id',
        this.detail.SCORECARD_ID
      )
    ).then((documents) => {
      this.hasAttachment = documents['oContent'].length > 0;
    }).catch((err) => {
      this.hasAttachment = false;
    });
  }
*/


  preview() {

    const dialogRef = this.dialog.open(CameraRollV2Component, {


      maxWidth: '100vw',
      width: '80vw',
      data: {
        title: "Attachments" ,
        files: this.detail.files, //this.detail.files,
        path:"SCORECARD/"+this.detail.SCORECARD_ID+ "/",
        parent: this.scorecardId,
        parentType: "SCORECARD",
        parent2: "null",
        isViewOnly: false,
        cancelButtonLabel: "Close",
        cancelButtonColor: "accent",
      },
    });

dialogRef.afterClosed().subscribe(res => {
  this.refreshData(false)
})

  }



  actionCatcher(event: string) {

    console.log(event)

    if (event.endsWith('_ALL')) {
      /*if (event.startsWith('ACT_EXPORT')) {
        this.generalDataService
          .exportDataExcel(
            this.apiUrl,
            'excel',
            this.savedParams.set(
              'dataset',
              this.layout!['sectionLayouts'].filter(
                (sectionLayout) => sectionLayout.id == 'LYO_SECTABLE'
              ).pop()?.dataset[0].id ?? ''
            ),
            []
          )
          .subscribe((blob) => {
            saveAs(blob, 'excel.xlsx');
            this.spinner.hide();
          });
      }*/
      /*if (event.startsWith('ACT_TO_LANE')) {
        const dialogRef = this.dialog.open(LaneSelectionComponent, {
          data: { title: 'Lane Selection' },
          width: '800px',
          disableClose: true,
        });

        lastValueFrom(dialogRef.afterClosed())
          .then((result) => {
            if(!!result){
              //(this.problemsTableController.row)
              this.router.navigate(['page/primary/damages/detail'], {state: result , queryParams: {id: 'PAG_DAMAGE_DETAIL', n: true}});
            }
          });
      }*/
      if (event.startsWith('ACT_TO_PROBLEM')) {
        this.onAddProblemClick();
      }
    }
    /*else if(event == 'TO_LANE'){
      this.router.navigate(
        ['page/' + parent + '/' + url + '/lane-selection'],
        {
          queryParams: {
            id: page_id,
            n: true,
            t: parent,

          },
        }
      );
    }*/ else if (event.endsWith('_ID')) {
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: 'Delete confirmation',
            content: `Are you sure to delete this problem?`,
            //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.generalDataService
              .deleteData(
                this.apiParent + '/' + this.apiUrl,
                [this.problemsTableController.row]
              )
              .subscribe((res) => {
                const row = cloneDeep(this.problemsTableController.row);

                this.detail.L_PROBLEMS = this.detail.L_PROBLEMS?.filter(problem => problem.SCORECARD_PROBLEM_ID != row.SCORECARD_PROBLEM_ID);

                this.problemsTableController.dataSet = this.detail.L_PROBLEMS ?? [];
                this.problemsTableController.rowNumber = -1;
                this.problemsTableController.row = null;
                this.problemsTableController.selection.clear();

              });
          }
        });
      }

      if (event.startsWith('ACT_EDIT')) {
        this.onAddProblemClick(this.problemsTableController.row, this.problemsTableController.rowNumber);
      }

      if (event.startsWith('ACT_SHOW_CAMERA')) {

        if (!!this.problemsTableController.row['PROBLEM_ID']) {
          lastValueFrom(
            this.generalDataService.getDataById(
              this.apiParent + '/problems/detail/pictures',
              'problem',
              this.problemsTableController.row['PROBLEM_ID']
            )
          ).then((pictures) => {
            const dialogRef = this.dialog.open(CameraRollComponent, {
              width: '60vw',
              data: {
                title:
                  'Camera roll for ' +
                  this.problemsTableController.row['PROBLEM_ID'] +
                  ' problem',
                content: pictures['oContent'],
                apiUrl: this.apiParent + '/problems/detail/pictures',
                problemId: this.problemsTableController.row['PROBLEM_ID'],
                cancelButtonLabel: 'Close',
                cancelButtonColor: 'accent',
              },
            });
          });
        }
      }

      /*if (event.startsWith('ACT_SAVE')) {
        this.spinner.show();
        this.generalDataService
          .postData(
            this.apiUrl,
            this.problemsTableController.dataSet
              .filter((row) => row.edited != undefined)
              .map((elem) => {
                delete elem.edited;
                delete elem.checked;
                return elem;
              })
          )
          .subscribe((res) => {
            this.spinner.hide();
            this.onCancelClick();
          });
      }*/
      if (event.startsWith('ACT_REMARKS')) {
        const data = { scorecardId: this.detail.SCORECARD_ID, pageId: "PAG_SCORECARD_REMARKS", t: this.titlePage};
        this.router.navigate(['page/' + this.apiParent + '/scorecards/remarks'], { queryParams: data });
      }

    } else {
      if (this.problemsTableController.selection.selected.length == 0) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'No rows selected',
            content: `Please select at least 1 row...`,
            confirmButtonLabel: 'Ok',
            confirmButtonColor: 'basic',
          },
        });
        return;
      }
      /*if (event.startsWith('ACT_EXPORT')) {
        if (!!this.layout)
          this.savedParams = this.savedParams.append(
            'dataset',
            this.layout['sectionLayouts']
              .filter((sectionLayout) => sectionLayout.id == 'LYO_SECTABLE')
              .pop()?.dataset[0].id ?? ''
          );

        this.generalDataService
          .exportDataExcel(
            this.apiUrl,
            'excel',
            this.savedParams.set(
              'dataset',
              this.layout!['sectionLayouts'].filter(
                (sectionLayout) => sectionLayout.id == 'LYO_SECTABLE'
              ).pop()?.dataset[0].id ?? ''
            ),
            this.tableController.selection.selected
          )
          .subscribe((blob) => {
            saveAs(blob, 'excel.xlsx');
            this.spinner.hide();
          });
      }*/
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: 'Confirm delete selected rows',
            content: `Are you sure to proceed?`,
            //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.spinner.show();
            const param = new HttpParams().set('damageId', this.scorecardId);
            lastValueFrom(
              this.generalDataService.deleteData(
                this.apiParent + '/problems',
                this.problemsTableController.selection.selected,
                param
              )
            )
              .then((res) => {
                this.alertService.add({
                  type: ALERT_TYPE.INFO,
                  message: 'Row deleted.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .catch((err) => {
                this.alertService.add({
                  type: ALERT_TYPE.WARNING,
                  message: 'Unable to delete selected problem.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .finally(() => {
                this.problemsTableController.deletedRows = [];
                this.problemsTableController.selection.clear();
                this.spinner.hide();
                this.refreshData(false, false);
              });
          } else {
            this.problemsTableController.deletedRows = [];
          }
        });
      }
      /*if (event.startsWith('ACT_DRAFT')) {
        if (!!this.tableController.selection.selected) {
          const dialog = this.dialog.open(DraftDialogComponent, {
            width: '65vw',
            data: {
              title: 'Notify Warehouse',
              damageIds: this.tableController.selection.selected,
              typology: 'default',
              notificationId: 'default',
              closeButtonLabel: 'Close',
              confirmButtonLabel: 'Send',
            },
            backdropClass: 'backdropBackground',
          });

          dialog.afterClosed().subscribe((res) => {
            if (!!res && res) {
              ////(res);
            }
          });

          this.tableController.selection.clear();
        }
      }*/
    }
  }

  onSaveClick(event: any, close: Boolean) {
    //(this.detail);



    this.spinner.show();
    lastValueFrom(
      this.generalDataService.postDataDetail(
        this.apiParent + '/' + this.apiUrl,
        this.detail
      )
    )
      .then((res) => {
        this.scorecardId = res['oContent']?.SCORECARD_ID ?? '';
        this.isNew = 'false';
        this.alertService.add({
          type: ALERT_TYPE.INFO,
          message: 'Scorecard ' + this.scorecardId + ' saved correctly',
          timeout: 5000,
          selfClose: null,
        });
        const queryParams: Params = {damage: this.scorecardId, n: false};

        this.router.navigate([], {
          relativeTo: this.route,
          //queryParams: queryParams,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        this.refreshData(close, false);
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'Some error occured during the damage save action',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  onSaveAndActionClick(action: string) {

    this.spinner.show();

    lastValueFrom(

      this.generalDataService.postDataDetail(

        this.apiParent + '/' + this.apiUrl,
        this.detail,
        action
      )

    )

      .then((res) => {
        if (action == 'P') {
          this.scorecardId = res['oContent']?.SCORECARD_ID ?? res['sResponseMessage'];
          this.isNew = 'false';
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Scorecard ' + this.scorecardId + ' saved correctly',
            timeout: 5000,
            selfClose: null,
          });

          const dialog = this.dialog.open(DraftDialogComponent, {
            width: '65vw',
            data: {
              title: 'Notify Warehouse',
              damageIds: this.detail?.DAMAGE_ID,
              typology: 'default',
              notificationId: 'default',
              url: this.apiParent + '/' + this.apiUrl,
              groups: res['oContent'],
              closeButtonLabel: 'Close',
              confirmButtonLabel: 'Send',
            },
            backdropClass: 'backdropBackground',
          });

          dialog.afterClosed().subscribe((res) => {
            if (!!res && res) {
              ////(res);
            }
            this.refreshData(false, false);
          });
        } else if (action == 'S') {
          this.scorecardId = res['oContent']?.SCORECARD_ID ?? res['sResponseMessage'];
          if (res['iResponseCode'] == 200) {
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: res['sResponseMessage'],
              timeout: 5000,
              selfClose: null,
            });
          } else {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: res['sResponseMessage'],
              timeout: 5000,
              selfClose: null,
            });
          }
          this.refreshData(false, false);
        }
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'Unable to save current damage',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  onCancelClick() {
    if (
      (this.detail == undefined && this.detailBck == undefined) ||
      (this.detail != undefined &&
        this.detailBck != undefined &&
        Object.keys(this.detail).filter(
          (key) =>
            key != 'L_PROBLEMS' &&
            key != 'L_EFFICIENCY_PROBLEMS' &&
            this.detail![key] != this.detailBck![key]
        ).length < 1)
    ) {
      this._location.back();
      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Unsaved changes',
        content: `If you leave this page the creations or changes you have made so far will not be saved. How would you like to proceed?`,
        cancelButtonLabel: 'Stay',
        cancelButtonColor: 'basic',
        confirmButtonLabel: 'Leave!',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res == true) {
        this._location.back();
      }
    });
  }

  private catchAllSelectedOptions(layout: Layout) {
    const dtArray: Data[] = Array.from(new Set(layout.sectionLayouts.flatMap((sl) => sl.dataset).flatMap((dts) => (dts.datas ?? [] as Data[]))));
    dtArray.forEach(data => {
      if (data.valuesFrom.length > 0) {
        let reserchString = cloneDeep(data.valuesFrom);
        const replaceArray =
          data.valuesFrom.match(/_@[^\ ]*@_/gm)?.map((substr) => {
            const subStringContent = substr.replace("_@", "").replace("@_", "");
            let dateSubstr;
            if (this.detail) {
              if (!!this.detail[subStringContent]) {
                if (subStringContent == 'INSERT_DATE' || subStringContent == 'UPDATE_DATE') {
                  dateSubstr = this.detail[subStringContent].split(" ");
                  if (dateSubstr[0].includes("-"))
                    dateSubstr[0] = dateSubstr[0].split("-")[1] + "-" + dateSubstr[0].split("-")[0] + "-" + dateSubstr[0].split("-")[2];
                  else if (dateSubstr[0].includes("/"))
                    dateSubstr[0] = dateSubstr[0].split("/")[1] + "/" + dateSubstr[0].split("/")[0] + "/" + dateSubstr[0].split("/")[2];

                  if (dateSubstr.length > 1) {
                    return {
                      toReplace: substr,
                      raplaceWith: dateSubstr[0] + dateSubstr[1] + " " + dateSubstr[2]
                    }
                  } else {
                    return {
                      toReplace: substr,
                      raplaceWith: dateSubstr[0]
                    }
                  }

                }
              } else {
                return {
                  toReplace: substr,
                  raplaceWith: ""
                }
              }





              return {
                toReplace: substr,
                raplaceWith: this.detail[subStringContent],
              };
            } else {
              return {
                toReplace: substr,
                raplaceWith: "",
              };
            }

          }) ?? [];

        for (let i = 0; i < replaceArray.length; i++) {
          reserchString = reserchString.replace(
            replaceArray[i].toReplace,
            "'" + replaceArray[i]?.raplaceWith?.replace(/\//gm, "-") + "'"
          );
        }

        this.generalDataService
          .getSelectionOption(reserchString)
          .then((res) => {
            data.optionValues = res;

            const layoutsString = localStorage.getItem("layout");
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: layout,
                instant: new Date(),
              };
              localStorage.setItem("layout", JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: layout,
                instant: new Date(),
              };
              localStorage.setItem("layout", JSON.stringify(layouts));
            }
          });
      }
    })
  }

  goBack() {
    this.spinner.show();
    this._location.back();
    this.spinner.hide();
  }

  onChangeStatusClick(status: string) {
    lastValueFrom(
      this.generalDataService.changeStatus(
        this.apiParent + '/' + this.apiUrl,
        this.detail?.DAMAGE_ID ?? '',
        status
      )
    )
      .then((res) => {
        if (status != 'D') this.scorecardId = res['oContent']?.SCORECARD_ID ?? '';
        this.isNew = 'false';

        if (status == 'W') {
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Scorecard ' + this.scorecardId + ' cancelled correctly',
            timeout: 5000,
            selfClose: null,
          });
        } else if (status == 'D') {
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Scorecard ' + this.scorecardId + ' deleted correctly',
            timeout: 8000,
            selfClose: null,
          });
        } else {
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Scorecard ' + this.scorecardId + ' saved correctly',
            timeout: 5000,
            selfClose: null,
          });
        }
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'Some error occured during the damage save action',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.spinner.hide();
        if (status != 'D') this.refreshData(false, false);
        else this._location.back();
      });
  }

  refreshDocumentData() {
    /*let params = new HttpParams().set('id', this.scorecardId);
    this.generalDataService
      .getDocumentsById(this.apiParent + '/' + this.apiUrl + '/docs', params)
      .subscribe((res) => {
        this.attachments = res['oContent'];
      });*/
  }

  deleteDoc(documentsId: string, documentId: string) {
    lastValueFrom(
      this.generalDataService.deleteDocument(
        this.apiParent + '/' + this.apiUrl,
        documentsId,
        documentId
      )
    )
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: 'Delete document success',
          timeout: 5000,
          selfClose: null,
        });
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Delete document was wrong',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.refreshDocumentData();
      });
  }

  downloadDoc(
    documentsId: string,
    documentId: string,
    fileType: string,
    fileName: string
  ) {
    lastValueFrom(
      this.generalDataService.downloadDocument(
        this.apiParent + '/' + this.apiUrl + '/docs/download',
        documentId
      )
    )
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Something went wrong during download ' + fileName,
          timeout: 5000,
          selfClose: null,
        });
      });
  }

  uploadDoc() {
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });
    dialogEvent.afterClosed().subscribe((dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        //this.fileToSend = dndResponse.files;
        this.spinner.show();
        lastValueFrom(
          this.generalDataService.uploadDoc(
            this.apiParent + '/' + this.apiUrl + '/docs',
            this.scorecardId,
            dndResponse.files[0]
          )
        )
          .then((res) => {
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: 'File uploaded',
              timeout: 5000,
              selfClose: null,
            });
          })
          .catch((err) => {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message:
                'Something went wrong, please try again or contact webmaster',
              timeout: 5000,
              selfClose: null,
            });
          })
          .finally(() => {
            this.spinner.hide();
            this.refreshDocumentData();
          });
      }
    });
  }

  selectProblem(data: any, i: number) {
    if( this.problemsTableController.rowNumber != i){
      this.problemsTableController.row = data;
      this.problemsTableController.rowNumber = i;
    }else{
      this.problemsTableController.row = null;
      this.problemsTableController.rowNumber = -1;
    }
  }

  onLongPressClick(data: any, index: number, actions: Action[] | null) {
    this.problemsTableController.row = data;
    this.problemsTableController.rowNumber = index;

    const dialogRef = this.dialog.open(BtnMenuComponent, {
      panelClass: 'my-dialog',
      minWidth: '75vw',
      data: {
        actions: actions
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((res) => {

      if (res && res.action) {
        if(res.action == 'ACT_EDIT'){
          /*const pageId =
            this.navigation.changeQueryParmas(
              'page/' + this.apiParent + '/scorecards/problem'
            ) ?? '';
          this.router.navigate(['page/' + this.apiParent + '/scorecards/problem'], {
            state: {data: this.problemsTableController.row},
            queryParams: {
              id: pageId,
              problemId: this.problemsTableController.row['SCORECARD_PROBLEM_ID'],
              t: this.apiParent + "/scorecards",
              damage: this.problemsTableController.row['DAMAGE_ID'],
              scr: this.problemsTableController.row['SCORECARD_ID']
            },
          });*/

          this.onAddProblemClick(this.problemsTableController.row, this.problemsTableController.rowNumber);
        }else if(res.action == 'ACT_DELETE'){
          const dialogRef = this.dialog.open(DialogComponent, {
            // width: '250px',
            data: {
              title: 'Delete confirmation',
              content: `Are you sure to delete this problem?`,
              //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
              cancelButtonLabel: 'No',
              cancelButtonColor: 'basic',
              confirmButtonLabel: 'Yes',
              confirmButtonColor: 'warn',
            },
          });

          dialogRef.afterClosed().subscribe((res) => {
            if (!!res) {
              this.generalDataService
                .deleteData(
                  this.apiParent + '/' + this.apiUrl,
                  [this.problemsTableController.row]
                )
                .subscribe((res) => {
                  this.problemsTableController.selection.clear();
                  this.onCancelClick();
                });
            }
          });
        }
      }
    });
    //open menu
  }

  foo($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  observable(number: number) {
    return of(number);
  }

  openEfficiencyProblem() {

    const dialogRef = this.dialog.open(ScorecardEfficiencyProblemDialogComponent, {
      panelClass: 'mat-dialog-parent',
      height: '100vh',
      minWidth: '100vw',
      data: {
        detail: cloneDeep(this?.detail)
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if(!!res) {
        if(this.detail){
          this.detail = {...this.detail, REMARKS: res.remarks, L_EFFICIENCY_PROBLEMS: res.efficiencyProblems};
          //salvo quando chiudo
          this.onSaveClick(null, false);
        }

      }

    })
  }

  historySize() {
    return this.navigation.size()
  }
}
