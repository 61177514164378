import { Component, OnInit } from '@angular/core';
import { Data, DataSet, Layout, SectionLayout } from '../../../models/layout';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutServiceService } from '../../../services/layout.service';
import { MatDialog } from '@angular/material/dialog';
import { TableControllerService } from '../../../services/table-controller.service';
import { GeneralService } from '../../../services/crud/general.service';
import { SpinnerService } from '../../../services/spinner.service';
import { Location } from '@angular/common';
import { AlertService } from '../../shared/alert/alert.service';
import { map } from 'rxjs/operators';
import { PageEventType } from '../../shared/custom-table-paginator/custom-table-paginator.component';
import { HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { hasSubArray } from '../../../utils/generic';
import { DndComponent } from '../../shared/dnd/dnd.component';
import * as saveAs from 'file-saver';
import { ALERT_TYPE } from '../../shared/alert/alert.enumerate';
import * as _ from 'lodash';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  layout: Layout | undefined;

  pageId: string = '';

  apiParent: string = 'documents';

  apiUrl: string = 'other';

  savedPaginator: PageEventType = {
    pageIndex: 0,
    previousPageIndex: 0,
    loader: 50,
    size: 0,
  };

  savedParams: HttpParams = new HttpParams();

  archives: Archive[] = [];

  isNewSection: boolean = false;

  newSection: Archive = {
    DOCUMENTS_ID: '',
    DOCUMENT_TYPE: '',
    EXPIRED: new Date(),
    FILE_NAME: '',
    FILE_PATH: '',
    L_DOCUMENTS: [],
    SECTION: '',
  };

  section: Archive | null = {
    DOCUMENTS_ID: '',
    DOCUMENT_TYPE: '',
    EXPIRED: new Date(),
    FILE_NAME: '',
    FILE_PATH: '',
    L_DOCUMENTS: [],
    SECTION: '',
  };

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutServiceService,
    public dialog: MatDialog,
    private generalDataService: GeneralService,
    private spinner: SpinnerService,
    private _location: Location,
    public alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.spinner.hideAll();
    this.route.queryParams.subscribe((params) => {
      this.pageId = params['id'] ?? [];

      const layoutsString = localStorage.getItem('layout');
      if (!!layoutsString) {
        const layouts = JSON.parse(layoutsString);
        if (!!layouts && !!layouts[this.pageId]) {
          const pageLayout = layouts[this.pageId];
          if (
            Math.abs(
              (new Date(pageLayout['instant']).getTime() -
                new Date().getTime()) /
                60000
            ) < 360
          ) {
            this.layout = pageLayout['instance'];
            if (!!this.layout) this.catchAllSelectedOptions(this.layout);
          } else {
            this.spinner.show();
            this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
              this.layout = res?.layouts[0] ?? undefined;
              const layoutsString = localStorage.getItem('layout');
              if (!!layoutsString) {
                let layouts = JSON.parse(layoutsString);
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              } else {
                let layouts = {};
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              }
              ////(this.layout)
              this.spinner.hide();
              if (!!this.layout) this.catchAllSelectedOptions(this.layout);
              /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                     this.pageActionset = this.pageLayouts[0].actionset ?? [];
                     this.pageDataset = this.pageLayouts[0].dataset ?? [];
                     ////(this.pageActionset)
                   }*/
            });
          }
        } else {
          this.spinner.show();
          this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
            this.layout = res?.layouts[0] ?? undefined;
            const layoutsString = localStorage.getItem('layout');
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            }
            ////(this.layout)
            this.spinner.hide();
            if (!!this.layout) this.catchAllSelectedOptions(this.layout);
            /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                   this.pageActionset = this.pageLayouts[0].actionset ?? [];
                   this.pageDataset = this.pageLayouts[0].dataset ?? [];
                   ////(this.pageActionset)
                 }*/
          });
        }
      } else {
        this.spinner.show();
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          ////(this.layout)
          this.spinner.hide();
          if (!!this.layout) this.catchAllSelectedOptions(this.layout);
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                 this.pageActionset = this.pageLayouts[0].actionset ?? [];
                 this.pageDataset = this.pageLayouts[0].dataset ?? [];
                 ////(this.pageActionset)
               }*/
        });
      }
      this.refreshData();
    });

    this.route.parent?.url
      .pipe(map((segments) => segments.join('/')))
      .subscribe((finalParentUrl) => {
        this.apiParent = finalParentUrl;
      });
  }

  refreshData() {
    this.onSearch(
      0,
      this.savedPaginator.loader,
      new Map<string, { order: number; type: 'asc' | 'desc' }>(),
      new Array<string>(),
      true
    );
  }

  onSearch(
    page: number,
    size: number,
    sorting: Map<string, { order: number; type: 'asc' | 'desc' }>,
    filters: Array<string>,
    refresh: boolean = false
  ) {
    /*let params = new HttpParams().set('page', page).set('size', size);
    new Map(
      [...sorting.entries()].sort((a, b) => a[1].order - b[1].order)
    ).forEach((value, key, map) => {
      if (!!!params.get('sort'))
        params = params.set(
          'sort',
          key + (value.type == 'desc' ? ',desc' : '')
        );
      else
        params = params.append(
          'sort',
          key + (value.type == 'desc' ? ',desc' : '')
        );
    });
    filters.forEach((filter) => {
      if (!filter.includes('&')) {
        if (!!!params.get('search')) params = params.set('search', filter);
        else params = params.append('search', filter);
      } else {
        filter.split('&').forEach((value) => {
          if (!!!params.get('search')) params = params.set('search', value);
          else params = params.append('search', value);
        });
      }
    });

    this.savedParams = params;*/

    this.spinner.show();
    lastValueFrom(
      this.generalDataService
        .getDocuments(this.apiParent + '/' + this.apiUrl, 'GENERAL')
        .pipe(map((sp) => sp['oContent']))
    )
      .then((res) => {
        this.archives = res;
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  private catchAllSelectedOptions(layout: Layout) {
    layout.sectionLayouts.forEach((sectionLayout: SectionLayout) => {
      sectionLayout.dataset.forEach((dataset: DataSet) => {
        dataset.datas?.map((data: Data) => {
          if (data.valuesFrom.length > 0) {
            this.spinner.show();
            this.generalDataService
              .getSelectionOption(data.valuesFrom)
              .then((res) => {
                data.optionValues = res;
                const layoutsString = localStorage.getItem('layout');
                if (!!layoutsString) {
                  let layouts = JSON.parse(layoutsString);
                  layouts[this.pageId] = {
                    instance: layout,
                    instant: new Date(),
                  };
                  localStorage.setItem('layout', JSON.stringify(layouts));
                } else {
                  let layouts = {};
                  layouts[this.pageId] = {
                    instance: layout,
                    instant: new Date(),
                  };
                  localStorage.setItem('layout', JSON.stringify(layouts));
                }
              })
              .finally(() => {
                this.spinner.hide();
              });
          }
        });
      });
    });
  }

  onAddSection(toSend: boolean = false) {
    if (toSend && this.isNewSection && !!this.newSection.SECTION) {
      this.generalDataService
        .postDataDetail(this.apiParent + '/' + this.apiUrl + '/section', {
          DOCUMENTS_ID: null,
          DOCUMENT_TYPE: 'GENERAL',
          SECTION: this.newSection.SECTION,
          FILE_NAME: null,
          FILE_PATH: null,
          EXPIRED: null,
          L_DOCUMENTS: [],
        })
        .subscribe((res) => {
          this.isNewSection = false;
          this.newSection = {
            DOCUMENTS_ID: '',
            DOCUMENT_TYPE: '',
            EXPIRED: new Date(),
            FILE_NAME: '',
            FILE_PATH: '',
            L_DOCUMENTS: [],
            SECTION: '',
          };
          this.refreshData();
        });
    } else {
      this.isNewSection = !this.isNewSection;

      this.newSection = {
        DOCUMENTS_ID: '',
        DOCUMENT_TYPE: '',
        EXPIRED: new Date(),
        FILE_NAME: '',
        FILE_PATH: '',
        L_DOCUMENTS: [],
        SECTION: '',
      };
    }
  }

  uploadDoc(documentsId: string) {
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });
    dialogEvent.afterClosed().subscribe((dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        //this.fileToSend = dndResponse.files;
        this.spinner.show();
        lastValueFrom(
          this.generalDataService.uploadDoc(
            this.apiParent + '/' + this.apiUrl,
            documentsId,
            dndResponse.files[0]
          )
        )
          .then((res) => {
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: 'File uploaded',
              timeout: 5000,
              selfClose: null,
            });
          })
          .catch((err) => {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message:
                'Something went wrong, please try again or contact webmaster',
              timeout: 5000,
              selfClose: null,
            });
          })
          .finally(() => {
            this.spinner.hide();
            this.refreshData();
          });
      }
    });
  }

  deleteArchive(documentsId: string) {
    lastValueFrom(
      this.generalDataService.deleteArchive(
        this.apiParent + '/' + this.apiUrl + '/section',
        documentsId
      )
    )
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: 'Delete entire archive',
          timeout: 5000,
          selfClose: null,
        });
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Delete entire archive was wrong',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.refreshData();
      });
  }

  deleteDoc(documentsId: string, documentId: string) {
    lastValueFrom(
      this.generalDataService.deleteDocument(
        this.apiParent + '/' + this.apiUrl,
        documentsId,
        documentId
      )
    )
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: 'Delete document success',
          timeout: 5000,
          selfClose: null,
        });
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Delete document was wrong',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.refreshData();
      });
  }

  downloadDoc(
    documentsId: string,
    documentId: string,
    fileType: string,
    fileName: string
  ) {
    lastValueFrom(
      this.generalDataService.downloadDocument(
        this.apiParent + '/' + this.apiUrl + '/download',
        documentId
      )
    )
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Something went wrong during download ' + fileName,
          timeout: 5000,
          selfClose: null,
        });
      });
  }

  renameSection(archive: Archive | null) {
    this.section = _.cloneDeep(archive);
  }

  saveSection(section: Archive) {
    if (!!this.section) {
      this.generalDataService
        .postDataDetail(
          this.apiParent + '/' + this.apiUrl + '/section',
          this.section
        )
        .subscribe((res) => {
          this.isNewSection = false;
          this.section = null;
          this.refreshData();
        });
    }
  }
}

export interface Document {
  DOCUMENTS_ID: string;
  DOCUMENT_ID: string;
  FILE_NAME: string;
  FILE_TYPE: string;
}

export interface Archive {
  DOCUMENTS_ID: string;
  DOCUMENT_TYPE: string;
  SECTION: string;
  FILE_NAME: string;
  FILE_PATH: string;
  EXPIRED: Date;
  L_DOCUMENTS: any;
}
