import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {EventEmitter} from '@angular/core';
import {RegExpInterface} from "../../../models/RegExpInterface";
import {RegexParser} from "../../../utils/generic";

@Component({
  selector: 'custom-text-field',
  templateUrl: './custom-text-field.component.html',
  styleUrls: ['./custom-text-field.component.scss'],
})
export class CustomTextFieldComponent implements OnInit {
  /*@ViewChild('input', { static: false })
  input: any | undefined;*/

  @Input()
  value: any = '';

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  @Input()
  appearance!: MatFormFieldAppearance;

  @Input()
  label: String | undefined;

  @Input()
  placeholder: String | undefined;

  @Input()
  disabled: Boolean | undefined = false;

  @Input()
  isNumber: Boolean = false;

  @Input()
  formControl;

  @Input()
  matAutocomplete;

  @Input()
  customClass: string = '';

  @Input()
  min = 0;

  @Input()
  minlength;

  @Input()
  maxlength;

  @Input()
  regexps: RegExpInterface[] = []

  @Input()
  pattern = '';

  @Input()
  isMandatory: Boolean = true;

  constructor() {
  }

  ngOnInit(): void {
    //////(this.disabled);
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  onInputChange = (value) => {
    if(this.regexps.length > 0){
      this.regexps.forEach(regExp => {
        value.target.value = value.target.value.replace(RegexParser(regExp.regExp), regExp.replaceValue)
      })

      /*this.regexps.forEach(regExp => {

        value.target.value = value.target.value.replace(RegexParser(regExp.regExp), regExp.replaceValue)
      })*/
    }
  }
}
