import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {NgxSpinnerService, Spinner} from "ngx-spinner";


interface SPINNER_SEQ {
  name: string;
  load: number;
}

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  numOfSpinner: SPINNER_SEQ[] = [];



  constructor(private spinner: NgxSpinnerService) {
  }

  hide(name: string = 'data', debounce: number = 10): Promise<unknown> {
    if(this.numOfSpinner.filter(spinner => spinner.name == name && spinner.load > 1).length > 0){
      this.numOfSpinner = this.numOfSpinner.map(spinner => {
        if(spinner.name == name){
          spinner.load -= 1;
        }
        return spinner;
      })
      return Promise.resolve()
    }else{
      this.numOfSpinner = this.numOfSpinner.filter(spinner => spinner.name != name)
      return this.spinner.hide(name)
    }




  }

  show(name: string = 'data', spinner?: Spinner): Promise<unknown> {
    if(this.numOfSpinner.filter(spinner => spinner.name == name).length > 0){
      this.numOfSpinner = this.numOfSpinner.map(spinner => {
        if(spinner.name == name){
          spinner.load += 1;
        }
        return spinner;
      })
      return Promise.resolve()
    }else{
      this.numOfSpinner.push({
        name: name,
        load: 1
      });
      return this.spinner.show(name)
    }

  }

  hideAll(): Promise<unknown>{
    this.numOfSpinner.forEach(spinner => this.spinner.hide(spinner.name))
    this.numOfSpinner = [];
    return Promise.resolve()
  }

}
