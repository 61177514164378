import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import { environment } from 'src/environments/environment';
import {RedirectRequest} from "@azure/msal-browser";
import {MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";

@Injectable()
export class AuthService {
  nameBS = new BehaviorSubject<string>('');
  nameObs = this.nameBS.asObservable();

  emailBS = new BehaviorSubject<string>('');
  emailObs = this.emailBS.asObservable();

  roleBS = new BehaviorSubject<string>('');
  roleObs = this.roleBS.asObservable();

  test: String = 'test';

  _isLoggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn = this._isLoggedIn.asObservable();

  constructor(private http: HttpClient, private msalService: MsalService, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {}

  headers = new HttpHeaders();

  getMenu() {
    return this.http.get<any>(environment.apiUrl + 'security/layout', {});
  }

  getPages() {
    return this.http.get<any>(environment.apiUrl + 'security/pages', {});
  }

  getUser() {
    return this.http.get<any>( environment.apiUrl + 'security/user', {});
  }

  setFilter(filters: string) {
    return this.http.post<any>( environment.apiUrl + 'security/user/filter', {filters});
  }

  login(): void {
    if (!this.msalService.instance.getActiveAccount()) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    } else {
      localStorage.setItem(
        'token',
        <string>this.msalService.instance.getActiveAccount()?.idToken
      );
    }
  }

  logout() {
    this.msalService.instance.logout();
  }



  refresh() {

    of(Object.keys(localStorage)
      .filter(key => !key.includes("appVersion"))
      .map(key => localStorage.removeItem(key))
    ).subscribe(res =>
      this.msalService.logoutRedirect()
    );

  }
}
