<!--<h1 class="h1-title">{{titlePage + " Scorecard"| titlecase}}</h1>-->
<!-- <h1 class="h1-title">{{titlePage | titlecase}}</h1> -->


<div *ngIf="titlePage.toLowerCase().trim() === 'warehouse'; else primaryView" class="warehouse">
  <h1 class="h1-title">{{titlePage | titlecase}}</h1>
  <div class="edit"></div>
</div>

<ng-template #primaryView>
  <div class="primary">
    <h1 class="h1-title">{{titlePage | titlecase}}</h1>
    <div class="edit"></div>
  </div>

</ng-template>

<!--<div class="edit"> -->

<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">
  <ng-container *ngFor="let sectionLayout of layout['sectionLayouts']; let si = index">
    <div (contextmenu)="foo($event)"
      *ngIf="sectionLayout['id'] == 'LYO_SECTABLE' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
      class="scrolling-wrapper-flexbox" (scrollend)="reachTheEnd($event)">
      <ng-container *ngIf="!!tableController.dataSet && tableController.dataSet.length >0">
        <span (click)="selectScoreCard(data, i)" (contextmenu)="foo($event)"
          (longPress)="onLongPressClick(data, i, sectionLayout.actionset[0].actions)"
          *ngFor="let data of tableController.dataSet; let i= index"
          [ngClass]="{'selected-scorecard': tableController.rowNumber == i}" class="card no-selectable-item"
          id="{{'scorecard_'+i}}">
          <ng-container *ngFor="let column of (sectionLayout['dataset'])[0].datas; let j= index">
            <div *ngIf="column.columnName == 'SCORECARD_ID'" class="card-sticky-title"><label
                class="fw-bold">{{data[column.columnName]}}</label></div>
            <div *ngIf="column.columnName != 'SCORECARD_ID'" class="card-content"><label>{{column.columnLabel |
                translate}}
                : {{data[column.columnName]}}</label></div>
          </ng-container>
          <div class="card-index fw-bold"><label>{{i + 1}} / {{tableController.dataSet.length}}</label></div>
        </span>
      </ng-container>
      <ng-container *ngIf="!(!!tableController.dataSet) || tableController.dataSet.length < 1">
        <span class="card no-selectable-item no-found-card">
          <div class="card-content no-found-card-content"><label class="no-found-card-label">{{'NO SCORECARD FOUND'}}</label></div>
        </span>
      </ng-container>

    </div>
    <div *ngIf="sectionLayout['id'] == 'LYO_SECBUTTON'">
      <ng-container *ngIf="sectionLayout['id'] == 'LYO_SECBUTTON'">
        <action-bar>
          <div leftBtn class="col-btn">

            <custom-icon-btn [compareTo]="null" [masterFrom]="null" backNavigation color="accent"
              [iconClasses]="'arrow_back'" [overriderPath]="'/'"></custom-icon-btn>

          </div>

          <div class="col-btn" centerBtn>
            <ng-container *ngFor="let action of sectionLayout.actionset[0]?.actions ?? []">
              <div *ngIf="action.id == 'ACT_ADD' && apiParent != 'warehousing'">
                <custom-icon-btn (click)="actionCatcher(action.id + '_ALL')" [btnClasses]="'fill-available'"
                  color="accent" [iconClasses]="action.icon"></custom-icon-btn>
              </div>
              <div *ngIf="action.id == 'ACT_ADD' && apiParent == 'warehousing'">
                <custom-icon-btn (click)="actionCatcher(action.id + '_WH' + '_ALL')" [btnClasses]="'fill-available'"
                  color="accent" [iconClasses]="action.icon"></custom-icon-btn>
              </div>
            </ng-container>
          </div>

          <ng-container *ngFor="let action of sectionLayout.actionset[0]?.actions ?? []" rightBtn>
            <div *ngIf="action.id == 'ACT_EDIT'">
              <custom-btn (click)="actionCatcher(action.id + '_ID')" [btnClasses]="'fill-available'"
                [disabled]="tableController.rowNumber == -1" color="accent">Edit
              </custom-btn>
            </div>
            <!--
            <div *ngIf="action.id == 'ACT_DELETE'">
              <custom-btn (click)="actionCatcher(action.id+ '_ID')" [btnClasses]="'fill-available'"
                [disabled]="tableController.rowNumber == -1" color="accent">Delete
              </custom-btn>
            </div>
-->
          </ng-container>
        </action-bar>
      </ng-container>
    </div>
  
  </ng-container>
</ng-container>
