import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DndComponent} from "../dnd/dnd.component";
import {lastValueFrom} from "rxjs";
import {ALERT_TYPE} from "../alert/alert.enumerate";
import {GeneralService} from "../../../services/crud/general.service";
import {AlertService} from "../alert/alert.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import * as _ from "lodash";
import {cloneDeep} from "lodash";
import * as saveAs from "file-saver";
import {NgxImageCompressService} from "ngx-image-compress";
import {Document} from "../../pages/documents/documents.component";
import {TableControllerService} from "src/app/services/table-controller.service";
import {compressAccurately} from "image-conversion";

interface Picture {
  pictureId: string;
  pictureName: string;
  pictureType: string;
  pictureBlob: any;
  pictureUrl: SafeUrl;
}

export interface Media {
  id: string,
  path: string,
  name: string,
  type: string,
  parent: string,
  category: string,
  url: string
  mediaType: string,
  damage: string,
  problem: string,
  card: string,
  section: string,
}

export interface CameraRollDialogDataType {
  path: string,
  parent: string,
  parent2: string,
  parentType: string,
  files: Media[],
  isViewOnly: boolean,
  cancelButtonLabel?: string,
  cancelButtonColor?: string,
  confirmButtonLabel?: string,
  confirmButtonColor?: string,
  title: string,
  apiUrl:string
}

@Component({
  selector: "camera-roll",
  templateUrl: "./camera-roll-v2.component.html",
  styleUrls: ["./camera-roll-v2.component.scss"],
  providers: [TableControllerService],
})
export class CameraRollV2Component implements OnInit {

  isGettingImage: boolean = true;

  pictureMaxSize: number = 500;

  path: string;
  parent: string;
  parent2: string;
  parentType: string;

  isViewOnly: boolean = false;


//////
  attachments: Media[] = [];

  loading: number = 0;

  constructor(
    public dialogRef: MatDialogRef<CameraRollV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: CameraRollDialogDataType,
    public dialog: MatDialog,
    private generalDataService: GeneralService,
    public alertService: AlertService,
    private sanitizer: DomSanitizer,
    private imageCompressService: NgxImageCompressService,
    public tableController: TableControllerService,
  ) {
    dialogRef.disableClose = true;
    this.path = data.path;
    this.parent = data.parent
    this.parent2 = data.parent2
    this.parentType = data.parentType
    this.attachments = data.files
    this.isViewOnly = data.isViewOnly
    //this.loading = 0;

  }

  ngOnInit(): void {
    //this.loading = 0;
  }

  uploadDoc() {
    if (this.data.isViewOnly) {
      return;
    }

    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: "UPLOADING",
        code: "Upload file: ",
        message: "Are you sure you want to upload this file?",
        confirmBtn: "Upload",
        cancelBtn: "Close",
        type: "image",
      },
    });
    dialogEvent.afterClosed().subscribe((dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        for (let i = 0; i < dndResponse.files.length; i++) {

          this.loading += 1;
          this.generalDataService.uploadAttachment(this.parent, this.parent2, this.parentType, this.path, dndResponse.files[i]).subscribe({
            next: value => {
              this.alertService.add({
                type: ALERT_TYPE.SUCCESS,
                message: "File uploaded",
                timeout: 5000,
                selfClose: null,
              });
              this.attachments.push(value.oContent)

              this.loading -= 1;
            }, error: err => {
              this.alertService.add({
                type: ALERT_TYPE.WARNING,
                message: "Something went wrong, please try again or contact webmaster",
                timeout: 5000,
                selfClose: null,
              });
              this.loading -= 1;
            }

          })

        }
      }
    });
  }

  deletePicture(id: string) {

    lastValueFrom(this.generalDataService.deleteAttachment(id))
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: "Delete document success",
          timeout: 5000,
          selfClose: null,
        });

        this.attachments = this.attachments.filter(f => f.id !== id)

      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: "Delete document was wrong",
          timeout: 5000,
          selfClose: null,
        });
      });
  }

  download(url: string, name: string) {
    console.log(url,name)
    saveAs(url, name)
  }

  processFile(imageInput: HTMLInputElement) {
    const images = Promise.all(Array.from(imageInput.files ?? []).map(async (file: File) => {
      const blob = await compressAccurately(file, this.pictureMaxSize);
      return new File([blob], file.name);
    })).then(imageArray => {
      imageArray.forEach(m => {
        this.generalDataService.uploadAttachment(this.parent, this.parent2, this.parentType, this.path,m).subscribe({
          next: value => {
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: "File uploaded",
              timeout: 5000,
              selfClose: null,
            });
            this.attachments.push(value.oContent)
          },
          error: err => {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: "Something went wrong, please try again or contact webmaster",
              timeout: 5000,
              selfClose: null,
            });
          }
        })
      })
    })
  }
}
