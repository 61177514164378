<div class="paginator-container">
  <div class="paginator-load-batch">
    <label class="paginator-load-batch-title">{{paginatorLoaderTitle}}</label>
    <mat-select class="paginator-load-batch-selector" #load [(ngModel)]="paginatorLoader"
      (selectionChange)="onPageChange($event)">
      <mat-option *ngFor="let option of paginatorLoaderList ?? []" [value]="option">{{option}}
      </mat-option>
    </mat-select>
  </div>
  <span class="paginator-spacer"></span>
  <div class="paginator-page">
    <label class="paginator-load-page-title">{{((numElementsLoaded ) > numElementsSize ? numElementsSize :
      (numElementsLoaded)) + ' / ' + numElementsSize}}</label>

    <custom-btn [disabled]="isAllLoaded || ((numElementsLoaded ) >= numElementsSize)" (click)="onLoadClick($event)">Load
    </custom-btn>
  </div>
</div>
