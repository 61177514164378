import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {DamageDetail} from '../../../../models/response';
import {
  Data,
  DataSet,
  Layout,
  SectionLayout,
} from '../../../../models/layout';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {LayoutServiceService} from '../../../../services/layout.service';
import {DialogComponent} from '../../dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {TableControllerService} from '../../../../services/table-controller.service';
import {BehaviorSubject, lastValueFrom, Observable, of, take} from 'rxjs';
import {map} from 'rxjs/operators';
import {hasSubArray} from '../../../../utils/generic';
import {HttpParams} from '@angular/common/http';
import {GeneralService} from '../../../../services/crud/general.service';
import {IMultiSelectOption} from '../../custom-multiselect/types';
import {NgxSpinnerService} from 'ngx-spinner';

import * as _ from 'lodash';
import {LaneSelectionComponent} from '../../../pages/lane-selection/lane-selection.component';
import {DraftDialogComponent} from '../../draft-dialog/draft-dialog.component';
import {AlertService} from '../../alert/alert.service';
import {ALERT_TYPE} from '../../alert/alert.enumerate';
import {SpinnerService} from '../../../../services/spinner.service';
import {NavigationService} from '../../../../services/utils/navigation.service';
import * as saveAs from 'file-saver';
import {Document} from '../../../pages/documents/documents.component';
import {DndComponent} from '../../dnd/dnd.component';
import {CameraRollComponent} from '../../camera-roll/camera-roll.component';

@Component({
  selector: 'damage-detail',
  templateUrl: './damage-detail.component.html',
  styleUrls: ['./damage-detail.component.scss'],
  providers: [TableControllerService],
})
export class DamageDetailComponent implements OnInit {
  layout: Layout | undefined;

  _detail: DamageDetail | undefined;

  get detail(): DamageDetail | undefined {
    return this._detail;
  }

  set detail(value: DamageDetail | undefined) {
    this._detail = value;
  }

  isEditable = (this.detail?.STATUS ?? 'CANCELED') != 'CANCELED';

  detailBck: DamageDetail | undefined;

  isNew: string = 'false';

  pageId: string = '';
  damageId: string = '';
  titlePage: string = 'Damage ' + this.damageId + ' detail';

  apiParent: string = 'primary';

  apiUrl: string = 'damages/detail';

  efficiencyDataset = {
    multiselect: true,
    datas: [
      {
        columnLabel: 'Description',
        type: 'DTY_NVAR',
        editable: false,
        columnName: 'LABEL',
      },
    ],
  };

  damageType: string = '';

  optionListMap: Map<string, IMultiSelectOption[]> = new Map<string,
    IMultiSelectOption[]>();

  private history: string[] = [];

  attachments: Document[] = [];

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private route: ActivatedRoute,
    public layoutService: LayoutServiceService,
    public dialog: MatDialog,
    public efficiencyTableController: TableControllerService,
    public problemsTableController: TableControllerService,
    private generalDataService: GeneralService,
    private spinner: SpinnerService,
    private _location: Location,
    public alertService: AlertService,
    private navigation: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.spinner.hideAll();
    this.route.queryParams.subscribe((params) => {
      this.pageId = params['id'] ?? [];
      this.damageId = params['damage'] ?? '';

      this.damageType = (params['t'] ?? '').toUpperCase();

      this.isNew = params['n'] ?? 'false';

      const layoutsString = localStorage.getItem('layout');
      if (!!layoutsString) {
        const layouts = JSON.parse(layoutsString);
        if (!!layouts && !!layouts[this.pageId]) {
          const pageLayout = layouts[this.pageId];
          if (
            Math.abs(
              (new Date(pageLayout['instant']).getTime() -
                new Date().getTime()) /
              60000
            ) < 360
          ) {
            this.layout = pageLayout['instance'];
            if (!!this.layout) this.catchAllSelectedOptions(this.layout);
          } else {
            this.spinner.show();
            this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
              this.layout = res?.layouts[0] ?? undefined;
              const layoutsString = localStorage.getItem('layout');
              if (!!layoutsString) {
                let layouts = JSON.parse(layoutsString);
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              } else {
                let layouts = {};
                layouts[this.pageId] = {
                  instance: res?.layouts[0],
                  instant: new Date(),
                };
                localStorage.setItem('layout', JSON.stringify(layouts));
              }
              ////(this.layout)
              this.spinner.hide();
              if (!!this.layout) this.catchAllSelectedOptions(this.layout);
              /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                     this.pageActionset = this.pageLayouts[0].actionset ?? [];
                     this.pageDataset = this.pageLayouts[0].dataset ?? [];
                     ////(this.pageActionset)
                   }*/
            });
          }
        } else {
          this.spinner.show();
          this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
            this.layout = res?.layouts[0] ?? undefined;
            const layoutsString = localStorage.getItem('layout');
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            }
            ////(this.layout)
            this.spinner.hide();
            if (!!this.layout) this.catchAllSelectedOptions(this.layout);
            /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                   this.pageActionset = this.pageLayouts[0].actionset ?? [];
                   this.pageDataset = this.pageLayouts[0].dataset ?? [];
                   ////(this.pageActionset)
                 }*/
          });
        }
      } else {
        this.spinner.show();
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          ////(this.layout)
          this.spinner.hide();
          if (!!this.layout) this.catchAllSelectedOptions(this.layout);
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                 this.pageActionset = this.pageLayouts[0].actionset ?? [];
                 this.pageDataset = this.pageLayouts[0].dataset ?? [];
                 ////(this.pageActionset)
               }*/
        });
      }
    });

    this.route.parent?.url
      .pipe(map((segments) => segments.join('/')))
      .subscribe((finalParentUrl) => {
        this.apiParent = finalParentUrl;
      });

    if (this.isNew == 'true') {
      const localDamage: DamageDetail | undefined = window.history.state;

      let params = new HttpParams().set('t', this.damageType);
      this.spinner.show();
      this.generalDataService
        .getData(
          this.apiParent + '/' + this.apiUrl + '/efficiency-problem',
          undefined,
          params
        )
        .pipe(
          map((sp) =>
            sp.map((entry) => ({
              ID: entry['ID'] ?? '',
              LABEL: entry['LABEL'] ?? '',
              SELECTED: entry['SELECTED'] ?? false,
            }))
          )
        )
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.detail = {
              DAMAGE_ID: '',
              SENDING_WH: localDamage?.SENDING_WH ?? '',
              RECEIVING_WH: localDamage?.RECEIVING_WH ?? '',
              DAMAGE_TYPE_ID: this.damageType.toUpperCase(),
              SENDING_DATE: '',
              RECEIVING_DATE: '',
              RECEIVING_WEEK: '',
              DESPATCHED_QTY: 0,
              DAMAGED_APP_TOT_QTY: 0,
              DAMAGED_PKG_TOT_QTY: 0,
              STATUS: 'OPEN',
              INSERT_USER: '',
              INSERT_DATE: '',
              UPDATE_USER: '',
              UPDATE_DATE: '',
              SHIPMENT_ID: localDamage?.SHIPMENT_ID ?? '',
              DELIVERY_TYPE_ID: '',
              DELIVERY_MODE_ID: '',
              CARRIER_ID: '',
              WAGON: '',
              TRUCK_PLATE: '',
              OTM_SHIPMENT_ID: '',
              CONTAINER: '',
              TRAILER_PLATE: '',
              REMARKS: '',
              L_EFFICIENCY_PROBLEMS: res,
              L_PROBLEMS: [],
              DESTINATION_WAREHOUSE_ID:'',
            };

            this.isEditable = (this.detail?.STATUS ?? 'CANCELED') != 'CANCELED';
          },
          error: (err) => {
            console.log(err)
          }
          }

          );
    } else {
      this.detail = window.history.state;
      this.detailBck = _.cloneDeep(window.history.state);
      this.refreshData(false);
    }

    if (!!this.detail && this.isNew == 'true') {
      if (!!this.detail?.SENDING_DATE)
        this.detail.SENDING_DATE =
          this.detail?.SENDING_DATE.split('-').join('/');
      if (!!this.detail?.RECEIVING_DATE)
        this.detail.RECEIVING_DATE =
          this.detail?.RECEIVING_DATE.split('-').join('/');
    }

    /*of(history.state).subscribe((state) => {
      if (!!state && !!state.data) {
        if (this.isNew) {
          const url: Observable<string> = this.route.url.pipe(
            map((segments) => segments.join('/'))
          );
          url.subscribe((url) => {
            this.apiUrl = url;
          });
        } else {
          this.damageId = state.data['DAMAGE_ID'];
          const url: Observable<string> = this.route.url.pipe(
            map((segments) => segments.join('/'))
          );
          url.subscribe((url) => {
            this.apiUrl = url;
            this.refreshData(false);
          });
        }

        ////(this.damageId)
      } else {
        this.refreshData(false);
      }
    });*/
  }

  ngOnDestroy() {
    localStorage.setItem('changes', 'false');
  }

  ngAfterContentChecked() {
    if (!_.isEqual(this.detailBck, this.detail)) {
      localStorage.setItem('changes', 'true');
    } else {
      localStorage.setItem('changes', 'false');
    }
  }

  refreshData(close: Boolean, isSearching: boolean = true) {
    if ((!this.damageId && this.isNew != 'true') || close) {
      this.navigation.back();
    }

    this.refreshDocumentData();

    this.spinner.show();
    let params = new HttpParams().set('id', this.damageId);
    this.generalDataService
      .getDetail(this.apiParent + '/' + this.apiUrl, undefined, params)
      .pipe(map((res) => res['oContent']))
      .pipe(
        map((sp) => ({
          DAMAGE_ID: sp['DAMAGE_ID'],
          SENDING_WH: sp['SENDING_WH'],
          RECEIVING_WH: sp['RECEIVING_WH'],
          SENDING_DATE: sp['SENDING_DATE'],
          RECEIVING_DATE: sp['RECEIVING_DATE'],
          RECEIVING_WEEK: sp['RECEIVING_DATE'],
          DESPATCHED_QTY: sp['DESPATCHED_QTY'],
          DAMAGED_APP_TOT_QTY: sp['DAMAGED_APP_TOT_QTY'],
          DAMAGED_PKG_TOT_QTY: sp['DAMAGED_PKG_TOT_QTY'],
          STATUS: sp['STATUS'],
          INSERT_USER: sp['INSERT_USER'],
          INSERT_DATE: sp['INSERT_DATE'],
          UPDATE_USER: sp['UPDATE_USER'],
          UPDATE_DATE: sp['UPDATE_DATE'],
          SHIPMENT_ID: sp['SHIPMENT_ID'],
          DELIVERY_TYPE_ID: sp['DELIVERY_TYPE_ID'],
          DELIVERY_MODE_ID: sp['DELIVERY_MODE_ID'],
          CARRIER_ID: sp['CARRIER_ID'],
          WAGON: sp['WAGON'],
          TRUCK_PLATE: sp['TRUCK_PLATE'],
          OTM_SHIPMENT_ID: sp['OTM_SHIPMENT_ID'],
          CONTAINER: sp['CONTAINER'],
          TRAILER_PLATE: sp['TRAILER_PLATE'],
          REMARKS: sp['REMARKS'],
          DAMAGE_TYPE_ID: sp['DAMAGE_TYPE_ID'],
          L_EFFICIENCY_PROBLEMS: sp['L_EFFICIENCY_PROBLEMS'],
          L_PROBLEMS: sp['L_PROBLEMS'],
          DESTINATION_WAREHOUSE_ID: sp['DESTINATION_WAREHOUSE_ID']
        }))
      )
      .subscribe(
        (res) => {
          //this.dataSet = res['oContent']['content'];
          ////(res)
          this.spinner.hide();
          this.detail = res;
          this.isEditable = (this.detail?.STATUS ?? 'CANCELED') != 'CANCELED';

          this.detailBck = _.cloneDeep(res);

          this.efficiencyTableController.dataSet =
            this.detail.L_EFFICIENCY_PROBLEMS ?? [];
          this.efficiencyTableController.selection.select(
            this.efficiencyTableController.dataSet.filter(
              (entry) => entry['SELECTED']
            )
          );

          this.problemsTableController.dataSet = this.detail.L_PROBLEMS ?? [];
          this.problemsTableController.selection.clear();

          /*this.tableController.dataSet = !!this.tableController.dataSet
          ? hasSubArray(
            this.tableController.dataSet,
            res['oContent']['content']
          )
          : res['oContent']['content'];
        this.tableController.numElementsLoaded =
          this.tableController.dataSet.length;
        this.tableController.numElementsSize = res['oContent'].totalElements;
        this.spinner.hide();*/
        },
        (error) =>
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: 'Damage ' + this.damageId + ' no correctly loaded.',
            timeout: 20000,
            selfClose: null,
          })
      );
  }

  uploadFiles(files: File[]) {
  }

  showFiles() {
    if (!!this.detail && !!this.detail.DAMAGE_ID) {
      lastValueFrom(
        this.generalDataService.downloadAllDocument(
          this.apiParent + '/' + this.apiUrl + '/docs',
          this.detail.DAMAGE_ID
        )
      )
        .then((res) => {
        })
        .catch((err) => {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message: "Unable to load damage's documents",
            timeout: 10000,
            selfClose: null,
          });
        });
    }
  }

  goToProblem(problem?: any) {
    if (!!this.detail && !!this.detail.DAMAGE_ID) {
      //(this.damageId)
      this.router.navigate(['page/' + this.apiParent + '/problems/detail'], {
        queryParams: {
          id:
            this.navigation.changeQueryParmas(
              'page/' + this.apiParent + '/problems/detail'
            ) ?? '',
          n: !problem,
          damageId: this.detail.DAMAGE_ID,
          problemId: problem?.PROBLEM_ID ?? 'null',
          dmgType: this.damageType,
          dmgStatus: this.detail.STATUS,
        },
      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved damage',
          content: `Please save the damage before creating the problem.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (!!res) {
          //salvo damage
        }
      });
    }
  }

  onAddProblemClick(problem?: any) {
    if (this.detail?.STATUS == 'CANCELED' && problem == null) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unable to add problem',
          content: `This damage is in the CANCEL CLAIM state so no PROBLEMs can be added.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
      });

      return;
    }

    if (
      this.isNew == 'false' &&
      this.detail != undefined &&
      this.detailBck != undefined &&
      Object.keys(this.detail).filter(
        (key) =>
          key != 'L_PROBLEMS' &&
          key != 'L_EFFICIENCY_PROBLEMS' &&
          this.detail![key] != this.detailBck![key]
      ).length < 1
    ) {
      this.goToProblem(problem);
      return;
    }

    if (this.isNew == 'true') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved changes',
          content: `You are creating a new damage, please save it before proceeding.`,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'basic',
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
      });

      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Unsaved changes',
        content: `Please save your damage before proceeding`,
        cancelButtonLabel: 'Close',
        cancelButtonColor: 'basic',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res == true) {
        this.goToProblem(problem);
      }
    });
  }

  actionCatcher(event: string) {

    console.log(event)

    if (event.endsWith('_ALL')) {
      /*if (event.startsWith('ACT_EXPORT')) {
        this.generalDataService
          .exportDataExcel(
            this.apiUrl,
            'excel',
            this.savedParams.set(
              'dataset',
              this.layout!['sectionLayouts'].filter(
                (sectionLayout) => sectionLayout.id == 'LYO_SECTABLE'
              ).pop()?.dataset[0].id ?? ''
            ),
            []
          )
          .subscribe((blob) => {
            saveAs(blob, 'excel.xlsx');
            this.spinner.hide();
          });
      }*/
      /*if (event.startsWith('ACT_TO_LANE')) {
        const dialogRef = this.dialog.open(LaneSelectionComponent, {
          data: { title: 'Lane Selection' },
          width: '800px',
          disableClose: true,
        });

        lastValueFrom(dialogRef.afterClosed())
          .then((result) => {
            if(!!result){
              //(this.problemsTableController.row)
              this.router.navigate(['page/primary/damages/detail'], {state: result , queryParams: {id: 'PAG_DAMAGE_DETAIL', n: true}});
            }
          });
      }*/
      if (event.startsWith('ACT_TO_PROBLEM')) {
        this.onAddProblemClick();
      }
    } else if (event.endsWith('_ID')) {
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Confirm delete selected rows',
            content: `Are you sure to proceed?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          ////(res);
          if (res) {
            this.spinner.show();
            lastValueFrom(
              this.generalDataService.deleteData(
                this.apiParent + '/problems',
                this.problemsTableController.deletedRows,
                new HttpParams().set('damageId', this.damageId)
              )
            )
              .then((res) => {
                this.alertService.add({
                  type: ALERT_TYPE.INFO,
                  message: 'Row deleted.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .catch((err) => {
                this.alertService.add({
                  type: ALERT_TYPE.WARNING,
                  message: 'Unable to delete selected problem.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .finally(() => {
                this.problemsTableController.deletedRows = [];
                this.spinner.hide();
                this.refreshData(false, false);
              });
          } else {
            this.problemsTableController.deletedRows = [];
          }
        });
      }

      if (event.startsWith('ACT_EDIT_PROBLEM')) {
        this.onAddProblemClick(this.problemsTableController.row);
      }

      if (event.startsWith('ACT_SHOW_CAMERA')) {
        if (!!this.problemsTableController.row['PROBLEM_ID']) {
          lastValueFrom(
            this.generalDataService.getDataById(
              this.apiParent + '/problems/detail/pictures',
              'problem',
              this.problemsTableController.row['PROBLEM_ID']
            )
          ).then((pictures) => {
            const dialogRef = this.dialog.open(CameraRollComponent, {
              width: '60vw',
              data: {
                title:
                  'Camera roll for ' +
                  this.problemsTableController.row['PROBLEM_ID'] +
                  ' problem',
                content: pictures['oContent'],
                apiUrl: this.apiParent + '/problems/detail/pictures',
                problemId: this.problemsTableController.row['PROBLEM_ID'],
                cancelButtonLabel: 'Close',
                cancelButtonColor: 'accent',
              },
            });
          });
        }
      }

      /*if (event.startsWith('ACT_SAVE')) {
        this.spinner.show();
        this.generalDataService
          .postData(
            this.apiUrl,
            this.problemsTableController.dataSet
              .filter((row) => row.edited != undefined)
              .map((elem) => {
                delete elem.edited;
                delete elem.checked;
                return elem;
              })
          )
          .subscribe((res) => {
            this.spinner.hide();
            this.onCancelClick();
          });
      }*/
    } else {
      if (this.problemsTableController.selection.selected.length == 0) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'No rows selected',
            content: `Please select at least 1 row...`,
            confirmButtonLabel: 'Ok',
            confirmButtonColor: 'basic',
          },
        });
        return;
      }
      /*if (event.startsWith('ACT_EXPORT')) {
        if (!!this.layout)
          this.savedParams = this.savedParams.append(
            'dataset',
            this.layout['sectionLayouts']
              .filter((sectionLayout) => sectionLayout.id == 'LYO_SECTABLE')
              .pop()?.dataset[0].id ?? ''
          );

        this.generalDataService
          .exportDataExcel(
            this.apiUrl,
            'excel',
            this.savedParams.set(
              'dataset',
              this.layout!['sectionLayouts'].filter(
                (sectionLayout) => sectionLayout.id == 'LYO_SECTABLE'
              ).pop()?.dataset[0].id ?? ''
            ),
            this.tableController.selection.selected
          )
          .subscribe((blob) => {
            saveAs(blob, 'excel.xlsx');
            this.spinner.hide();
          });
      }*/
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: 'Confirm delete selected rows',
            content: `Are you sure to proceed?`,
            //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.spinner.show();
            const param = new HttpParams().set('damageId', this.damageId);
            lastValueFrom(
              this.generalDataService.deleteData(
                this.apiParent + '/problems',
                this.problemsTableController.selection.selected,
                param
              )
            )
              .then((res) => {
                this.alertService.add({
                  type: ALERT_TYPE.INFO,
                  message: 'Row deleted.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .catch((err) => {
                this.alertService.add({
                  type: ALERT_TYPE.WARNING,
                  message: 'Unable to delete selected problem.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .finally(() => {
                this.problemsTableController.deletedRows = [];
                this.problemsTableController.selection.clear();
                this.spinner.hide();
                this.refreshData(false, false);
              });
          } else {
            this.problemsTableController.deletedRows = [];
          }
        });
      }
      /*if (event.startsWith('ACT_DRAFT')) {
        if (!!this.tableController.selection.selected) {
          const dialog = this.dialog.open(DraftDialogComponent, {
            width: '65vw',
            data: {
              title: 'Notify Warehouse',
              damageIds: this.tableController.selection.selected,
              typology: 'default',
              notificationId: 'default',
              closeButtonLabel: 'Close',
              confirmButtonLabel: 'Send',
            },
            backdropClass: 'backdropBackground',
          });

          dialog.afterClosed().subscribe((res) => {
            if (!!res && res) {
              ////(res);
            }
          });

          this.tableController.selection.clear();
        }
      }*/
    }
  }

  onSaveClick(event: any, close: Boolean) {
    //(this.detail);

    this.spinner.show();
    lastValueFrom(
      this.generalDataService.postDataDetail(
        this.apiParent + '/' + this.apiUrl,
        this.detail
      )
    )
      .then((res) => {
        this.damageId = res['oContent']?.DAMAGE_ID ?? '';
        this.isNew = 'false';
        this.alertService.add({
          type: ALERT_TYPE.INFO,
          message: 'Damage ' + this.damageId + ' saved correctly',
          timeout: 5000,
          selfClose: null,
        });
        const queryParams: Params = {damage: this.damageId, n: false};
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        this.refreshData(close, false);
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'Some error occured during the damage save action',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  onSaveAndActionClick(action: string) {
    this.spinner.show();
    lastValueFrom(
      this.generalDataService.postDataDetail(
        this.apiParent + '/' + this.apiUrl,
        this.detail,
        action
      )
    )
      .then((res) => {
        if (action == 'P') {
          this.damageId = res['oContent']?.DAMAGE_ID ?? res['sResponseMessage'];
          this.isNew = 'false';
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Damage ' + this.damageId + ' saved correctly',
            timeout: 5000,
            selfClose: null,
          });

          const dialog = this.dialog.open(DraftDialogComponent, {
            width: '65vw',
            data: {
              title: 'Notify Warehouse',
              damageIds: this.detail?.DAMAGE_ID,
              typology: 'default',
              notificationId: 'default',
              url: this.apiParent + '/' + this.apiUrl,
              groups: res['oContent'],
              closeButtonLabel: 'Close',
              confirmButtonLabel: 'Send',
            },
            backdropClass: 'backdropBackground',
          });

          dialog.afterClosed().subscribe((res) => {
            if (!!res && res) {
              ////(res);
            }
            this.refreshData(false, false);
          });
        } else if (action == 'S') {
          this.damageId = res['oContent']?.DAMAGE_ID ?? res['sResponseMessage'];
          if (res['iResponseCode'] == 200) {
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: res['sResponseMessage'],
              timeout: 5000,
              selfClose: null,
            });
          } else {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: res['sResponseMessage'],
              timeout: 5000,
              selfClose: null,
            });
          }
          this.refreshData(false, false);
        }
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'Unable to save current damage',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  onCancelClick() {
    if (
      (this.detail == undefined && this.detailBck == undefined) ||
      (this.detail != undefined &&
        this.detailBck != undefined &&
        Object.keys(this.detail).filter(
          (key) =>
            key != 'L_PROBLEMS' &&
            key != 'L_EFFICIENCY_PROBLEMS' &&
            this.detail![key] != this.detailBck![key]
        ).length < 1)
    ) {
      this._location.back();
      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Unsaved changes',
        content: `If you leave this page the creations or changes you have made so far will not be saved. How would you like to proceed?`,
        cancelButtonLabel: 'Stay',
        cancelButtonColor: 'basic',
        confirmButtonLabel: 'Leave!',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && res == true) {
        this._location.back();
      }
    });
  }

  private catchAllSelectedOptions(layout: Layout) {
    layout.sectionLayouts.forEach((sectionLayout: SectionLayout) => {
      sectionLayout.dataset.forEach((dataset: DataSet) => {
        dataset.datas?.map((data: Data) => {
          if (data.valuesFrom.length > 0) {
            this.spinner.show();
            this.generalDataService
              .getSelectionOption(data.valuesFrom)
              .then((res) => {
                data.optionValues = res;
                const layoutsString = localStorage.getItem('layout');
                if (!!layoutsString) {
                  let layouts = JSON.parse(layoutsString);
                  layouts[this.pageId] = {
                    instance: layout,
                    instant: new Date(),
                  };
                  localStorage.setItem('layout', JSON.stringify(layouts));
                } else {
                  let layouts = {};
                  layouts[this.pageId] = {
                    instance: layout,
                    instant: new Date(),
                  };
                  localStorage.setItem('layout', JSON.stringify(layouts));
                }
              })
              .finally(() => {
                this.spinner.hide();
              });
          }
        });
      });
    });
  }

  goBack() {
    this.spinner.show();
    this._location.back();
    this.spinner.hide();
  }

  onChangeStatusClick(status: string) {
    lastValueFrom(
      this.generalDataService.changeStatus(
        this.apiParent + '/' + this.apiUrl,
        this.detail?.DAMAGE_ID ?? '',
        status
      )
    )
      .then((res) => {
        if (status != 'D') this.damageId = res['oContent']?.DAMAGE_ID ?? '';
        this.isNew = 'false';

        if (status == 'W') {
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Damage ' + this.damageId + ' cancelled correctly',
            timeout: 5000,
            selfClose: null,
          });
        } else if (status == 'D') {
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Damage ' + this.damageId + ' deleted correctly',
            timeout: 8000,
            selfClose: null,
          });
        } else {
          this.alertService.add({
            type: ALERT_TYPE.INFO,
            message: 'Damage ' + this.damageId + ' saved correctly',
            timeout: 5000,
            selfClose: null,
          });
        }
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'Some error occured during the damage save action',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.spinner.hide();
        if (status != 'D') this.refreshData(false, false);
        else this._location.back();
      });
  }

  refreshDocumentData() {
    let params = new HttpParams().set('id', this.damageId);
    this.generalDataService
      .getDocumentsById(this.apiParent + '/' + this.apiUrl + '/docs', params)
      .subscribe((res) => {
        this.attachments = res['oContent'];
      });
  }

  deleteDoc(documentsId: string, documentId: string) {
    lastValueFrom(
      this.generalDataService.deleteDocument(
        this.apiParent + '/' + this.apiUrl,
        documentsId,
        documentId
      )
    )
      .then((res) => {
        this.alertService.add({
          type: ALERT_TYPE.SUCCESS,
          message: 'Delete document success',
          timeout: 5000,
          selfClose: null,
        });
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Delete document was wrong',
          timeout: 5000,
          selfClose: null,
        });
      })
      .finally(() => {
        this.refreshDocumentData();
      });
  }

  downloadDoc(
    documentsId: string,
    documentId: string,
    fileType: string,
    fileName: string
  ) {
    lastValueFrom(
      this.generalDataService.downloadDocument(
        this.apiParent + '/' + this.apiUrl + '/docs/download',
        documentId
      )
    )
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((err) => {
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Something went wrong during download ' + fileName,
          timeout: 5000,
          selfClose: null,
        });
      });
  }

  uploadDoc() {
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });
    dialogEvent.afterClosed().subscribe((dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        //this.fileToSend = dndResponse.files;
        this.spinner.show();
        lastValueFrom(
          this.generalDataService.uploadDoc(
            this.apiParent + '/' + this.apiUrl + '/docs',
            this.damageId,
            dndResponse.files[0]
          )
        )
          .then((res) => {
            this.alertService.add({
              type: ALERT_TYPE.SUCCESS,
              message: 'File uploaded',
              timeout: 5000,
              selfClose: null,
            });
          })
          .catch((err) => {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message:
                'Something went wrong, please try again or contact webmaster',
              timeout: 5000,
              selfClose: null,
            });
          })
          .finally(() => {
            this.spinner.hide();
            this.refreshDocumentData();
          });
      }
    });
  }

  historySize() {
    return this.navigation.size()
  }
}
