<div class="filter-container" *ngIf="!filterModel.isHide">
  <label class="fs-6 fw-bold filter-title">{{filterModel.columnLabel}}</label>
  <div class="filter-content">

    <div [ngSwitch]="filterModel.filterType" class="filter-type-container">
      <!-- DATE FILTER -->
      <div *ngSwitchCase="filterType.DATE">
        <custom-datepicker *ngIf="filterModel.filterSelectedOption.haveFirstParam" [(value)]="filterModel.firstValue" (valueChange)="onFirstFilterChange($event)">
        </custom-datepicker>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam">AND</label>
        <custom-datepicker [(value)]="filterModel.secondValue" *ngIf="filterModel.filterSelectedOption.haveSecondParam">
        </custom-datepicker>
      </div>

      <!-- SELECTION FILTER -->
      <div *ngSwitchCase="filterType.SELECTION">
        <custom-select [values]="filterModel.filterValues!" *ngIf="filterModel.filterSelectedOption.haveFirstParam" [defaultValue]="filterModel.firstValue"
          (defaultValueChange)="onFirstFilterChange($event)"></custom-select>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam" class="fs-7 text-center">AND</label>
        <custom-select [values]="filterModel.filterValues!" [defaultValue]="filterModel.secondValue"
          (defaultValueChange)="onSecondFilterChange($event)" *ngIf="filterModel.filterSelectedOption.haveSecondParam">
        </custom-select>
      </div>

      <!-- MULTI SELECTION FILTER -->
      <div *ngSwitchCase="filterType.MULTI_SELECTION">
        <custom-multiselect [options]="filterModel.filterValues!" *ngIf="filterModel.filterSelectedOption.haveFirstParam" [(ngModel)]="filterModel.firstValue"
          (ngModelChange)="onFirstFilterChange($event)">
        </custom-multiselect>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam" class="fs-7 text-center">AND</label>
        <custom-multiselect [options]="filterModel.filterValues!" [(ngModel)]="filterModel.secondValue"
          (ngModelChange)="onSecondFilterChange($event)" *ngIf="filterModel.filterSelectedOption.haveSecondParam">
        </custom-multiselect>
      </div>
      <!-- TEXT e NUMBER FILTER -->
      <div *ngSwitchDefault>
        <custom-text-field [appearance]="'fill'" *ngIf="filterModel.filterSelectedOption.haveFirstParam" [placeholder]="'First value'" [(value)]="filterModel.firstValue"
          [isNumber]="filterModel.filterType == filterType.NUMBER"></custom-text-field>
        <label *ngIf="filterModel.filterSelectedOption.haveSecondParam">AND</label>
        <custom-text-field [appearance]="'fill'" [placeholder]="'Second value'"
          *ngIf="filterModel.filterSelectedOption.haveSecondParam" [value]="filterModel.firstValue"></custom-text-field>
      </div>

    </div>
    <custom-select [values]="filterModel.lFilterOptions" [defaultValue]="filterModel.filterSelectedOption.id"
                   [preventIdChange]="false" (defaultValueChange)="onChange($event)" [extraClass]="'filterOption'"></custom-select>

  </div>

</div>
