import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFilterComponent } from '../custom-filter/custom-filter.component';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { FilterInterface } from '../custom-filter/filter.interface';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogData,
  FilterSettingsComponent,
} from '../filter-settings/filter-settings.component';
import { TableControllerService } from '../../../../services/table-controller.service';
import { FilterControllerService } from '../../../../services/filter-controller.service';
import {Required} from "../../../../utils/requiredInputs";
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'filter-panel[pageReference]',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class FilterPanelComponent {
  @Input()
  filterModels: FilterInterface[] = [];

  @Output()
  onFilterClick: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  filterController!: FilterControllerService;

  @Input()
  pageReference!: string;

  @Output()
  onResetFilterClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog, private authService: AuthService) {}

  openDndDialog(event: MouseEvent) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(FilterSettingsComponent, {
      data: {
        dataTitle: 'Filter Settings',
        viewList: this.filterController.filterModels
          .filter((fil) => !fil.isHide)
          .map((fil) => {
            return {
              id: fil.filterId,
              label: fil.columnLabel,
            };
          }),
        hideList: this.filterController.filterModels
          .filter((fil) => fil.isHide)
          .map((fil) => {
            return {
              id: fil.filterId,
              label: fil.columnLabel,
            };
          }),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      ////('The dialog was closed');
      ////(result);
      if (!!result.hideList) {
        const hideList = result.hideList.map((fil) => fil.id);
        this.filterController.filterModels.map((filter) => {
          filter.isHide = hideList.some((elem) => elem === filter.filterId);
          return filter;
        });
        const filterSettings = localStorage.getItem('filter');
        if(!!filterSettings){
          const filters = JSON.parse(filterSettings);
          filters[this.pageReference] = {
            instance: this.filterController.filterModels,
            instant: new Date(),
          };
          localStorage.setItem('filter', JSON.stringify(filters));
          this.authService.setFilter(JSON.stringify(filters)).subscribe(res => {});
        }else{
          const filters = {};
          filters[this.pageReference] = {
            instance: this.filterController.filterModels,
            instant: new Date(),
          };
          localStorage.setItem('filter', JSON.stringify(filters));

          this.authService.setFilter(JSON.stringify(filters)).subscribe(res => {});
        }
      }
    });
  }

  applyFilter() {
    //(this.filterController);
    this.onFilterClick.emit(true);
  }

  clearFilter() {
    //(this.filterController);
    this.filterController.filterModels.forEach((model) => {
      model.firstValue = '';
      model.filterSelectedOption = model.lFilterOptions[0];
    });

  }


  resetFilter(){
    const filterSettings = localStorage.getItem('filter');
    if(!!filterSettings){
      let filters = JSON.parse(filterSettings);
      filters[this.pageReference] = {};
      delete filters[this.pageReference]
      //this.omit(filters, [this.pageReference])
      localStorage.setItem('filter', JSON.stringify(filters));
      this.authService.setFilter(JSON.stringify(filters)).subscribe(res => {
        this.onResetFilterClick.emit(true)
      });

    }
  }
}
