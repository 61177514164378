<h2 mat-dialog-title>{{ data['title'] }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="row align-items-center justify-content-between attachment-title">
    <custom-alert></custom-alert>
    <div class="col-1">
      <label class="fs-6 fw-bold"></label>
    </div>
    <div class="col-2" *ngIf="loading > 0">
      <mat-spinner style="margin:0 auto;" color="primary" diameter="50" ></mat-spinner>
    </div>
    <div class="col-7"></div>
  </div>
 <!-- <div class="container" *ngIf="attachments.length > 0 else noData"> -->
    <div class="container">
    <!-- heading text -->
    <ul class="image-gallery">
      <li *ngFor="let picture of attachments">
        <img [src]="picture.url" [alt]="picture.name"   />
        <div class="overlay">
          <span>{{picture.name}}</span>
          <div class="row-btn-container">
            <custom-btn [iconButton]="true" [iconClasses]="'delete'" [fontSize]="'16px'"
                        (click)="deletePicture(picture.id)" matTooltip="Delete" *ngIf="!isViewOnly">
            </custom-btn>
            <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'" [fontSize]="'16px'"
                        (click)="download(picture.url, picture.name)" matTooltip="Download">
            </custom-btn>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <ng-template #noData>
    <div class="container">
    <h2 class="fs-1 fw-bold text-center align-items-center justify-content-center m-5">{{ data['content'].length == attachments.length ? 'No picture found' : 'Getting pictures...' }}</h2>
    </div>
  </ng-template>





  <!--<div class="images-area">
    <div class="image-container" *ngFor="let picture of pictures">
      <img class="image-content" [src]="picture.pictureUrl" [alt]="picture.pictureName">
      <div class="row-btn-container">
        <custom-btn [iconButton]="true" [iconClasses]="'delete'" [fontSize]="'16px'"
                    (click)="deleteDoc(picture.pictureId)">
        </custom-btn>
        <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'" [fontSize]="'16px'"
                    (click)="downloadDoc(picture.pictureId, picture.pictureName)">
        </custom-btn>
      </div>
    </div>
  </div>-->
</mat-dialog-content>

<div class="buttons-container" mat-dialog-actions >
  <input #imageInput
         type="file"
         accept="image/*"
         (change)="processFile(imageInput)" style="display: none">
  <custom-icon-btn color="accent" [iconClasses]="'add_a_photo'" (click)="imageInput.click()"></custom-icon-btn>

  <custom-icon-btn color="accent" [iconClasses]="'cloud_upload'" (click)="uploadDoc()"></custom-icon-btn>

  <custom-icon-btn [mat-dialog-close]="{send: false}" color="accent" [iconClasses]="'save'"></custom-icon-btn>
</div>
