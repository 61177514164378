import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'custom-table-paginator',
  templateUrl: './custom-table-paginator.component.html',
  styleUrls: ['./custom-table-paginator.component.scss'],
})
export class CustomTablePaginatorComponent implements OnInit {
  @Input()
  paginatorLoaderTitle!: string;

  @Input()
  paginatorLoader: number = 50;

  @Input()
  paginatorLoaderList: Array<number> = [50, 100, 250];

  @Input()
  page!: number;

  @Input()
  numElementsLoaded!: number;

  @Input()
  numElementsSize!: number;

  isAllLoaded: boolean = false;

  @Output()
  onPaginatorChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {}


  onPageChange(evt: any) {
    this.page = Math.floor(this.numElementsLoaded / this.paginatorLoader);
    this.isAllLoaded = this.numElementsSize - this.numElementsLoaded <= 0;
    if (!this.isAllLoaded) {
      this.onPaginatorChange.emit({
        pageIndex: this.page,
        previousPageIndex: this.page - 1,
        loader: this.paginatorLoader,
        size: this.numElementsSize,
      });
    }
  }

  onLoadClick(event: MouseEvent) {

    event.preventDefault()
    this.isAllLoaded = this.numElementsSize - this.numElementsLoaded <= 0;
    if (!this.isAllLoaded) {
      this.page = this.page + 1;
      this.onPaginatorChange.emit({
        pageIndex: this.page,
        previousPageIndex: this.page - 1,
        loader: this.paginatorLoader,
        size: this.numElementsSize,
      });
    }
  }
}

export interface PageEventType {
  pageIndex: number;
  previousPageIndex: number;
  loader: number;
  size: number;
}
