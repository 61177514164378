import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {from, Observable, throwError} from 'rxjs';
import {catchError, switchMap} from "rxjs/operators";
import {MsalService} from '@azure/msal-angular';
import {environment} from 'src/environments/environment';

const NO_REDIRECT_URI = ['damages/detail/docs', 'assets/version.json', 'from-otm'];

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private msalService: MsalService, private _router: Router) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    if (
      !req.url.includes('prod-157') &&
      !req.url?.includes('login.microsoftonline.com') &&
      !req.url.includes('assets/') &&
      !((req.url.includes('api/v1/translation') && req.method.toUpperCase() == 'GET'))
    ) {
      return this.getTokenAndHandleRequest(req, next).pipe(
        catchError((error: HttpErrorResponse) => {
          if ((req.url.includes('damages/detail/docs')) || (req.url.includes('attachment')) || req.url.includes('/pictures/info') || (req.method.toUpperCase() != 'GET')) {
            return throwError(() => error);
          } else {
            if (error.status >= 500 || error.status == 404) {
              return throwError(() => error);
            } else {
              return throwError(() => error);
            }
          }
        })
      );
    } else {
      return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
        if (
          (error.status == 401 || error.status == 400) &&
          (req.url.includes('ddt/api/') || req.url.includes('/auth/api/v2') && !(req.url.includes('damages/detail/docs') || (req.url.includes('attachment')) ) && !req.url.includes('/pictures/info') && !req.url.includes('assets/version.json'))
        ) {
          //return this.handleTokenRefresh(req, next);
          return throwError(() => error);
        }  else if (!(req.url.includes('damages/detail/docs') || (req.url.includes('attachment')) ) && !req.url.includes('/pictures/info') && error.status != 404) {
          this._router.navigateByUrl('/400?message=' + error.message);
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      }));
    }
  }

  private getTokenAndHandleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.msalService.acquireTokenSilent({
      scopes: environment.clientScope.split(' '),
    }).pipe(
      switchMap(token => {
        const headers = new HttpHeaders()
          .set('Authorization', `Bearer ${token.idToken}`)
          .set('Access-Control-Allow-Origin', '*');
        const reqAuthorized = req.clone({headers});
        return next.handle(reqAuthorized).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status == 400 || error.status == 401) {
              return this.authErrorHandler(error, req, next);
            }
            return throwError(() => error);
          })
        );
      })
    );
  }

  private handleTokenRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(
      this.msalService.acquireTokenRedirect({
        scopes: environment.clientScope.split(' '),
      })
    ).pipe(
      switchMap(() => {
        return this.msalService.acquireTokenSilent({
          scopes: environment.clientScope.split(' '),
        });
      }),
      switchMap(token => {
        const headers = new HttpHeaders()
          .set('Authorization', `Bearer ${token.idToken}`)
          .set('Access-Control-Allow-Origin', '*');
        const reqAuthorized = req.clone({headers});
        return next.handle(reqAuthorized).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status == 400) {
              this._router.navigateByUrl('/400?message=' + err.message);
            } else if (err.status == 401) {
              this._router.navigateByUrl('/400?message=' + err.message);
            }
            return throwError(() => err);
          })
        );
      })
    );
  }

  private authErrorHandler(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    //this._router.navigateByUrl('/400?message=' + err.message);
    if (err.status == 400) {
      this._router.navigateByUrl('/400?message=' + err.message);
    } else if (err.status == 401) {
      return this.handleTokenRefresh(req, next);
    }
    return throwError(() => err);
  }
}
