import { NgModule } from '@angular/core';
import { CustomMultiselectComponent } from './custom-multiselect.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiSelectSearchFilter } from './search-filter.pipe';
import { AutofocusDirective } from './autofocus.directive';
import { OffClickDirective } from './off-click.directive';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    CustomMultiselectComponent,
    MultiSelectSearchFilter,
    AutofocusDirective,
    OffClickDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  exports: [
    CustomMultiselectComponent,
    MultiSelectSearchFilter,
  ],
})
export class CustomMultiselectModule { }
