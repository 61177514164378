import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, merge, of, Subscription, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[longPress]'
})
export class LongPressDirective implements OnDestroy {
  private eventSubscribe: Subscription;
  threshold = 1000;

  @Output()
  longPress = new EventEmitter();

  longClicked = false;

  position = {
    pageX: 0,
    pageY: 0
  }

  constructor(private elementRef: ElementRef) {
    const mousedown = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousedown'
    ).pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map((event) => {
        this.position.pageX = event.pageX
        this.position.pageY = event.pageY
        return true
      }) // turn on threshold counter
    );
    const touchstart = fromEvent(elementRef.nativeElement, 'touchstart').pipe(
      map((event:any) => {
        if(event && event['touches']){
          this.position.pageX = (event['touches'])[0].pageX
          this.position.pageY = (event['touches'])[0].pageY
        }
        return true;
      })
    );
    const touchEnd = fromEvent(elementRef.nativeElement, 'touchend').pipe(
      map((event:any) => {
        if(this.longClicked){
          event.stopPropagation();
        }
        this.longClicked = false;
        this.position = {
          pageX: 0,
          pageY: 0
        }

        return false;
      })
    );
    const mouseup = fromEvent<MouseEvent>(window, 'mouseup').pipe(
      filter((event) => event.button == 0), // Only allow left button (Primary button)
      map((event:any) => {
        if(this.longClicked){
          event.stopPropagation();
        }
        this.longClicked = false;
        this.position = {
          pageX: 0,
          pageY: 0
        }
        return false;
      }) // reset threshold counter
    );
    this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
      .pipe(
        switchMap((state) => (state ? timer(this.threshold, 100) : of(null))),
        filter((value) => !!value)
      )
      .subscribe((re) => {
        if(!this.longClicked){
          this.longClicked = true;
          this.longPress.emit(this.position)
        }
      });
  }

  ngOnDestroy(): void {
    if (this.eventSubscribe) {
      this.eventSubscribe.unsubscribe();
      this.longClicked = false;
    }
  }
}
