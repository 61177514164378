import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FILTER_TYPE, FilterInterface } from './filter.interface';

@Component({
  selector: 'custom-filter[filterModel]',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss'],
})
export class CustomFilterComponent implements OnInit {
  filterType = FILTER_TYPE;

  @Input()
  filterModel!: FilterInterface;

  @Output()
  filterModelChange: EventEmitter<FilterInterface> =
    new EventEmitter<FilterInterface>();

  constructor() {}

  ngOnInit(): void {}

  onChange(event: any): void {
    //(this.filterModel);
    this.filterModel.filterSelectedOption =
      this.filterModel.lFilterOptions.filter((f) => f.id == event)[0];
    this.filterModelChange.emit(this.filterModel);
  }

  onFirstFilterChange(event: any): void {
    this.filterModel.firstValue = event;
    this.filterModelChange.emit(this.filterModel);
  }

  onSecondFilterChange(event: any): void {
    this.filterModel.secondValue = event;
    this.filterModelChange.emit(this.filterModel);
  }

  onClearClick() {
    ////(this.filterModel);
  }
}
