import {Component, OnInit, ViewChild} from '@angular/core';
import {Layout} from "../../../models/layout";
import {ActivatedRoute} from "@angular/router";
import {LayoutServiceService} from "../../../services/layout.service";
import {SpinnerService} from "../../../services/spinner.service";
import {AlertService} from "../../shared/alert/alert.service";
import {finalize, map, take} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ALERT_TYPE} from "../../shared/alert/alert.enumerate";
import {environment} from "../../../../environments/environment";
import {forkJoin, of} from "rxjs";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-scorecard-remarks',
  templateUrl: './scorecard-remarks.component.html',
  styleUrls: ['./scorecard-remarks.component.scss']
})
export class ScorecardRemarksComponent implements OnInit {
  pageId: string = "";
  layout: Layout | undefined;
  scorecardId: string = "";
  value: string = "";
  titlePage: string = "";
  private apiParent: string = "";
  private apiUrl: string = 'scorecards/detail';

  @ViewChild('remarksInput') remarksInput!: NgForm;


  constructor(private route: ActivatedRoute,
              public layoutService: LayoutServiceService,
              private spinner: SpinnerService,
              private http: HttpClient,
              public alertService: AlertService,
              private location: Location,
              public dialog: MatDialog) {
  }

  /**
   * Initializes the component by setting up observables to retrieve query parameters
   * and parent URL from the route. It uses `forkJoin` to wait for both observables
   * to complete before executing the `onDataReady` method. The query parameters
   * are extracted to set `pageId` and `scorecardId`, while the parent URL is used
   * to set `apiParent`.
   */
  ngOnInit(): void {
    // Observable per i queryParams, che si completa dopo il primo valore
    const queryParams$ = this.route.queryParams.pipe(
      take(1), // Prende il primo valore e completa
      map((params) => {
        this.pageId = params['pageId'];
        this.scorecardId = params['scorecardId'];
        this.titlePage = params['t'];
        this.titlePage = (this.titlePage === 'Warehouse ') ? 'Warehouse ' : 'Primary ';
      })
    );

    // Observable per la URL del genitore, che si completa dopo il primo valore
    const parentUrl$ = this.route.parent?.url
      ? this.route.parent.url.pipe(
        take(1),
        map((segments) => {
          const finalParentUrl = segments.join('/');
          this.apiParent = finalParentUrl;
          return finalParentUrl; // Tipo: string
        })
      )
      : of('');

    // Usa forkJoin per attendere che entrambi gli Observable completino
    forkJoin([queryParams$, parentUrl$]).subscribe(([queryParams, parentUrl]) => {
      console.log('Query Params:', queryParams);
      console.log('Parent URL:', parentUrl);

      // Esegui la funzione una volta che entrambi sono completati
      this.onDataReady();
    });
  }

  /**
   * Executes the necessary actions once the data is ready by loading the page layout
   * and the remarks associated with the scorecard.
   */
  private onDataReady(): void {
    this.loadPageLayout();
    this.loadRemarks();
  }

  /**
   * Loads the page layout from local storage or fetches it from the server if not available or outdated.
   *
   * This method checks if the layout for the current page ID is stored in local storage and is less than 6 hours old.
   * If so, it assigns the stored layout to the component's layout property. Otherwise, it fetches the layout from the server,
   * updates the local storage with the new layout, and assigns it to the component's layout property.
   *
   * The method uses a spinner to indicate loading while fetching the layout from the server.
   */
  private loadPageLayout() {

    const layoutsString = localStorage.getItem('layout');
    if (!!layoutsString) {
      const layouts = JSON.parse(layoutsString);
      if (!!layouts && !!layouts[this.pageId]) {
        const pageLayout = layouts[this.pageId];
        if (
          Math.abs(
            (new Date(pageLayout['instant']).getTime() -
              new Date().getTime()) /
            60000
          ) < 360
        ) {
          this.layout = pageLayout['instance'];
        } else {
          this.spinner.show();
          this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
            this.layout = res?.layouts[0] ?? undefined;
            const layoutsString = localStorage.getItem('layout');
            if (!!layoutsString) {
              let layouts = JSON.parse(layoutsString);
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            } else {
              let layouts = {};
              layouts[this.pageId] = {
                instance: res?.layouts[0],
                instant: new Date(),
              };
              localStorage.setItem('layout', JSON.stringify(layouts));
            }
            this.spinner.hide();
          });
        }
      } else {
        this.spinner.show();
        this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[this.pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }
          this.spinner.hide();

        });
      }
    } else {
      this.spinner.show();
      this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
        this.layout = res?.layouts[0] ?? undefined;
        const layoutsString = localStorage.getItem('layout');
        if (!!layoutsString) {
          let layouts = JSON.parse(layoutsString);
          layouts[this.pageId] = {
            instance: res?.layouts[0],
            instant: new Date(),
          };
          localStorage.setItem('layout', JSON.stringify(layouts));
        } else {
          let layouts = {};
          layouts[this.pageId] = {
            instance: res?.layouts[0],
            instant: new Date(),
          };
          localStorage.setItem('layout', JSON.stringify(layouts));
        }
        this.spinner.hide();
      });
    }
  }

  /**
   * Handles the cancel action for the form. If the form is not dirty, navigates back to the previous location.
   * If the form has unsaved changes, opens a confirmation dialog to warn the user about unsaved changes.
   * If the user confirms, navigates back to the previous location.
   */
  onCancelClick(): void {
    if(!this.remarksInput.dirty){
      this.location.back();
      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Unsaved changes',
        content: `If you leave this page the changes you have made so far will not be saved. How would you like to proceed?`,
        cancelButtonLabel: 'Stay',
        cancelButtonColor: 'basic',
        confirmButtonLabel: 'Leave!',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((res: boolean):void => {
      if (res) {
        this.location.back();
      }
    });
  }

  /**
   * Handles the save action for remarks by sending a PATCH request to the server.
   * Constructs the URL using the environment's API URL, parent URL, and scorecard ID.
   * If the server responds with a success code, displays a success alert.
   * Otherwise, displays a warning alert indicating failure to save remarks.
   * Logs any errors encountered during the request and displays a warning alert.
   */
  onSaveClick(): void {
    const url = `${environment.apiUrl}${this.apiParent}/${this.apiUrl}/remarks/${this.scorecardId}`;
    this.spinner.show();
    this.http.patch(url, this.value).subscribe({
      next: (response: any) => {
        this.spinner.hide();
        if (response.iResponseCode === 200) {
          this.remarksInput.control.markAsPristine();
          this.alertService.add({
            type: ALERT_TYPE.SUCCESS,
            message: 'Remarks saved successfully.',
            timeout: 5000,
            selfClose: null,
          });
        } else {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message: 'Unable to save remarks.',
            timeout: 5000,
            selfClose: null,
          });
        }

      },
      error: (error) => {
        this.spinner.hide();
        console.error(error);
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Unable to save remarks.',
          timeout: 5000,
          selfClose: null,
        });
      }
    })
  }

  /**
   * Loads remarks for the current scorecard by making an HTTP GET request.
   * Displays a spinner while the request is in progress and hides it upon completion.
   * Updates the component's value with the response content if successful.
   * Shows a warning alert if the request fails or returns a non-200 response code.
   */
  private loadRemarks(): void {
    this.spinner.show();
    const url = `${environment.apiUrl}${this.apiParent}/${this.apiUrl}/remarks/${this.scorecardId}`;
    this.http.get(url).pipe(finalize(() => {
      this.spinner.hide();
    })).subscribe({
      next: (response: any) => {
        if (response.iResponseCode === 200) {
          this.value = response.oContent;
        } else {
          this.alertService.add({
            type: ALERT_TYPE.WARNING,
            message: 'Unable to load remarks of remarks',
            timeout: 5000,
            selfClose: null,
          });
        }

      },
      error: (error) => {
        console.error(error);
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Unable to load remarks of remarks.',
          timeout: 5000,
          selfClose: null,
        });
      }
    })
  }
}
