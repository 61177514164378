<a
  *ngIf="!!!item.hidden"
  mat-list-item
  [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
  (click)="onItemSelected(item)"
  class="menu-list-item"
>
  <mat-icon
    class="routeIcon"
    [ngClass]="{ 'elx-blue': item.children && item.children.length }"
    >{{ item.iconName }}
  </mat-icon>
  <span [ngClass]="{ 'elx-blue': item.children && item.children.length }">{{
    item.displayName
  }}</span>
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 3"
  >
  </app-menu-list-item>
</div>
