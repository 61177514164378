import {Injectable} from "@angular/core";
import {IMultiSelectOption} from "../../custom-multiselect/types";

export interface FilterOption{
  id: string;
  name: string;
  haveSecondParam: boolean;
}

export enum FILTER_TYPE {
  TEXT = 'FLT_STRING',
  NUMBER = 'FLT_NUMBER',
  DATE = 'FLT_DATE',
  SELECTION = 'FLT_SELECTION',
  MULTI_SELECTION = 'FLT_MULTI_SELECTION'
}

export function getKeyByValue(value: string) {
  const indexOfS = Object.values(FILTER_TYPE).indexOf(value as unknown as FILTER_TYPE);

  const key = Object.keys(FILTER_TYPE)[indexOfS];

  return key;
}

export interface FilterValue{
  value: any;
  label: String;
}

export interface FilterInterface{

  lFilterOptions: IMultiSelectOption[];
  filterId: string;
  filterType: FILTER_TYPE;
  columnLabel: string;
  columnName: string,
  selectedOption: string;
  filterSelectedOption: IMultiSelectOption;
  filterValues: IMultiSelectOption[];
  firstValue: any;
  secondValue: any;
  isHide: Boolean;

}
