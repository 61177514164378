<div class="d-flex flex-column justify-content-between h-100">
<div mat-dialog-content *ngIf="!!otmDetail" class="m-1">
  <h2 class="fs-1 fw-bold text-center align-items-center justify-content-center m-5">OTM Data</h2>
  <div class="row mt-3 pl-1">
    <div class="col-6">
      <div class="row">
        <div class="col mr-1 p-2">
          <div class="row mt-2 align-items-center">
            <div class="col-12 justify-content-center"><label
              class="fs-8 fw-bold ">{{'Pickup'}}</label></div>
          </div>
          <div class="row mt-2 ">
            <div class="col-12 justify-content-center">
              <custom-select [values]="otmDetail.pickUpStops" [defaultValue]="otmDetail.selectedPickUp.id" (defaultValueChange)="stopChange($event, 'pickup')"
                             [extraClass]="'w-100 m-0 mw-100'" [preventIdChange]="false"></custom-select>
            </div>
          </div>
        </div>
        <div class="col-auto"></div>
        <div class="col ml-1 p-2">
          <div class="row mt-2 align-items-center">
            <div class="col-12 justify-content-center"><label
              class="fs-8 fw-bold ">{{'Drop'}}</label></div>
          </div>
          <div class="row mt-2 ">
            <div class="col-12 justify-content-center">
              <custom-select [values]="otmDetail.dropDownStops" [defaultValue]="otmDetail.selectedDropdown.id" [preventIdChange]="false"
                             (defaultValueChange)="stopChange($event, 'drop')"
                             [extraClass]="'w-100 m-0 mw-100'"></custom-select>
            </div>
          </div>

        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="row mt-2 mr-1 align-items-center">
            <div class="col-6"><label class="fs-8 fw-bold ">{{'Pickup appointment'}}</label></div>
            <div class="col-6 align-items-end"><label
              class="fs-6 "> {{otmDetail.selectedPickUp.data.shipmentStopBeanData.appointmentPickup.date}}</label>
            </div>
          </div>
          <div class="row mt-2 ml-1 align-items-center">
            <div class="col-6 "><label class="fs-8 fw-bold ">{{'Drop appointment'}}</label></div>
            <div class="col-6 align-items-end"><label
              class="fs-6 "> {{otmDetail.selectedDropdown.data.shipmentStopBeanData.appointmentDelivery.date}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 mt-2">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>
              <span>Delivery</span>
            </th>
            <th>
              <span>PNC</span>
            </th>
            <th>
              <span>Quantity</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <ng-container
            *ngFor="let shipmentStopD of otmDetail.selectedDropdown.data.shipmentStopBeanData.shipmentStopD; let i=index">
            <ng-container *ngFor="let sShipUnit of shipmentStopD.shipmentStopDBeanData.sShipUnit; let i=index">
              <tr *ngFor="let sShipUnitLine of sShipUnit.sShipUnitBeanData.sShipUnitLine; let i=index">
                <td>
                  <span>{{sShipUnitLine.sShipUnitLineBeanData.orderBaseGid.replace("ELX.", "")}}</span>
                </td>
                <td>
                  <span>{{sShipUnitLine.sShipUnitLineBeanData.packagedItemGid.replace("ELX.", "").replace("-OTD", "")}}</span>
                </td>
                <td>
                  <span>{{sShipUnitLine.sShipUnitLineBeanData.packagingUnitCount}}</span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>

    </div>
  </div>
  <div class="row my-5 ">
    <div class="detail-container">
      <div class="detail-content mt-2">
        <label class="fs-8 fw-bold">{{'Carrier'}}&nbsp;&nbsp;</label>
        <label class="fs-6"> {{otmDetail.carrier}}</label>
      </div>
      <div class="detail-content mt-2">
        <label class="fs-8 fw-bold">{{'Plate Number'}}&nbsp;&nbsp;</label>
        <label class="fs-6 "> {{otmDetail.plateNumber}}</label>
      </div>
      <div class="detail-content mt-2">
        <label class="fs-8 fw-bold">{{'Container'}}&nbsp;&nbsp;</label>
        <label class="fs-6 "> {{otmDetail.container}}</label>
      </div>
      <div class="detail-content mt-2">
        <label class="fs-8 fw-bold">{{'Wagon'}}&nbsp;&nbsp;</label>
        <label class="fs-6"> {{otmDetail.wagon}}</label>
      </div>
      <div class="detail-content mt-2">
        <label class="fs-8 fw-bold">{{'OTM Shipment Num.'}}&nbsp;&nbsp;</label>
        <label class="fs-6"> {{otmDetail.shipmentOtm}}</label>
      </div>
      <div class="detail-content mt-2">
        <label class="fs-8 fw-bold">{{'Load Number'}}&nbsp;&nbsp;</label>
        <label class="fs-6"> {{otmDetail.loadNum}}</label>
      </div>
    </div>
    <!-- INFO -->
  </div>

</div>

  <div mat-dialog-content *ngIf="!(!!otmDetail)" class="m-1">
    <h2 class="fs-1 fw-bold text-center align-items-center justify-content-center m-5">{{ statusTitle }}</h2>
  </div>


  <div mat-dialog-actions class="d-flex justify-content-between">
    <div>
      <custom-btn mat-dialog-close cdkFocusInitial >Cancel</custom-btn>
    </div>
    <custom-btn mat-button (click)="onNextClick()" *ngIf="!!otmDetail"  color="primary" class="float-right" align="end">Next
    </custom-btn>
  </div>

</div>

