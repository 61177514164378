<!--DIV PRINCIPALE-->
<div class="document-container" *ngFor="let doc of lDoc">
    <div class="box"> <!--div righe-->
        <label class="document-label">{{doc.FILE_NAME}} </label>

        <div class="button-container">

            <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'" [fontSize]="'16px'"  matTooltip="{{ 'LABEL_DOWNLOAD' | translate }}" (click)="download(doc.FILE_NAME,doc.DOCUMENT_ID)"> </custom-btn>
            <custom-btn [iconButton]="true" [iconClasses]="'delete'" [fontSize]="'16px'"  matTooltip="{{ 'LABEL_DELETE' | translate }}" (click)="del(doc.DOCUMENT_ID, doc.DOCUMENTS_ID)"> </custom-btn>
        </div>

    </div>

</div>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="{}" cdkFocusInitial>{{data['cancelButtonLabel']}}</button>
  </mat-dialog-actions>
