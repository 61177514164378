<button mat-icon-button class="elx-blue close" aria-label="Close" [mat-dialog-close]="false">
  <mat-icon class="user-icon">close</mat-icon>
</button>
<br>
<div id="menuPosition" class="menu-position">
  <ng-container *ngFor="let action of data.actions">
    <div *ngIf="action.id == 'ACT_EDIT'" class="menu-element"><custom-btn  color="accent" [mat-dialog-close]="{action: action.id}">Edit</custom-btn></div>
    <div *ngIf="action.id == 'ACT_DELETE'" class="menu-element"><custom-btn  color="accent" [mat-dialog-close]="{action: action.id}">Delete</custom-btn></div>
    <!--<div *ngIf="action.id == 'ACT_ADD'" class="menu-element"><custom-btn  color="accent" [mat-dialog-close]="{action: action.id}">Add</custom-btn></div>-->
  </ng-container>
</div>

