import { HttpParams } from '@angular/common/http';
import {AfterContentChecked, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute, Data, Params } from '@angular/router';
import { cloneDeep, isEqual, map, toNumber } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { DataSet, Layout, SectionLayout } from 'src/app/models/layout';
import {Pnc, PncSerial, ProblemDetail} from 'src/app/models/response';
import { GeneralService } from 'src/app/services/crud/general.service';
import { LayoutServiceService } from 'src/app/services/layout.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TableControllerService } from 'src/app/services/table-controller.service';
import { NavigationService } from 'src/app/services/utils/navigation.service';
import { ALERT_TYPE } from '../../shared/alert/alert.enumerate';
import { AlertService } from '../../shared/alert/alert.service';
import { CameraRollComponent } from '../../shared/camera-roll/camera-roll.component';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { ProblemDetailComponent } from '../../shared/template/problem-detail/problem-detail.component';
import { SerialComponent } from '../serial/serial.component';
import {RegExpInterface} from "../../../models/RegExpInterface";
import {QrCodeComponent} from "../../shared/qr-code/qr-code.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-pnc',
  templateUrl: './pnc.component.html',
  styleUrls: ['./pnc.component.scss'],
  providers: [
    {
      provide: 'PncTableController',
      useClass: TableControllerService,
    },
    {
      provide: 'SerialTableController',
      useClass: TableControllerService,
    },
  ],
})
export class PncComponent implements OnInit, OnDestroy, AfterContentChecked {

  @Input()
  layout: Layout | undefined;

  detail: Pnc = {
    L_SCORECARD_PROBLEM_PNC_SERIAL: [],
    PNC: '',
    PNC_APPLIANCES_QTY: 0,
    PNC_PACKAGES_QTY: 0,
    SCORECARD_PROBLEM_PNC_ID: '',
    SCORECARD_PROBLEM_ID: '',
};
  detailBck: Pnc | undefined;

  isEditable: boolean = false;

  editable:boolean = false;

  isNew: boolean = false;

  pageId: string = '';
  problemId: string = '';
  scorecardId: string = '';
  scorecardProblemPncId: string = '';
  category: String = '';
  apiParent: string = 'primary';

  apiPath: string = '/scorecards/problems/pnc';

  disabledColumns: string[] = [];

  defaultCheckedButton: string[] = ['IS_PACKAGE'];

  disabledActions = [
    { id: 'ACT_ADD', motivation: 'Save problem before adding PNC' }
  ]

  isChanged: boolean = false;


  pncRegExp:  RegExpInterface[] = [{"regExp":"/[^0-9]/g","replaceValue":""},{"regExp":"/(\\..*)\\./g","replaceValue":"$1"}];


  getPageLayout() {
    const layoutsString = localStorage.getItem('layout');
    if (!!layoutsString) {
      const layouts = JSON.parse(layoutsString);
      if (!!layouts && !!layouts[this.pageId]) {
        this.setLayout(layouts);
      } else {
        this.fetchLayout();
      }
    } else {
      this.fetchLayout();
    }
  }

  setLayout(layouts: any) {
    const pageLayout = layouts[this.pageId];
    if (Math.abs((new Date(pageLayout['instant']).getTime() - new Date().getTime()) / 60000) < 360) {
      this.layout = pageLayout['instance'];
    } else {
      this.fetchLayout();
    }
  }

  fetchLayout() {
    this.spinner.show();
    this.layoutService.getPageLayout(this.pageId).subscribe((res) => {
      this.layout = res?.layouts[0] ?? undefined;
      const layoutsString = localStorage.getItem('layout');
      if (!!layoutsString) {
        let layouts = JSON.parse(layoutsString);
        layouts[this.pageId] = {
          instance: res?.layouts[0],
          instant: new Date(),
        };
        localStorage.setItem('layout', JSON.stringify(layouts));
      } else {
        let layouts = {};
        layouts[this.pageId] = {
          instance: res?.layouts[0],
          instant: new Date(),
        };
        localStorage.setItem('layout', JSON.stringify(layouts));
      }

      this.spinner.hide();

    });
  }


  setDetail(params: any) {
    this.detail = {
      SCORECARD_PROBLEM_PNC_ID: params['scorecardProblemPncId'] ?? '',
      SCORECARD_PROBLEM_ID: params['problemId'] ?? '',
      PNC: params['pnc'] ?? '',
      PNC_APPLIANCES_QTY: params['app'] ?? 0,
      PNC_PACKAGES_QTY: params['pack'] ?? 0,
      L_SCORECARD_PROBLEM_PNC_SERIAL: [],
    };
  }


  handleQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {

      console.log(params);

      this.pageId = params['id'] ?? '';
      this.category = params['category']
      let isNewStr = params['n'] ?? 'false';
      this.isNew = (isNewStr === 'true');


      if (this.isNew) {
        this.isEditable = true;
      }else{
        //this.isEditable = !(this.category === 'FA' ||  this.category === 'WFA')
        this.isEditable = false
      }

      this.scorecardProblemPncId = params['scorecardProblemPncId'] ?? '';
      this.disabledColumns = (params['disabledColumns'] ?? '').split(',');

      this.refreshDetail();

      this.setDetail(params);

    });



    console.log(this.pncTableController)
    this.pncTableController.dataSet = this.detail?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? [];
    this.pncTableController.selection?.clear();

    if ((this.detail?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? []).length > 0) {
      this.pncTableController.row = this.detail?.L_SCORECARD_PROBLEM_PNC_SERIAL[0];
      this.pncTableController.rowNumber = 0;
    }


    this.serialTableController.dataSet =
      this.pncTableController.dataSet[0]?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? [];
    this.serialTableController.selection.clear();

    this.getPageLayout();
  }

  customizeAfterClick() {
    this.pncTableController.customizeAfterAddClick = (newRowObj: any): any => {

      newRowObj['PNC_PACKAGES_QTY'] = 0;
      newRowObj['PNC_APPLIANCES_QTY'] = 0;

      if (!!this.pncTableController.dataSet && this.pncTableController.dataSet.length > 0) {
        (this.pncTableController.dataSet[this.pncTableController.rowNumber])['L_SCORECARD_PROBLEM_PNC_SERIAL'] = cloneDeep(this.serialTableController.dataSet.map(serial => {
          delete serial.edited;
          delete serial.editing;
          delete serial.newRow;
          return serial;
        }) ?? []);

        this.recalculateDetail();
        this.serialTableController.dataSet = []
      }

      if (newRowObj['L_SCORECARD_PROBLEM_PNC_SERIAL']) {
        return cloneDeep(newRowObj);
      } else {
        newRowObj['L_SCORECARD_PROBLEM_PNC_SERIAL'] = [];
        return cloneDeep(newRowObj);
      }
    }
  }

  constructor(
    @Inject('PncTableController')
    public pncTableController: TableControllerService,
    @Inject('SerialTableController')
    public serialTableController: TableControllerService = new TableControllerService(),
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public layoutService: LayoutServiceService,
    private generalDataService: GeneralService,
    private spinner: SpinnerService,
    public dialog: MatDialog,
    public alertService: AlertService,
    public navigation: NavigationService,
    public dialogRef: MatDialogRef<PncComponent>,
    private translateService: TranslateService,
  ) {
    this.handleQueryParams()
    this.customizeAfterClick()
  }

  ngOnInit(): void {
    this.spinner.hideAll();
  }
  onSaveClick(event, close: boolean = false) {
    this.spinner.show();
    if (this.detail) {
      lastValueFrom(
        this.generalDataService.postData(
          this.apiParent + '/scorecards/problem/pnc',
          [this.detail],
          //new HttpParams().set('problemId', this.problemId)
        )
      )
        .then((res) => {
          const pnc = res['oContent'][0].PNC ?? '';

          this.isNew = false;
          const queryParams: Params = {
            pnc: pnc,
            app: res['oContent'][0].PNC_APPLIANCES_QTY,
            pack: res['oContent'][0].PNC_PACKAGES_QTY,
            n: false
          };

          console.log(queryParams)

          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge', // refmove to replace all query params by provided
          });
        })
        .finally(() => {
          this.spinner.hide();
          if (!close) {
            //this.refreshData();
          } else {
            this.navigation.back();
          }
        });
    }
  }

  serialActionCatcher(event: string) {
    if(this.serialTableController.rowNumber == -1) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: "No serial selected",
          content: `No serial has been selected or no serial is available.`,
          confirmButtonLabel: "Ok",
          confirmButtonColor: "Basic",
        },
      });
      return;
    }

    if (event.startsWith('ACT_ROLLBACK')) {
      this.serialTableController.rowNumber = -1;
      return;
    }
    if (event.endsWith('_ALL')) {
    } else if (event.endsWith('_ID')) {
      if (event.startsWith('ACT_EDIT_SERIAL')) {
        const pncSerial = {
          pnc: this.detail?.PNC,
          serial: this.serialTableController?.row?.SERIAL_NUMBER
        }

        const serialDialog = this.dialog.open(SerialComponent, {
          maxWidth: '95vw',
          maxHeight: '90vh',
          data: {
            isNew: false,
            pncSerial: pncSerial,
            isApp: this.serialTableController.row.IS_APPLIANCE
          }
        });

        serialDialog.afterClosed().subscribe(res => {
          if (!!res) {
            console.log(res)
            const newSerial: PncSerial = {
              IS_APPLIANCE: res['isApp'],
              IS_PACKAGE: !res['isApp'],
              SCORECARD_PROBLEM_PNC_ID: this.serialTableController?.row?.SCORECARD_PROBLEM_PNC_ID,
              SCORECARD_PROBLEM_PNC_SERIAL_ID: this.serialTableController?.row?.SCORECARD_PROBLEM_PNC_SERIAL_ID,
              SERIAL_NUMBER: res['serial']
            }

            this.generalDataService.postSerialDetail(this.apiParent + '/scorecards/problem/pnc/serial', newSerial, this.detail.SCORECARD_PROBLEM_ID, pncSerial.pnc).subscribe({
              next: value => {
                this.refreshDetail();

                this.alertService.add({
                  type:  ALERT_TYPE.SUCCESS,
                  message:this.translateService.instant('LABEL_SAVED'),
                  timeout: 5000,
                  selfClose: null
                });


              },
              error: err => {
                //alert(this.translateService.instant('LABEL_ERROR_SERIAL'))
                this.alertService.add({
                  type:  ALERT_TYPE.DANGER,
                  message:this.translateService.instant('LABEL_ERROR_SERIAL'),
                  timeout: 5000,
                  selfClose: null
                });

              }
            });

          }

        });
      } else if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Confirm delete selected rows',
            content: `Are you sure to proceed?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          ////(res);
          if (res) {
            this.generalDataService.deleteDetailData(this.apiParent + '/scorecards/problem/pnc/serial', [this.serialTableController.row.SCORECARD_PROBLEM_PNC_SERIAL_ID], new HttpParams().set("pncId", this.serialTableController.row.SCORECARD_PROBLEM_PNC_ID)).subscribe(res => {
              this.refreshDetail()
            })
            /*this.serialTableController.dataSet = this.serialTableController.dataSet.map((serial, index) => {
              if (
                this.serialTableController.rowNumber == index
              ) {
                serial['deleted'] = true;
                this.serialTableController.deleteErrors(index);
              }
              return serial;
            }).filter(serial => !serial["deleted"] || (serial["deleted"] && !!serial['PROBLEM_PNC_SERIAL_ID']));
            this.serialTableController.deletedRows = [];
            this.serialTableController.rowNumber = -1;*/
          } else {
            this.serialTableController.deletedRows = [];
          }
        });
      } else if (event.startsWith('ACT_SAVE')) {
        if (
          !!this.pncTableController.row['PNC']
        ) {

          this.spinner.show();
          lastValueFrom(
            this.generalDataService.postData(
              this.apiParent + '/problems/pncSerial',
              this.serialTableController.dataSet
                .filter((row) => {
                  return row.edited;
                })
                .map((elem) => {
                  elem['IS_PACKAGE'] =
                    typeof elem['IS_PACKAGE'] == 'string'
                      ? elem['IS_PACKAGE'] == '' || elem['IS_PACKAGE'] == 'N'
                      : elem['IS_PACKAGE'];
                  elem['IS_APPLIANCE'] =
                    typeof elem['IS_APPLIANCE'] == 'string'
                      ? elem['IS_APPLIANCE'] == '' ||
                      elem['IS_APPLIANCE'] == 'N'
                      : elem['IS_APPLIANCE'];
                  elem['PROBLEM_ID'] = this.problemId;
                  elem['PNC'] = this.pncTableController.row['PNC'];
                  delete elem.editing;
                  delete elem.checked;
                  return elem;
                }),
              new HttpParams()
                .set('pnc', this.pncTableController.row['PNC'])
                .set('problemId', this.problemId)
            )
          )
            .then((res) =>
              this.alertService.add({
                type: ALERT_TYPE.SUCCESS,
                message: 'Saved new serial.',
                timeout: 3000,
                selfClose: null,
              })
            )
            .catch((err) => {
              this.alertService.add({
                type: ALERT_TYPE.DANGER,
                message: "Unable to save a new serial.",
                timeout: 8000,
                selfClose: null,
              });
            })
            .finally(() => {
              this.spinner.hide();
              //this.refreshData(false, this.pncTableController.rowNumber);
            });
        } else {
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: "Unable to save a new serial without a valid Pnc or Problem Id.",
            timeout: 8000,
            selfClose: null,
          });
        }
      }
    } else {
      if (this.serialTableController.selection.selected.length == 0) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'No rows selected',
            content: `Please select at least 1 row...`,
            confirmButtonLabel: 'Ok',
            confirmButtonColor: 'basic',
          },
        });
        return;
      }

      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Confirm delete selected rows',
            content: `Are you sure to proceed?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          ////(res);
          if (res) {
            //this.serialTableController.dataSet = this.serialTableController.dataSet.filter(serial => !this.serialTableController.selection.selected.some(deleteItem => serial['PROBLEM_ID'] == deleteItem['PROBLEM_ID'] && serial['PNC'] == deleteItem['PNC']) )
            this.serialTableController.dataSet = this.serialTableController.dataSet.map((serial, index) => {
              if (
                this.pncTableController.selection.selected.some(
                  (deleteItem) => serial["PROBLEM_ID"] == deleteItem["PROBLEM_ID"] && serial["PNC"] == deleteItem["PNC"]
                )
              ) {
                serial['deleted'] = true;
                this.serialTableController.deleteErrors(index);
              }
              return serial;
            });

            this.serialTableController.deletedRows = [];
            this.serialTableController.selection.clear();
            /*this.spinner.show();
            this.generalDataService
              .deleteDetailData(
                this.apiParent + '/problems/pncSerial',
                this.serialTableController.selection.selected.map(
                  (elem) => elem['PROBLEM_PNC_SERIAL_ID']
                ),
                new HttpParams()
                  .set('pnc', this.pncTableController.row['PNC'])
                  .set('problemId', this.problemId)
              )
              .subscribe((res) => {
                this.serialTableController.deletedRows = [];
                this.serialTableController.selection.clear();
                this.spinner.hide();
                //this.refreshData();
              });*/
          } else {
            this.serialTableController.deletedRows = [];
            this.serialTableController.selection.clear();
          }
        });
      }
    }
  }

  ngOnDestroy() {
    localStorage.setItem('changes', 'false');
  }

  ngAfterContentChecked() {
    if (!isEqual(this.detailBck, this.detail)) {
      localStorage.setItem('changes', 'true');
      this.isChanged = true;
    } else {
      localStorage.setItem('changes', 'false');
      this.isChanged = false;
    }
  }

  onRowClick(row: number) {
    this.serialTableController.row = this.serialTableController.dataSet[row];

    this.serialTableController.rowNumber = row;

    this.serialTableController.selection.clear();
  }

  onCancelClick() {

    if (this.isChanged) {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Unsaved changes',
          content: `You have made changes. Do you want to stay or discard them?`,
          confirmButtonLabel: 'Discard',
          confirmButtonColor: "warn",
          cancelButtonLabel: "Stay",
          cancelButtonColor: "primary"
        },
      }).afterClosed().subscribe(res => {
        if (res)
          this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  openCameraRoll() {
    lastValueFrom(
      this.generalDataService.getDataById(
        this.apiParent + '/' + this.apiPath + '/pictures',
        'problem',
        this.problemId
      )
    ).then((pictures) => {
      const dialogRef = this.dialog.open(CameraRollComponent, {
        width: '100%',
        data: {
          title: 'Camera roll for ' + this.problemId + ' problem',
          content: pictures['oContent'],
          apiUrl: this.apiParent + '/' + this.apiPath + '/pictures',
          problemId: this.problemId,
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'accent',
        },
      });
    });
  }

  onCloseClick() {
    if (this.pncTableController.rowNumber >= 0 && this.pncTableController.dataSet.length > 0) {
      this.pncTableController.dataSet[this.pncTableController.rowNumber].L_SCORECARD_PROBLEM_PNC_SERIAL = this.serialTableController.dataSet.map(serial => {
        delete serial.edited;
        delete serial.editing;
        delete serial.newRow;
        return serial;
      }) ?? [];
    }
    this.detail ? this.detail.L_SCORECARD_PROBLEM_PNC_SERIAL = this.pncTableController.dataSet.map(pnc => {
      delete pnc.edited;
      delete pnc.newRow;
      delete pnc.editing;
      return pnc;
    }) : []

    if (this.detail) {
      if (this.category == 'FA') {
        this.detail.PNC_APPLIANCES_QTY = this.detail.L_SCORECARD_PROBLEM_PNC_SERIAL.reduce((acc, serial) => {
          return serial.IS_APPLIANCE ? acc + 1 : acc
        }, 0)

        this.detail.PNC_PACKAGES_QTY = this.detail.L_SCORECARD_PROBLEM_PNC_SERIAL.reduce((acc, serial) => {
          return serial.IS_PACKAGE ? acc + 1 : acc
        }, 0)
      }
    }


    this.dialogRef.close({ data: this.detail })
  }

  recalculateDetail() {
    if (this.detail) {
      if (this.category == 'FA' && this.pncTableController.rowNumber >= 0 && this.pncTableController.dataSet.length > 0) {
        this.pncTableController.dataSet.forEach(pnc => {
          pnc.PNC_APPLIANCES_QTY = pnc.L_PROBLEM_PNC_SERIAL?.reduce((acc, serial) => {
            return serial.IS_APPLIANCE ? acc + 1 : acc
          }, 0)

          pnc.PNC_PACKAGES_QTY = pnc.L_PROBLEM_PNC_SERIAL?.reduce((acc, serial) => {
            return serial.IS_PACKAGE ? acc + 1 : acc
          }, 0)
        })
      }
      this.detail.PNC_APPLIANCES_QTY
        = this.pncTableController.dataSet?.reduce((acc, pnc) => {
          return acc + toNumber((pnc.PNC_APPLIANCES_QTY) ?? 0)
        }, 0) ?? 0;
      this.detail.PNC_PACKAGES_QTY
        = this.pncTableController.dataSet?.reduce((acc, pnc) => {
          return acc + toNumber((pnc.PNC_PACKAGES_QTY) ?? 0)
        }, 0) ?? 0;
    }
  }


  refreshDetail(){
    if(this.scorecardProblemPncId){
      this.generalDataService.getDetail(this.apiParent + '/scorecards/problem/pnc', this.scorecardProblemPncId).subscribe({
        next: value => {
          console.log(value.oContent)
          this.detail = value.oContent
          this.detailBck = cloneDeep(value.oContent)
          this.serialTableController.dataSet = this.detail?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? [];
          this.serialTableController.selection?.clear();

          if ((this.detail?.L_SCORECARD_PROBLEM_PNC_SERIAL ?? []).length > 0) {
            this.serialTableController.row = this.detail?.L_SCORECARD_PROBLEM_PNC_SERIAL[0];
            this.serialTableController.rowNumber = 0;
          }
          /*this.detail = value.oContent
          this.detailBck = cloneDeep(value.oContent)

          this.pncTableController.dataSet = this.detail?.L_SCORECARD_PROBLEM_PNC ?? [];
          this.pncTableController.selection?.clear();

          if ((this.detail?.L_SCORECARD_PROBLEM_PNC ?? []).length > 0) {
            this.pncTableController.row = this.detail?.L_SCORECARD_PROBLEM_PNC[0];
            this.pncTableController.rowNumber = 0;
          }

          if(this.layout){
            this.catchAllSelectedOptions(this.layout);
          }*/

        },
        error: err => {
          console.error(err)
        }
      });
    }



  }


  qrCodeScanner() {
    const qrCodeDialog = this.dialog.open(QrCodeComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh'
    })

    qrCodeDialog.afterClosed().subscribe(closeResult => {
      if (!!closeResult) {

        if(closeResult.length >= 22){
          this.detail.PNC= closeResult.substring(3, 12)
        }else{
        }

      }
    })
  }

}
