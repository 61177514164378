import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RootObject, ShipmentStop } from '../../models/response/FromOtm';

@Injectable({
  providedIn: 'root',
})
export class DamageService {
  constructor(private httpClient: HttpClient) {}

  groupDamagesByWh(damageIds: any[], typology: string, id: string, url: string) {
    const params = new HttpParams().set('typology', typology).set('id', id);
    return this.httpClient.post<any>(
      environment.apiUrl + url + "/groups",
      damageIds,
      { params }
    );
  }

  notify(
    arg0: { contactGroups: NotificationSLBody[] },
    typology: any,
    notificationId: any,
    url: string
  ): Observable<any> {
    const params = new HttpParams().set('type', typology).set('id', notificationId);
    return this.httpClient.post<any>(
      environment.apiUrl + url + '/groups/send',
      arg0,
      { params }
    );
  }

  directNotify(damageIds: any[], typology: string, id: string, url: string) {
    const params = new HttpParams().set('t', typology).set('nId', id);
    return this.httpClient.post<any>(
      environment.apiUrl + url + "/send",
      damageIds,
      { params }
    );
  }

  getFromOtm(shipment: string) {
    const params = new HttpParams().set('id', shipment);

    /*
    * return this.httpClient.post<any>(
      'https://prod-157.westeurope.logic.azure.com:443/workflows/e8813205eb6843a58d620fb941c8f63a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TMedRBNDu_QcLfSgnKxk9PuRLyeLf5pRFL9O86U03tA',
      {
        shipment: shipment
      },
      {params}
    );
    * */

    return this.httpClient.get<OTMDetail>(
      environment.apiUrl + 'primary/damages/otm',
      { params }
    );
  }
}

export interface OTMDetail {
  shipmentOtm: string;
  loadNum: string;
  carrier: string;
  plateNumber: string;
  container: string;
  wagon: string;
  pickUpStops: ShipmentStopToSelect[];
  dropDownStops: ShipmentStopToSelect[];
  selectedPickUp: ShipmentStopToSelect;
  selectedDropdown: ShipmentStopToSelect;
  fileToSend?: File[];
}

export interface ShipmentStopToSelect {
  id: string;
  name: string;
  data: ShipmentStop;
}

export interface Attachments {
  name: string;
  blob: File;
}

export interface contactGroupDataSource {
  [contactGroup: string]: any;
}

export interface NotificationSL {
  [key: string]: NotificationSLBody;
}

export interface NotificationReplyBody {
  notificationBody: string;
  notificationCc: string;
  notificationTo: string;
  notificationSubject: string;
  messageId: string;
  conversationId: string;
  receivedDateTime: string;
  notificationTypology: string;
  notificationThreadId: number;
  notificationAttachments: {
    name: string;
    contentBytes: string;
    contentType: string;
    size: number;
  }[];
  notificationThreadDesc: string;
}

export interface NotificationSLBody {
  htmlBody: string;
  sendCc: string[];
  sendTo: string[];
  mailSubject: string;
  files: any[];
}
