<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">
  <h1 class="fs-1 fw-bold text-left mt-3">{{ isNew ? 'New PNC' : 'Edit PNC'}}</h1>
  <div class="mt-lg-1" *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
    <div
         *ngIf="sectionLayout['id'] == 'LYO_SEC_PNC_DT_DETAIL' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas && !!detail"
         class="detail-container p-3">
      <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
        <div class="detail-content">
          <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
          <div style="display: flex; align-items: center">
            <custom-text-field *ngIf="data.columnName == 'PNC' && isNew" [appearance]="'fill'"
                               [placeholder]="data.columnLabel | translate "
                               [disabled]="false"
                               [(value)]="detail[data.columnName]" [maxlength]="9" [pattern]="'[0-9]{9}'" [regexps]="pncRegExp"></custom-text-field>
            <custom-text-field *ngIf="data.columnName == 'PNC' && !isNew" [appearance]="'fill'"
                               [placeholder]="data.columnLabel | translate"
                               [disabled]="!isEditable"
                               [(value)]="detail[data.columnName]" [maxlength]="9" [pattern]="'[0-9]{9}'" [regexps]="pncRegExp"></custom-text-field>
            <custom-btn style="margin-top: 8px"
              *ngIf="data.columnName == 'PNC'"
              [iconButton]="true" class="h-2"
              [iconClasses]="'qr_code_scanner'" [btnClasses]="'h-2'" (click)="qrCodeScanner()"
              [fontSize]="'2rem'"></custom-btn>
          </div>


          <custom-text-field *ngIf="data.columnName != 'PNC'" [appearance]="'fill'"
                             [placeholder]="data.columnLabel | translate"
                             [disabled]="!isEditable"
                             [(value)]="detail[data.columnName]"></custom-text-field>

        </div>
      </ng-container>
    </div>

    <div class="table-container">
      <div *ngIf="sectionLayout['id'] == 'LYO_SEC_PNC_DT_SERIAL' " class="table-content">
        <data-table
                    *ngIf="sectionLayout['id'] == 'LYO_SEC_PNC_DT_SERIAL' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
                    [datasets]="(sectionLayout['dataset'])[0]"
                    [actionsets]="isEditable ? (sectionLayout['actionset'])[0] : []"
                    (onActionEmit)="serialActionCatcher($event)" [tableController]="serialTableController"
                    [defaultCheckedValue]="defaultCheckedButton"
                    [numElementsLoaded]="serialTableController.numElementsLoaded"
                    [length]="serialTableController.numElementsSize"
                    (onRowClick)="onRowClick($event)"
                    (longPress)= "serialActionCatcher('ACT_EDIT_SERIAL'+ '_ID')"


                    ></data-table>

      </div>
    </div>

    <ng-container *ngIf="sectionLayout['id'] == 'LYO_SEC_PNC_DT_BUTTON'">
      <action-bar>

        <div class="col-btn" leftBtn>
          <custom-icon-btn [compareTo]="detail" [masterFrom]="detailBck" backNavigation color="accent" [iconClasses]="'arrow_back'"></custom-icon-btn>
        </div>

        <ng-container *ngFor="let action of sectionLayout.actionset[0].actions" centerBtn>
          <div class="col-btn" *ngIf="action.id == 'ACT_SAVE'">
            <custom-icon-btn (click)="onSaveClick($event, true)" [btnClasses]="'fill-available'" color="accent" [iconClasses]="'save'"></custom-icon-btn>
          </div>
        </ng-container>

        <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0]?.actions">
          <div class="col-auto" *ngIf="action.id == 'ACT_EDIT_SERIAL'">
            <custom-btn color="primary" (click)="serialActionCatcher(action.id + '_ID')">Edit Serial</custom-btn>
          </div>
        </ng-container>

      </action-bar>
    </ng-container>

  </div>
</ng-container>
