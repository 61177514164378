import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '../../shared/alert/alert.service';
import { ALERT_TYPE } from '../../shared/alert/alert.enumerate';
import {
  FILTER_TYPE,
  FilterInterface,
  FilterValue,
} from '../../shared/filter/custom-filter/filter.interface';
import { SelectType } from '../../shared/custom-select/custom-select.component';
import { IMultiSelectOption } from '../../shared/custom-multiselect/types';
import { refactorName } from '../../../utils/generic';
import { LayoutServiceService } from '../../../services/layout.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LaneSelectionComponent } from '../lane-selection/lane-selection.component';
import { MatDialog } from '@angular/material/dialog';
import {AuthService} from "../../../services/auth.service";
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {RedirectRequest} from "@azure/msal-browser";
import {ScorecardDetail} from "../../../models/response";
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {DndComponent} from "../../shared/dnd/dnd.component";
import {HttpParams} from "@angular/common/http";
import {lastValueFrom} from "rxjs";
import {DraftDialogComponent} from "../../shared/draft-dialog/draft-dialog.component";
import {Router} from "@angular/router";
import {GeneralService} from "../../../services/crud/general.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  user = "Guest"
  title = ""
  userContextError: boolean = false
  name: string | undefined;
  email: string = '';
  role: string | undefined;
  userDef: string = '';
  userOldDef: string = '';
  userContext: any[] = []

  constructor(
    public alertService: AlertService,
    public layoutService: LayoutServiceService,
    public dialog: MatDialog,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    public authService: AuthService,
    private router: Router,
    private generalDataService: GeneralService,
	private spinner: NgxSpinnerService
  ) {

  }

  ngAfterViewInit(): void {
        this.alertService.reset()
        
    }

  

    ngOnInit(): void {
      localStorage.setItem("isNew","false");
      // Subscribe to email observable outside
      this.authService.emailObs.subscribe((email) => {
        this.email = email;
      });

		this.authService.roleObs.subscribe(res=>{
			if(!!res){
				this.title= ""
				this.userContextError = false

			}
			else{
				this.user=this.authService.nameBS.value;
				if(!!this.user)
					this.title= "You are not signed in or there are an error with your User Context. Please contact administrator."
				else{
					this.userContextError = true
					this.title = "Some error occurred with authentication"
				}

			}
		});

		this.authService.emailObs.subscribe((email) => {
			this.generalDataService.getAvailableUserContext(email).subscribe({


				next: value => {
					if(!(!!value.oContent && value.oContent.length > 0)){
					}
					this.fetchUserContext(this.email);

				},

				error: err => {
					this.userContextError = true;
				}
			});
		});
    }
    
    private fetchUserContext(email: string): void {
      this.generalDataService.getAvailableUserContext(email).subscribe({
        next: value => {
          this.userContext = value.oContent.map((res) => {
            this.userDef = value.oContent[0].id;
            this.userOldDef = this.userDef;
            return {
              id: res.id,
              name: res.id + " - " + res.name,
            };
          });
    
          this.generalDataService.getLastSelectedUserContext(this.email).subscribe({
            next:value=>{
              if(!value.oContent){
    
                this.generalDataService.setUserContext(this.userDef, this.userOldDef).subscribe(res => {

                  localStorage.removeItem('layout')
                  localStorage.removeItem('filter')
                  Object.keys(sessionStorage).forEach(function (key) {
                    if (!key.startsWith("sort")) {
                      sessionStorage.removeItem(key)
                    }
                  })
                  //window.location.reload();
                })
              }
            }
          })
        },
        error: err => {
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: 'Some error occurred on data retrieve',
            timeout: 5000,
            selfClose: null,
          });
        }
      });
    }
    

/*
          this.generalDataService.getLastSelectedUserContext(this.email).subscribe({
            next:value=>{
              if(!value.oContent){
    
                this.generalDataService.setUserContext(this.userDef, this.userOldDef).subscribe(res => {

                  localStorage.removeItem('layout')
                  localStorage.removeItem('filter')
                  Object.keys(sessionStorage).forEach(function (key) {
                    if (!key.startsWith("sort")) {
                      sessionStorage.removeItem(key)
                    }
                  })
                  //window.location.reload();
                })
              }
            }
          })
*/


  addAlert() {
    this.alertService.add({
      type: ALERT_TYPE.PRIMARY,
      message: 'Messaggio di errore',
      timeout: 2000,
      selfClose: null,
    });
  }

  onChange(event: any): void {
    this.alertService.add({
      type: ALERT_TYPE.PRIMARY,
      message: 'You have selected: ' + event,
      timeout: 1000,
      selfClose: null,
    });
  }

  login(): void {
    if (!this.msalService.instance.getActiveAccount()) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    } else {
      localStorage.setItem(
        'token',
        <string>this.msalService.instance.getActiveAccount()?.idToken
      );
    }
  }

  logout(): void {
    this.msalService.instance.logout()
  }

  actionCatcher(event: string, parent:string, url:string, page_id:string) {

    if(event == 'TO_LANE'){
      localStorage.setItem("isNew","true")
      this.router.navigate(
        ['page/' + parent + '/' + url + '/lane-selection'],
        {
          queryParams: {
            id: page_id,
            n: true,
            t: parent,
            title: 'warehouse'
          },
        }
      );
    }else if( event == 'NEW_WH'){
      localStorage.setItem("isNew","true")
      let detail: ScorecardDetail = {
        CARRIER_ID: "",
        CONTAINER: "",
        SCORECARD_APPLIANCES_QTY: 0,
        SCORECARD_PACKAGES_QTY: 0,
        DAMAGE_ID: "",
        DAMAGE_TYPE_ID: parent.toUpperCase(),
        DELIVERY_MODE_ID: "",
        DELIVERY_TYPE_ID: "",
        DESPATCHED_QTY: 0,
        INSERT_DATE: "",
        INSERT_USER: "",
        L_EFFICIENCY_PROBLEMS: [],
        L_PROBLEMS: [],
        OTM_SHIPMENT_ID: "",
        RECEIVING_DATE: "",
        RECEIVING_WEEK: "",
        RECEIVING_WH: "",
        REMARKS: "",
        SCORECARD_ID: "",
        SENDING_DATE: "",
        SENDING_WH: "",
        SHIPMENT_ID: "",
        STATUS: "",
        TRAILER_PLATE: "",
        TRUCK_PLATE: "",
        UPDATE_DATE: "",
        UPDATE_USER: "",
        WAGON: "",
        DESTINATION_WAREHOUSE_ID:""
      };

      this.generalDataService.postDataDetail(
        parent + '/scorecards/detail',
        detail
      ).subscribe(res => {

        detail = res['oContent']
        if (detail) {
          this.router.navigate(['page/' + parent + '/scorecards/detail'], {
            state: { data: detail },
            queryParams: {
              id: page_id + '_DETAIL',
              t: parent,
              damage: detail['DAMAGE_ID'],
              scr: detail['SCORECARD_ID'],
              shp: '',
              title: 'warehouse'
            },
          });
        }
      });
    }
  }

}
