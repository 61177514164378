<div class="d-flex flex-column h-100">
  <!--<h1 mat-dialog-title>Primary - Scorecard {{data.detail.SCORECARD_ID}}</h1>-->

  <mat-dialog-content class="mat-typography mat-dialog-content">
    <custom-select-values [(options)]="data.detail.L_EFFICIENCY_PROBLEMS" [disable]="!isEditable"
                          *ngIf="!!data.detail && !!data.detail.L_EFFICIENCY_PROBLEMS"
                          [title]="'Efficiency Problems'">
    </custom-select-values>

    <h2>Remarks</h2>
    <textarea class="form-control w-100 h-30" matInput [(ngModel)]="data.detail.REMARKS"
              placeholder="Remarks" [disabled]="!isEditable"></textarea>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-between">
    <custom-btn mat-dialog-close="{{false}}">Cancel</custom-btn>
    <custom-btn [mat-dialog-close]="{remarks: data.detail.REMARKS, efficiencyProblems: data.detail.L_EFFICIENCY_PROBLEMS}" [color]="'accent'">Ok</custom-btn>
  </mat-dialog-actions>
</div>
