<div class="form-group">
  <!--<input class="form-control text-center" placeholder="dd/mm/yyyy" name="d2" #c2="ngModel" [(ngModel)]="value"
    (ngModelChange)="onDateChange()" ngbDatepicker #d2="ngbDatepicker" [placement]="'left'" />
  <i class="fa fa-calendar icon" (click)="d2.toggle()"></i>-->
  <div class="input-group" >
    <input class="form-control input-picker" placeholder="dd/mm/yyyy" name="dp" [(ngModel)]="value" ngbDatepicker
      #d="ngbDatepicker" (ngModelChange)="onDateChange($event)" [readonly]="disabled" (focusout)="onFocusOut()"/>
    <button class="btn btn-outline-secondary bi bi-calendar3 btn-picker" (click)="d.toggle()" type="button" [disabled]="disabled"></button>
  </div>


  <!--<button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button"></button>-->
</div>
