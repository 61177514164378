import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[rightBtn]'
})
export class RightBtnDirective {

  constructor() {
  }

}
