import { Component, Inject, OnInit } from '@angular/core';
import { lastValueFrom } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthService } from "../../../services/auth.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { GeneralService } from "../../../services/crud/general.service";
import { version } from '../../../version'
import { MatTooltip } from "@angular/material/tooltip";
import { DialogComponent } from "../dialog/dialog.component";
import { NavigationService } from "../../../services/utils/navigation.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from '@angular/common/http';
import { ALERT_TYPE } from '../alert/alert.enumerate';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  userContext: any[] = []
  appVersion: string = '';
  beVersion: string = '';
  destinations: any[] = [];
  username: string = '';

  list: any[] = [];

  name: string | undefined;
  email: string = '';
  role: string | undefined;
  userDef: string = '';
  userOldDef: string = '';

  constructor(
    protected router: Router,
    private msalService: MsalService,
    private authService: AuthService,
    private clipboard: Clipboard,
    private generalService: GeneralService,
    private dialog: MatDialog,
    private navigation: NavigationService,
    public translate: TranslateService,
    private generalDataService: GeneralService,
    private http: HttpClient,
    public alertService: AlertService,

  ) { }

  ngOnInit(): void {

    this.authService.emailObs.subscribe((email) => {
      this.email = email;

      this.generalDataService.getAvailableUserContext(this.email).subscribe({
        

        next: value => {

          this.userContext = value.oContent.map((res) => {

            this.userDef = value.oContent[0].id
            this.userOldDef = this.userDef
            return {
              id: res.id,
              name: res.id + " - " + res.name,
            };
          })

          this.generalDataService.getLastSelectedUserContext(this.email).subscribe({
            next:value=>{
              if(!value.oContent){
    
                this.generalDataService.setUserContext(this.userDef, this.userOldDef).subscribe(res => {

                  localStorage.removeItem('layout')
                  localStorage.removeItem('filter')
                  Object.keys(sessionStorage).forEach(function (key) {
                    if (!key.startsWith("sort")) {
                      sessionStorage.removeItem(key)
                    }
                  })
                  //window.location.reload();
                })
              }
            }
          })
          

        },
        error: err => {
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: 'Some error occured on data retrive',
            timeout: 5000,
            selfClose: null,
          })
        }
      });


    });
    this.authService.nameObs.subscribe((name) => {
      this.name = name;
    });
    this.authService.roleObs.subscribe((role) => {
      this.role = role;
    });



  }

  userContextChange(event: string) {
    if (this.userDef) {
      this.generalDataService.setUserContext(this.userDef, this.userOldDef).subscribe(res => {
        localStorage.removeItem('layout')
        localStorage.removeItem('filter')
        Object.keys(sessionStorage).forEach(function (key) {
          if (!key.startsWith("sort")) {
            sessionStorage.removeItem(key)
          }
        })
        window.location.reload();
      })

    }

  }

  login() {
    //this.onClickLogin.emit();
    this.authService.login();
  }

  userInfo() {
    //this.onClickInfo.emit();
  }

  logout() {
    //this.onClickLogout.emit();
    this.authService.logout()
  }

  isActionPresent(action: string) {
    //if (this.actions.indexOf(action) > -1) return true;
    //else return false;
    return true;
  }

  arrayFrom(string: string) {
    return Array.from(string)['0'] + Array.from(string)['1'];
  }

  onMenuClick() {
    //this.toggleSidenav.emit();
  }

  copyToken(): void {
    lastValueFrom(
      this.msalService.acquireTokenSilent({
        scopes: ['openid', 'profile', 'User.Read', 'email'],
      })
    ).then((token) => {
      this.clipboard.copy(token.idToken);
    });
  }

  info() {
    this.generalService.getVersion().subscribe(res => {
      this.beVersion = res['oContent'] ?? '';
      this.appVersion = version;
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'DDT Application Information',
          content: `Web application version: ` + this.appVersion,
          content2: `Server application version: ` + this.beVersion,
          confirmButtonLabel: 'Refresh layout & Close',
          confirmButtonColor: 'basic',
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'accent'
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res && res == true) {
          localStorage.removeItem('layout');
          window.location.reload();
        }
      });
    })
  }

  copy(tooltip: MatTooltip, value: string) {
    this.clipboard.copy(value);
    tooltip.show();
  }

  historySize() {
    return this.navigation.size()
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }
}
function getMenu() {
  throw new Error('Function not implemented.');
}

