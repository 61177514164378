<h1 class="fs-3 fw-bold text-left">{{ 'Add Serial' }}</h1>
<div class="dialog-container">
  <div class="shipment-container">
    <div class="input-qr-code">
      <custom-text-field (valueChange)="stringSpliting($event)" [customClass]="'max-width'"
                         [value]="pncAndSerial" class="d-inline-flex max-width"></custom-text-field>
      <custom-btn
        (click)="qrCodeScanner()" [btnClasses]="'h-2'"
        [fontSize]="'2rem'" [iconButton]="true" [iconClasses]="'qr_code_scanner'"
        class="h-2"></custom-btn>
    </div>
  </div>
  <div class="pnc-container">
    <label class="input-label-title">Pnc</label>
    <div class="input-qr-code">
      <custom-text-field (valueChange)="onPncChange($event)" [customClass]="'max-width'"
                         [maxlength]="9" [pattern]="'[0-9]{9}'" [regexps]="pncRegExp"
                         [value]="pnc" class="d-inline-flex max-width"></custom-text-field>
    </div>
  </div>
  <div class="serial-container">
    <label class="input-label-title">Serial</label>
    <div class="input-qr-code">
      <custom-text-field (valueChange)="onSerialChange($event)" [customClass]="'max-width'"
                         [maxlength]="8" [pattern]="'[A-Za-z0-9]{8}'" [regexps]="serialRegExp"
                         [value]="serial" class="d-inline-flex max-width"></custom-text-field>
    </div>
  </div>
  <div class="serial-detail-container">
    <div class="d-flex flex-row justify-content-around mt-3" style="
    width: 100%;
">
      <p>App <i (click)="isPkg = !isPkg" *ngIf="!isPkg" class="bi bi-record-circle"></i>
        <i (click)="isPkg = !isPkg" *ngIf="isPkg" class="bi bi-circle"></i>
      </p>
      <p>Pack <i (click)="isPkg = !isPkg" *ngIf="isPkg" class="bi bi-record-circle"></i>
        <i (click)="isPkg = !isPkg" *ngIf="!isPkg" class="bi bi-circle"></i>
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-between" mat-dialog-actions>
    <custom-icon-btn [iconClasses]="'arrow_back'" color="accent" mat-dialog-close></custom-icon-btn>
    <custom-icon-btn (click)="onAddClick()" [disabled]="pnc.length < 9 || serial.length < 8" [iconClasses]="'add'" class="float-right"
                     color="accent"></custom-icon-btn>
    <custom-icon-btn (click)="closeClick()" [disabled]="pnc.length < 9 || serial.length < 8" [iconClasses]="'save'" class="float-right"
                     color="accent"></custom-icon-btn>
  </div>
</div>

