<!-- <div class="page-header-container">
  <ng-content select="[page-title]"></ng-content>
  <span class="spacer"></span>
  <ng-content select="[action-type=button]"></ng-content>
</div>
-->

<div class="px-5 action-bar highlight-actionbar">
  <div class="action-bar">
    <div class="col-btn">
      <div class="row-btn justify-content-start">
        <ng-content select="[leftBtn]"></ng-content>
        <div class="col-btn-space"></div>
      </div>
    </div>
    <div class="col-btn">
      <div class="row-btn justify-content-center">
        <ng-content select="[centerBtn]"></ng-content>
      </div>
    </div>
    <div class="col-btn">
      <div class="cst-btn-group" *ngIf="childrensLength > 1; else singleBtn">
        <custom-btn color="accent" [matMenuTriggerFor]="actionsMenu" [iconButton]="true" [iconClasses]="'more_vert'" #otherBtns></custom-btn>
        <mat-menu #actionsMenu="matMenu" yPosition="above">
          <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </mat-menu>
      </div>
      <ng-template #singleBtn>
        <div class="cst-btn-group">
          <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
        </div>
      </ng-template>
      <ng-template #contentTpl>
        <ng-content select="[rightBtn]"></ng-content>
      </ng-template>
    </div>
  </div>
  <!--<div>
    <ng-content select="[cancelBtn]"></ng-content>
    <ng-content select="[category]"></ng-content>
    <ng-content select="[reason]"></ng-content>
    <ng-content select="[reasonDetail]"></ng-content>
  </div>
  <ng-content select="[saveBtn]"></ng-content>
  <ng-content select="[saveAndCloseBtn]"></ng-content>-->
</div>
