
<!--
<div class="shipment">
<h1 class="fs-1 fw-bold text-left mt-3">{{'Shipment'}}</h1>
<div class="edit"></div>
</div>
-->



<div class="shipment">
  <h1 class="fs-1 fw-bold text-left mt-3">{{'Primary'}}</h1>
  </div>
  
  <div class="edit"></div>
<div class="shipment-container">
  <label class="shipment-label-title">Shipment ID</label>
  <div class="input-qr-code">
    <custom-text-field [(value)]="shipmentId" class="d-inline-flex max-width"
                       [customClass]="'max-width'" ></custom-text-field>
    <custom-btn
      [iconButton]="true" class="h-2"
      [iconClasses]="'qr_code_scanner'" [btnClasses]="'h-2'" (click)="qrCodeScanner()"
      [fontSize]="'2rem'"></custom-btn>
  </div>
  <input type="file" accept="image/*" capture="environment" #cameraPhoto style="display: none" (change)="prepareFilesList(cameraPhoto.files)">
  <div class="input-qr-code">
    <label class="fs-6 fw-bold">{{'Transport Doc'}}</label>
    <div class="row">
      <div class="col-4"><custom-btn [iconButton]="true" [iconClasses]="'note_add'" (click)="uploadDoc()">
      </custom-btn></div>
      <div class="col-4"><custom-btn [iconButton]="true" [iconClasses]="'add_a_photo'" (click)="cameraPhoto.click()" >
      </custom-btn></div>
    </div>
  </div>
  <div class="input-qr-code">
    <div class="preview-files">
      <div *ngFor="let file of fileToSend">
        <ng-container *ngIf="IMAGE_FILE.includes(file.type) else simpleFile">
          <img [src]="file | file2Picture | async" [alt]="file.name" class="preview-image" />
        </ng-container>
        <ng-template #simpleFile>
          <img [src]="'assets/utils/g_file.png'" [alt]="file.name" class="preview-image" />
        </ng-template>
      </div>
    </div>
  </div>
</div>




<action-bar >
  <div leftBtn class="col-btn">
    <custom-icon-btn [compareTo]="null" [masterFrom]="null" backNavigation color="accent"
    [iconClasses]="'arrow_back'" [overriderPath]="'/'"></custom-icon-btn>
  </div>
  
  <div rightBtn class="col-btn">
    <custom-icon-btn [iconClasses]="'arrow_forward'" color="accent" (click)="onNextClick()"> </custom-icon-btn>
  </div>
</action-bar>


