<h1 mat-dialog-title>{{data.dataTitle}}</h1>
<div mat-dialog-content>


  <div class="example-container">
    <h2>Hidden Filters</h2>
    <div cdkDropList #hideList="cdkDropList" [cdkDropListData]="data.hideList" [cdkDropListConnectedTo]="[viewList]"
      class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of data.hideList; let i = index" cdkDrag>
        {{item.label}}
        <span class="material-symbols-outlined pointer right" (click)="transferArrayItem(i, 'view')">
          arrow_forward_ios
        </span>

      </div>
    </div>
  </div>
  <div class="example-container">
    <h2>Visible Filters</h2>
    <div cdkDropList #viewList="cdkDropList" [cdkDropListData]="data.viewList" [cdkDropListConnectedTo]="[hideList]"
      class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of data.viewList; let i = index" cdkDrag>

        <span class="material-symbols-outlined pointer left" (click)="transferArrayItem(i, 'hide')">
          arrow_back_ios
        </span>
        {{item.label}}
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <custom-btn [color]="'basic'" (click)="onNoClick()">Cancel</custom-btn>
  <custom-btn [color]="'accent'" [mat-dialog-close]="data" cdkFocusInitial>Ok</custom-btn>
</div>
