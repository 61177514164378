<div class="dialog-container">
  <ng-container *ngIf="!!availableDevices && testing">
    <div id="content">
      <div class="scanner-container">
        <zxing-scanner (camerasFound)="onCamerasFound($event)" (deviceChange)="onDeviceChange($event)" (permissionResponse)="onHasPermission($event)"
                       (scanSuccess)="onCodeResult($event)"
                       (scanError)="onCodeError($event)"
                       (torchCompatible)="onTorchCompatible($event)"
                       [device]="deviceCurrent" [enable]="openReader" [formats]="formatsEnabled"
                       [torch]="torchEnabled"
                       [tryHarder]="tryHarder" ></zxing-scanner>
      </div>

    </div>
  </ng-container>
  <ng-container *ngIf="!!availableDevices && !testing">
    <div>
      <custom-text-field [appearance]="'fill'" [placeholder]="'Please scan a QR Code'"
                         [(value)]="qrResultString" (valueChange)="onCodeResult($event)"></custom-text-field>
    </div>
  </ng-container>


  <section *ngIf="qrResultString" class="results mt-3 cst-broder">
    <div style="text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;">
      <small>Result: </small>
      <strong *ngIf="shipment.length == 5; else normalString">{{ shipment[0] }}<strong
        style="color: red">{{ shipment[1] }}</strong>{{ shipment[2] }}<strong
        style="color: red">{{ shipment[3] }}</strong>{{ shipment[4] }}</strong>
      <ng-template #normalString>
        <strong>{{ shipment[0] }}</strong>
      </ng-template>

    </div>
    <button (click)="clearResult()" mat-icon-button>&times;</button>
  </section>

  <ng-container *ngIf="hasPermission === undefined && testing">

    <h2>Waiting for permissions.</h2>

    <blockquote>
      If your device does not has cameras, no permissions will be asked.
    </blockquote>

  </ng-container>

  <ng-container *ngIf="hasPermission === false && testing">

    <h2>You denied the camera permission, we can't scan anything without it. 😪</h2>

  </ng-container>

  <ng-container *ngIf="hasDevices === undefined && testing">

    <h2>Couldn't check for devices.</h2>

    <blockquote>
      This may be caused by some security error.
    </blockquote>

  </ng-container>

  <ng-container *ngIf="hasDevices === false && testing">

    <h2>No devices were found.</h2>

    <blockquote>
      I believe your device has no media devices attached to.
    </blockquote>

  </ng-container>

  <div class="d-flex justify-content-between" mat-dialog-actions>
    <custom-icon-btn mat-dialog-close color="accent" [iconClasses]="'close'"></custom-icon-btn>
    <custom-icon-btn (click)="onNextDeviceClick()" color="accent" [iconClasses]="'flip_camera_ios'" *ngIf="(availableDevices?.length ?? 0)> 1" ></custom-icon-btn>
    <custom-icon-btn (click)="toggleTorch()" color="accent" [iconClasses]="'flashlight_on'" *ngIf="!torchEnabled && (torchAvailable$.asObservable() | async)"></custom-icon-btn>
    <custom-icon-btn (click)="toggleTorch()" color="accent" [iconClasses]="'flashlight_off'" *ngIf="torchEnabled && (torchAvailable$.asObservable() | async)"></custom-icon-btn>
    <custom-icon-btn (click)="onClose()" class="float-right" color="accent" [iconClasses]="'done'"></custom-icon-btn>
  </div>
</div>
