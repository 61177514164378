<h1 class="fs-1 fw-bold text-left mt-3">{{titlePage | titlecase}}</h1>
<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">
  <ng-container *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
    <div class="shadow p-3 mb-5 bg-white rounded"
         *ngIf="sectionLayout['id'] == 'LYO_SECFILT' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].filterset && !!filterController.filterModels">
      <filter-panel (onFilterClick)="onFilterClick($event)" [filterController]="filterController" [pageReference]="pageId" (onResetFilterClick)="resetFilter($event)"></filter-panel>
    </div>
    <data-table
      *ngIf="sectionLayout['id'] == 'LYO_SECTABLE' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
      [datasets]="(sectionLayout['dataset'])[0]" [actionsets]="(sectionLayout['actionset'])[0]"
      (onActionEmit)="actionCatcher($event)" [tableController]="tableController"
      [numElementsLoaded]="tableController.numElementsLoaded" [length]="tableController.numElementsSize"
      [disabledColumns]="disabledColumns"></data-table>
  </ng-container>

  <action-bar>
    <div leftBtn class="col-auto">
      <custom-btn backNavigation color="primary" [masterFrom]="null" [compareTo]="null">{{historySize() > 1 ? 'Back' : 'Home'}}</custom-btn>
    </div>
  </action-bar>
</ng-container>
