import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WarehouseService} from 'src/app/services/crud/warehouse.service';
import {DndComponent} from '../../shared/dnd/dnd.component';
import {DialogData} from '../../shared/filter/filter-settings/filter-settings.component';
import {FromOtmComponent} from "../from-otm/from-otm.component";
import {QrCodeComponent} from "../../shared/qr-code/qr-code.component";
import * as _ from "lodash"
import {GeneralService} from "../../../services/crud/general.service";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {SpinnerService} from "../../../services/spinner.service";
import {AlertService} from "../../shared/alert/alert.service";
import {NavigationService} from "../../../services/utils/navigation.service";
import {ALERT_TYPE} from "../../shared/alert/alert.enumerate";
import {of} from "rxjs";
import {RegExpInterface} from "../../../models/RegExpInterface";

@Component({
  selector: 'app-serial-selection',
  templateUrl: './serial-selection.component.html',
  styleUrls: ['./serial-selection.component.scss'],
})
export class SerialSelectionComponent implements OnInit {
  pncAndSerial: String = '';

  startSegment: string = '000';
  pnc: string = '';
  midSegment: string = '00';
  serial: string = '';
  endSegment: string = '0000000000';

  file?: File;
  pncRegExp:  RegExpInterface[] = [{"regExp":"/[^0-9]/g","replaceValue":""},{"regExp":"/(\\..*)\\./g","replaceValue":"$1"}];
  serialRegExp:  RegExpInterface[] = [{"regExp":"/[^A-Za-z0-9]/g","replaceValue":""},{"regExp":"/(\\..*)\\./g","replaceValue":"$1"}];

  isPkg: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<SerialSelectionComponent>,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
  }

  qrCodeScanner() {
    const qrCodeDialog = this.dialog.open(QrCodeComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh'
    })

    qrCodeDialog.afterClosed().subscribe(closeResult => {
      if (!!closeResult) {

        if(closeResult.length >= 22){
          this.startSegment = closeResult.substring(0, 3)
          this.pnc= closeResult.substring(3, 12)
          this.midSegment = closeResult.substring(12, 14)
          this.serial= closeResult.substring(14, 22)
          this.endSegment = closeResult.substring(22)
        }else{
          this.pnc = '';
          this.serial = '';
        }
        this.pncAndSerial = closeResult

      }
    })
  }

  closeClick(){
    this.dialogRef.close({
      pnc: this.pnc,
      serial: this.serial,
      isPkg: this.isPkg,
      stop: true
    })
  }

  stringSpliting(event: string) {
    const inputString= event.replace(/[^0-9]/g, '')
    if(inputString.length >= 22){
      this.startSegment = inputString.substring(0, 3)
      this.pnc= inputString.substring(3, 12)
      this.midSegment = inputString.substring(12, 14)
      this.serial= inputString.substring(14, 22)
      this.endSegment = inputString.substring(22)
      this.pncAndSerial = inputString;
    }else{
      this.pnc = '';
      this.serial = '';
    }
  }

  onPncChange(event: string){
    this.pnc = event
    this.pncAndSerial = '' + this.startSegment + event + this.midSegment + this.serial + this.endSegment
  }

  onSerialChange(event: string){
    this.serial = event
    this.pncAndSerial = '' + this.startSegment + this.pnc + this.midSegment + event + this.endSegment
  }

  onAddClick(){
    this.dialogRef.close({
      pnc: this.pnc,
      serial: this.serial,
      isPkg: this.isPkg,
      stop: false
    })
  }
}
