import {Component, ContentChildren, ElementRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Observable, of} from "rxjs";
import {RightBtnDirective} from "../../../directives/utils/right-btn.directive";

@Component({
  selector: 'action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit {

  @ContentChildren(RightBtnDirective) options: QueryList<RightBtnDirective> | undefined;



  get childrensLength(): number {
    return this.options ? this.options.length : 0;
  }

  @Input()
  type: String = '';

  @Input()
  rightBtnCount: number = 0;

  constructor() {}

  ngOnInit(): void {}

}
