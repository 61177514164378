import {Component, Inject, OnInit} from '@angular/core';
import {DamageService} from "../../../services/crud/damage.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {RootObject, ShipmentStop} from "../../../models/response/FromOtm";
import {map} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {EfficiencyProblem, ProblemDetail} from "../../../models/response";
import {SpinnerService} from "../../../services/spinner.service";
import {lastValueFrom} from "rxjs";

export interface FromOtmData {
  shipment: string;
  files?: File[];
  dmgType: string;
}

@Component({
  selector: 'from-otm',
  templateUrl: './from-otm.component.html',
  styleUrls: ['./from-otm.component.scss']
})
export class FromOtmComponent implements OnInit {

  otmDetail: OTMDetail | null = null;

  statusTitle = 'Getting data from OTM...'




  constructor(private damageService: DamageService, @Inject(MAT_DIALOG_DATA) public data: FromOtmData, public dialogRef: MatDialogRef<FromOtmComponent>,
              private router: Router, private spinner: SpinnerService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    lastValueFrom(this.damageService.getFromOtm(this.data.shipment))/*.pipe(
      map(res => {
        //(res)
        const shipmentOtm = res.BuyShipment.shipmentXid;
        const loadNum = res.BuyShipment.shipmentName;
        const carrier = res.BuyShipment.servprovGid;
        const plateNumber = res.BuyShipment.shipmentRefnum.filter(refNum => refNum.shipmentRefnumBeanData.shipmentRefnumQualGid == 'ELX.ASSET-ID').map(refNum => refNum.shipmentRefnumBeanData.shipmentRefnumValue).pop()
        const container = res.BuyShipment.shipmentRefnum.filter(refNum => refNum.shipmentRefnumBeanData.shipmentRefnumQualGid == 'ELX.EU-SH-CONTAINER').map(refNum => refNum.shipmentRefnumBeanData.shipmentRefnumValue).pop()
        const wagon = res.BuyShipment.shipmentRefnum.filter(refNum => refNum.shipmentRefnumBeanData.shipmentRefnumQualGid == 'ELX.DESPATCH-NUMBER').map(refNum => refNum.shipmentRefnumBeanData.shipmentRefnumValue).pop()
        const pickUpStops = res.BuyShipment.shipmentStop.filter(shipmentStop => shipmentStop.shipmentStopBeanData.stopType == 'P').map(shipmentStop => {
          return {
            id: shipmentStop.shipmentStopBeanData.locationGid,
            name: shipmentStop.shipmentStopBeanData.locationGid,
            data: shipmentStop
          }
        })
        const dropDownStops = res.BuyShipment.shipmentStop.filter(shipmentStop => shipmentStop.shipmentStopBeanData.stopType == 'D').map(shipmentStop => {
          return {
            id: shipmentStop.shipmentStopBeanData.locationGid,
            name: shipmentStop.shipmentStopBeanData.locationGid,
            data: shipmentStop
          }
        })
        const selectedPickUp = (res.BuyShipment.shipmentStop.filter(shipmentStop => shipmentStop.shipmentStopBeanData.stopType == 'P')).map(shipmentStop => {
          return {
            id: shipmentStop.shipmentStopBeanData.locationGid,
            name: shipmentStop.shipmentStopBeanData.locationGid,
            data: shipmentStop
          }
        }).pop()
        const selectedDropdown = (res.BuyShipment.shipmentStop.filter(shipmentStop => shipmentStop.shipmentStopBeanData.stopType == 'D')).map(shipmentStop => {
          return {
            id: shipmentStop.shipmentStopBeanData.locationGid,
            name: shipmentStop.shipmentStopBeanData.locationGid,
            data: shipmentStop
          }
        }).pop()
        return {
          shipmentOtm: shipmentOtm ?? '',
          loadNum: loadNum ?? '',
          carrier: carrier ?? '',
          plateNumber: plateNumber ?? '',
          container: container ?? '',
          wagon: wagon ?? '',
          pickUpStops: pickUpStops ?? [],
          dropDownStops: dropDownStops ?? [],
          selectedPickUp: selectedPickUp!,
          selectedDropdown: selectedDropdown!
        }
      })
    )*/.then(res => {
      this.otmDetail = res;
      this.otmDetail.fileToSend = this.data.files;
      ////(res)
    }).catch(err => {
      this.statusTitle = 'Some error occured on retriving data from OTM';
    }).finally(() =>{
    })
  }

  stopChange(event: any, type: string) {
    if(type=='pickup'){
      if(!!this.otmDetail){
        this.otmDetail.selectedPickUp = this.otmDetail?.pickUpStops.find(elem => elem.id == event)!
      }

    }
    if(type=='drop'){
      if(!!this.otmDetail){
        this.otmDetail.selectedDropdown = this.otmDetail?.dropDownStops.find(elem => elem.id == event)!
      }
    }
  }

  onNextClick() {

    let currentDate = this.otmDetail?.selectedPickUp.data.shipmentStopBeanData.appointmentPickup.date ? new Date(this.otmDetail?.selectedPickUp.data.shipmentStopBeanData.appointmentPickup.date) : new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate.getTime() - startDate.getTime()) /
      (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);



    this.dialogRef.close({
      DAMAGE_ID: '',
      DAMAGE_TYPE_ID: this.data.dmgType.toUpperCase(),
      SENDING_WH: this.otmDetail?.selectedPickUp.name.split('-')[0],
      RECEIVING_WH: this.otmDetail?.selectedDropdown.name.split('-')[0],
      SENDING_DATE: this.otmDetail?.selectedPickUp.data.shipmentStopBeanData.appointmentPickup.date,
      RECEIVING_DATE: this.otmDetail?.selectedDropdown.data.shipmentStopBeanData.appointmentDelivery.date,
      RECEIVING_WEEK: ''+weekNumber,
      DESPATCHED_QTY: 0,
      DAMAGED_APP_TOT_QTY: 0,
      DAMAGED_PKG_TOT_QTY: 0,
      STATUS: 'OPEN',
      INSERT_USER: '',
      INSERT_DATE: '',
      UPDATE_USER: '',
      UPDATE_DATE: '',
      SHIPMENT_ID: this.otmDetail?.loadNum,
      DELIVERY_TYPE_ID: '',
      DELIVERY_MODE_ID: '',
      CARRIER_ID: this.otmDetail?.carrier,
      WAGON: this.otmDetail?.wagon,
      TRUCK_PLATE: this.otmDetail?.plateNumber,
      OTM_SHIPMENT_ID: this.otmDetail?.shipmentOtm,
      CONTAINER: this.otmDetail?.container,
      TRAILER_PLATE: this.otmDetail?.plateNumber,
      REMARKS: '',
      L_EFFICIENCY_PROBLEMS: undefined,
      L_PROBLEMS: undefined
    })
  }
}

export interface OTMDetail {
  shipmentOtm: string;
  loadNum: string;
  carrier: string;
  plateNumber: string;
  container: string;
  wagon: string;
  pickUpStops: ShipmentStopToSelect[];
  dropDownStops: ShipmentStopToSelect[];
  selectedPickUp: ShipmentStopToSelect;
  selectedDropdown: ShipmentStopToSelect;
  fileToSend?: File[];
}

export interface ShipmentStopToSelect {
  id: string,
  name: string,
  data: ShipmentStop
}
