<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">

  <div *ngIf="titlePage.toLowerCase().trim() === 'warehouse'; else primaryView" class="warehouse">

    <h1 class="fs-2 fw-bold text-left">{{ titlePage | titlecase }}</h1>
    <div class="edit"></div>

  </div>
  <ng-template #primaryView>

    <div class="primary">

      <h1 class="fs-2 fw-bold text-left">{{ titlePage | titlecase }}</h1>
      <div class="edit"></div>
    </div>
  </ng-template>

  <h1 class="fs-2 fw-bold text-left">{{ "Remarks" | titlecase }}</h1>

  <div class="detail-content-row" style="margin-bottom: 18px;">
    <label class="fs-6 fw-bold filter-title">Scorecard: </label>
    <label class="fs-6 filter-title">{{ ' ' + scorecardId }}</label>
  </div>

  <div class="body-content w-100 p-4">
     <mat-form-field class="w-100" appearance="fill">
        <mat-label>Remarks</mat-label>
        <textarea id="remarks"  [(ngModel)]="value" matInput placeholder="Write here the remarks of scorecard" #remarksInput="ngModel" [maxlength]="255"></textarea>
      </mat-form-field>
  </div>


  <action-bar>
    <div class="col-btn" leftBtn>
      <custom-icon-btn (click)="onCancelClick()" color="accent" [iconClasses]="'arrow_back'"></custom-icon-btn>
    </div>

    <div rightBtn class="col-auto">
        <custom-icon-btn *ngIf="true" (click)="onSaveClick()" [btnClasses]="'fill-available'" color="accent" [iconClasses]="'save'"></custom-icon-btn>
    </div>
  </action-bar>
</ng-container>
