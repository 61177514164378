import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'haveAction',
})
export class HaveActionPipe implements PipeTransform {
  transform(value: string, list: any[] | null): Observable<boolean> {
    //////(list)
    if(!!list)
      return of(list.some((action) => action['id'] == value));

    return of(false)
  }
}
