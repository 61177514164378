import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, isDevMode, OnDestroy, ViewChild} from '@angular/core';
import {BehaviorSubject, filter, forkJoin, Observable, of, Subject, Subscription, takeUntil} from 'rxjs';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {InteractionStatus} from '@azure/msal-browser';
import {Clipboard} from '@angular/cdk/clipboard';
import {AuthService} from './services/auth.service';
import {NavService} from './services/nav.service';
import {NavItem} from './models/NavItem';
import {MediaMatcher} from '@angular/cdk/layout';
import {NavigationService} from "./services/utils/navigation.service";
import {TranslateService} from "@ngx-translate/core";
import {GeneralService} from "./services/crud/general.service";
import {CheckForUpdateService} from "./services/check-for-update.service";
import {AnnounceService} from './services/announcements.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslationService} from "./services/translation.service";
import {catchError} from "rxjs/operators";

export interface Announce {
  id: string
  priority: string
  text: string
  message_goes_on: string
  message_goes_off: string
  block_users_starts: string
}

export interface ExtendedAnnounce {
  id: string
  priority: string
  text: string
  message_goes_on: string
  message_goes_off: string
  block_users_starts: string
  interval: Subscription
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'DDT';

  private readonly _destroying$ = new Subject<void>();

  _ShowTableSubject = new BehaviorSubject<string>('');
  ShowTableSubject = this._ShowTableSubject.asObservable();

  isLoggedIn: boolean = false;
  email: string = this.msalService.instance.getActiveAccount()?.username || '';

  role: String = '';

  loading: boolean = false;

  appDrawer!: ElementRef;
  @ViewChild('appDrawer', { static: false }) set drawer(drawer: ElementRef) {
    if (drawer) {
      this.appDrawer = drawer;
    }
  }

  mobileQuery!: MediaQueryList;

  private _mobileQueryListener!: () => void;

  navItems: NavItem[] = [];

  allerts: ExtendedAnnounce[] = [];

  userDef: string = '';
  userOldDef: string = '';

  userContext: any[] = []

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private clipboard: Clipboard,
    private navService: NavService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private navigation: NavigationService,
    public translate: TranslateService,
    private httpService: GeneralService,
    private swUpdate: CheckForUpdateService,
    public allertService: AnnounceService,
	public spinner : NgxSpinnerService,
	private translationService: TranslationService
  ) {


    /*this.httpService.getAllertByValidity().subscribe(res => {
      //this.allert = res['oContent'];
      this.allertService.replaceAllert(res?.oContent?.map(announce => {
        const self = cloneDeep({ // Store a reference to the object
          id: announce.id,
          priority: announce.priority,
          text: announce.text,
          message_goes_on: announce.message_goes_on,
          message_goes_off: announce.message_goes_off,
          block_users_starts: announce.block_users_starts,
          interval: interval(5000).pipe(tap(temp => {
            if (new Date(self.block_users_starts) <= new Date() && new Date(self.message_goes_off) > new Date()) {
              self.priority = 'custom'; // Access object's properties using sel
              self.interval.unsubscribe();
              this.pagesInit();
              this.navigation.home()
            } else if (new Date(announce.message_goes_off) <= new Date()) {
              self.interval.unsubscribe();
              this.pagesInit();
              this.navigation.home();
            }
          })).subscribe()
        });
        return self;
      }) ?? []);

    });*/

    this.swUpdate.checkVersion();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  userContextChange(event: string) {
    if (this.userDef) {
      this.httpService.setUserContext(this.userDef, this.userOldDef).subscribe(res => {
        localStorage.removeItem('layout')
        localStorage.removeItem('filter')
        Object.keys(sessionStorage).forEach(function (key) {
          if (!key.startsWith("sort")) {
            sessionStorage.removeItem(key)
          }
        })
        window.location.reload();
      })

    }

  }


  ngOnInit(): void {

	  const defualtLanguage = "en";
	  //The follwings localStorage element are setted when the user change language
	  let selectedLanguage:string = localStorage.getItem('selectedLanguage')?? defualtLanguage; //Id of the language

	  this.translate.addLangs([selectedLanguage]);
	  this.translate.defaultLang = defualtLanguage;
	  this.translate.currentLang = selectedLanguage;

	  const getTranslationsForDefault: Observable<any> = this.translate.getTranslation(defualtLanguage);
	  const setCurrentLanguageAndGetTranslation = this.translate.getTranslation(selectedLanguage);
	  const getAvailableLanguages =  this.translationService.getAvailableLanguages();

	  forkJoin({
		  defaultTranslation: getTranslationsForDefault,
		  currentTranslation: setCurrentLanguageAndGetTranslation,
		  availableLanguages: getAvailableLanguages})
	  .pipe(catchError((error: any, caught: any) =>
		  {
			  console.error("Error loading translation information: ", error);
			  return of(error);
		  }
	  ))
	  .subscribe(res => {
		  this.translate.setTranslation(selectedLanguage, res.currentTranslation); //Current language
		  this.translate.setTranslation(defualtLanguage, res.defaultTranslation); //Default language
		  this.translate.addLangs(res.availableLanguages ?? ['en']); //Add the list of available langauges
	  });

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((res) => {
        //////(this.msalService.instance)
        this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
        this.authService._isLoggedIn.next(this.msalService.instance.getAllAccounts().length > 0);
        //////(this.msalService.instance.getAllAccounts());
        if (this.isLoggedIn) {
          this.msalService.instance.setActiveAccount(
            this.msalService.instance.getAllAccounts()[0]
          );

          this.authService.nameBS.next(
            this.msalService.instance.getActiveAccount()?.name || ''
          );

          this.authService.emailBS.next(
            this.msalService.instance.getActiveAccount()?.username || ''
          );

		  this.loading = true;
		  this.spinner.show("initLoad");
		  forkJoin(
			{
				user: this.authService.getUser(),
				menu: this.authService.getMenu(),
				pages: this.authService.getPages(),
				languages: this.translationService.getAvailableLanguages()
			}).subscribe({
			  next:({user, menu, pages, languages}) => {
				  if (!!(user['oContent'])['sFilterSettings'])
				  {
					  const filter = JSON.parse((user['oContent'])['sFilterSettings'])
					  localStorage.setItem('filter', filter['filters'])
				  }

				  if (!!menu)
				  {
					  this.navItems = menu['oContent']['userMenus'];

					  this.authService.roleBS.next(
						  menu['oContent']['userRoles'][0]['_sRoleId']
					  );
				  }

				  this.navigation.setNavigationItems((pages['oContent'])['pages']);

				  /**
				  const languagesList = languages.map(lan => lan.id);
				  this.translate.addLangs(languagesList ?? ['en']);
				  this.translate.setDefaultLang(languagesList[languagesList.indexOf(localStorage.getItem('lang'))] ?? 'en');
				  this.translate.use(languagesList[languagesList.indexOf(localStorage.getItem('lang'))] ?? 'en');
					  */

				  this.spinner.hide("initLoad");
				  this.loading = false;

			  },
			  error: (err)=>{
				  console.error("Ups, that's an error:", err);
				  this.spinner.hide("initLoad");
				  this.loading = false;
			  }
			})

        }
      });
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  ngOnDestroy(): void {
    localStorage.clear();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getToken(): string {
    return String(localStorage.getItem('token'));
  }

  copyToken(): void {
    this.ShowTableSubject.subscribe((token) => {
      this.clipboard.copy(token);
    });
  }


  logout() {
    this.msalService.instance.logout();
  }

  isDevMode() {
    return isDevMode();
  }

}
