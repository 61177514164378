import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { WarehouseService } from 'src/app/services/crud/warehouse.service';
import { TableControllerService } from 'src/app/services/table-controller.service';
import { keyExistsIn, refactorName } from 'src/app/utils/generic';
import * as _ from 'lodash';
import { PageEventType } from '../custom-table-paginator/custom-table-paginator.component';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import { LaneSelectionComponent } from '../../pages/lane-selection/lane-selection.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  @Input()
  pageEvent: PageEventType = {
    pageIndex: 0,
    previousPageIndex: 0,
    loader: 50,
    size: 50,
  };

  @Input()
  length: number = 0;

  @Input()
  numElementsLoaded: number = 0;

  @ViewChildren('checkboxes')
  checkboxes: any;

  itemsArray: any[] = [];

  @Input()
  datasets;

  @Input()
  actionsets;

  pageable: any;

  @Output()
  onActionEmit: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onRowClick: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  tableController!: TableControllerService;

  @Input()
  disabledColumns: string[] = [];

  @Input()
  defaultCheckedValue: string[] = [];

  @Input()
  disabledActions: { id: string, motivation: string }[] = [];

  sortedColumns: Map<string, { order: number; type: 'asc' | 'desc' }> =
    new Map();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getDataset();
    //console.log(this.datasets)
  }

  ngOnAfterViewInit() {}

  getDataset() {}

  onCheckboxClick() {
    /*this.tableController.selectedRow = this.checkboxes
      .toArray()
      .filter((elementRef) => elementRef.nativeElement.checked)
      .map((elementRef) => elementRef.nativeElement.value);

    ////(this.tableController.selectedRow);*/
  }

  editRow(index: number) {
    this.tableController.editRow(index);
  }

  editRowId(index: number, action: string) {
    this.tableController.commitEditDetail(index);

    this.actionEmitter(action + '_ID');
  }

  exitRow(index: number) {
    this.tableController.exitRow(index);
  }

  commitEdit(index: number, action: string) {
    if (!this.tableController.dataSet[index].newRow) {
      this.tableController.commitEdit(index);
      this.datasets.datas.forEach((column) => {
        if (
          this.tableController.dataSet[index][column.columnName] !=
          this.tableController.dataSet[index].backup[column.columnName]
        ) {
          this.tableController.dataSet[index]['edited'] = true;
        }
      });
    }
    this.actionEmitter(action);
  }

  keyExistsIn(obj, key) {
    return keyExistsIn(obj, key);
  }

  onInputChange(index: number) {
    ////(this.tableController.dataSet[index]);
    this.tableController.dataSet[index].edited = true;
  }

  onAddClick() {
    ////(this.datasets)
    let newRowObject = {};

    if (
      !!this.tableController.dataSet &&
      this.tableController.dataSet.length > 0 &&
      !!this.tableController.dataSet[0] &&
      Object.keys(this.tableController.dataSet[0]).length > 0
    ) {
      newRowObject = _.cloneDeep(this.tableController.dataSet[0]);
      Object.keys(newRowObject).forEach((key) => {
        if((this.defaultCheckedValue.indexOf(key) > -1)){
          newRowObject[key] = true;
        }else{
          newRowObject[key] = '';
        }

      });
    } else {
      newRowObject = {};
      this.datasets.datas.forEach((column) => {
        if((this.defaultCheckedValue.indexOf(refactorName(column.columnName)) > -1)){
          newRowObject[refactorName(column.columnName)] = true;
        }else{
          newRowObject[refactorName(column.columnName)] = '';
        }
      });
    }

    this.tableController.dataSet = this.tableController.onAddClick(
      _.cloneDeep(newRowObject)
    );

    this.onRowClick.emit(-1);

  }

  deleteRow(index: number) {
    this.tableController.dataSet = this.tableController.deleteRow(index);

    this.actionEmitter('ACT_DELETE_ID');
  }

  saveRow(index: number, isEditing: Boolean) {
    if (isEditing) {
      this.tableController.commitEdit(index);
    } else {
      this.tableController.dataSet = this.tableController.saveRow(index);
    }
  }

  onCancelClick() {
    this.getDataset();
  }

  onPageChange(pageEvent: any) {
    this.tableController.onPaginationChange.emit(pageEvent);
  }

  actionEmitter(action: string, index?: number) {
    if (action.endsWith('_ALL')) {
      this.tableController.commitEditDetail(0);
    }
    this.onActionEmit.emit(action);
  }

  convertValue(column: any, dataSetElementElement: any, row: number) {
    return (
      column.optionValues.filter(
        (option) => option.id == dataSetElementElement
      )[0]?.name ?? this.tableController.dataSet[row][column.columnName]
    );
  }

  onRadioButtonClick(column: any, i: number) {
    this.tableController.dataSet[i][column.columnName] =
      !this.tableController.dataSet[i][column.columnName];
    column.radioGroup?.forEach((columnName) => {
      this.tableController.dataSet[i][columnName] =
        !this.tableController.dataSet[i][column.columnName];
    });
  }

  sortColumn(name: string) {
    let poolMap: Map<string, { order: number; type: 'asc' | 'desc' }> =
      new Map();
    this.sortedColumns.forEach((value, key, map) => {
      poolMap.set(key, value);
    });
    if (
      !!this.sortedColumns &&
      !!this.sortedColumns.get(name) &&
      (this.sortedColumns.get(name)?.type == 'asc' ||
        this.sortedColumns.get(name)?.type == 'desc')
    ) {
      poolMap.delete(name);
      if (this.sortedColumns.get(name)?.type == 'asc') {
        const order = this.sortedColumns.get(name)?.order ?? 0;
        this.sortedColumns = new Map();
        this.sortedColumns.set(name, { order: order, type: 'desc' });
        poolMap.forEach((value, key, map) => {
          this.sortedColumns.set(key, value);
        });
      } else {
        const order = this.sortedColumns.get(name)?.order ?? 0;
        this.sortedColumns.delete(name);
        this.sortedColumns.forEach((value, key, map) => {
          this.sortedColumns.set(key, {
            order: value.order > order ? value.order - 1 : value.order,
            type: value.type,
          });
        });
      }
    } else {
      if (!!!this.sortedColumns.get(name)) {
        let maxIndex = 0;
        this.sortedColumns.forEach((value, key, map) => {
          maxIndex = maxIndex < value.order ? value.order : maxIndex;
        });
        this.sortedColumns.set(name, { order: maxIndex + 1, type: 'asc' });
      }
    }
    this.tableController.onSortChange.emit({
      sorting: this.sortedColumns,
      pageEvent: this.pageEvent,
    });
  }
}
