<div class="dialog-scorecard">
  <h1 mat-dialog-title>User Info Menu</h1>
  <div mat-dialog-content class="account-detail-container">
    <div class="account-detail no-selectable-item" *ngIf="email !== null" (click)="$event.stopPropagation()">
      <label class="fw-bold">Name: </label>
      <span (longPress)="copy(tooltipName, name ?? '')" #tooltipName="matTooltip"
            matTooltip="Username copied">{{name}}</span>
    </div>
    <div class="account-detail no-selectable-item" *ngIf="email !== null" (click)="$event.stopPropagation()">
      <label class="fw-bold">Email: </label>
      <span (longPress)="copy(tooltipEmail, email)" #tooltipEmail="matTooltip"
            matTooltip="Email copied">{{email}}</span>
    </div>
    <div class="account-detail no-selectable-item" *ngIf="role !== null" (click)="$event.stopPropagation()">
      <label class="fw-bold">Role: </label>
      <span (longPress)="copy(tooltipRole, role ?? '')" #tooltipRole="matTooltip"
            matTooltip="Role copied">{{role}}</span>
    </div>

 

  <div class="account-detail no-selectable-item" *ngIf="role !== null" (click)="$event.stopPropagation()">
      <label class="fw-bold" >User Context: </label>
      <div  style="margin-top: 10px; display: flex; flex-direction: row;"> 
      <custom-select
      [(defaultValue)]="userDef"
      [values]="userContext" 
      (columnChange)="userContextChange($event)">
    </custom-select>
  </div>
    </div> 

    <div class="account-detail no-selectable-item" *ngIf="role !== null" (click)="$event.stopPropagation()">
      <label class="fw-bold">Language: </label>
      <span [matMenuTriggerFor]="language" class="menu-language-item-selected "><img src="{{'assets/flags/4x3/'+(!!translate.currentLang ? translate.currentLang : 'gb')+'.svg'}}" width="40" height="30" alt="{{ (!!translate.currentLang ? translate.currentLang : 'gb') | translate }}" />
      </span>
      <mat-menu #language="matMenu" >
    <span mat-menu-item *ngFor="let language of translate.getLangs()" (click)="switchLang(language)" class="menu-language-item">
      <img src="{{'assets/flags/4x3/'+language+'.svg'}}" width="40" height="30" alt="{{ language | translate }}" /> <label class="language-label">{{ (language ?? 'gb') | translate }}</label>
    </span>
      </mat-menu>
    </div>

    <!--<div class="account-detail">
      <label class="email-title">User Role: </label>
      <label class="record-label-content">{{role}}</label>
    </div>-->
    <div class="account-detail" *ngIf="email === null">
      <label>You must be logged in</label>
    </div>
  </div>
  <action-bar>
    <custom-icon-btn backNavigation leftBtn [masterFrom]="null" [compareTo]="null" color="accent" [iconClasses]="'arrow_back'"></custom-icon-btn>

    <ng-container rightBtn *ngIf="email!== null; else loginBtn">
      <div>
        <custom-btn color="accent" [btnClasses]="'fill-available'" (click)="logout()" >Logout</custom-btn>
      </div>
    </ng-container>
    <ng-container rightBtn *ngIf="email!== null">
      <div>
        <custom-btn color="accent" [btnClasses]="'fill-available'" (click)="info()" >Info</custom-btn>
      </div>
    </ng-container>
    <ng-container rightBtn *ngIf="email!== null">
      <div>
        <custom-btn color="accent" [btnClasses]="'fill-available'" (click)="copyToken()" >Copy JWT</custom-btn>
      </div>
    </ng-container>
    <ng-template #loginBtn>
      <div rightBtn class="col-auto">
        <custom-btn rightBtn color="accent" (click)="login()" >Login</custom-btn>
      </div>

    </ng-template>


  </action-bar>
</div>




