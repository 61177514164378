<h1 class="fs-1 fw-bold text-left mt-3">{{'Documents'}}</h1>
<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">
  <div class="mt-lg-5 w-100" *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
    <!-- && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas -->
    <div *ngIf="sectionLayout['id'] == 'LYO_DOC_LIST' " class="detail-container p-3 mb-5 w-100">
      <ng-container
        *ngIf="!!sectionLayout.actionset[0] &&  ('ACT_DOC_NEW' | haveAction : sectionLayout.actionset[0].actions | async)">
        <div class="row align-items-center" *ngIf="isNewSection">
          <div class="col-4">
            <div class="row align-items-center">
              <div class="col-3"><label class="fs-6 fw-bold filter-title">{{'Section name'}}</label></div>
              <div class="col-9">
                <custom-text-field [appearance]="'fill'" [placeholder]="'Section Name'" [(value)]="newSection.SECTION">
                </custom-text-field>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row align-items-center">
              <div class="col-2">
                <custom-btn color="accent" (click)="onAddSection(true)">Confirm</custom-btn>
              </div>
              <div class="col-2">
                <custom-btn (click)="onAddSection(false)">Cancel</custom-btn>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>
        <div class="row" *ngIf="!isNewSection">
          <div class="col-3">
            <custom-btn color="accent" (click)="onAddSection()">Add Section</custom-btn>
          </div>
          <div class="col-9"></div>
        </div>
      </ng-container>


      <ng-container *ngFor="let archive of archives">
        <div class="row align-items-center mt-4 w-100 mb-2">
          <div class="col-1"
            *ngIf="!!sectionLayout.actionset[0] &&  (('ACT_DOC_NEW' | haveAction : sectionLayout.actionset[0].actions | async) || ('ACT_DOC_UPLOAD' | haveAction : sectionLayout.actionset[0].actions | async) || ('ACT_DOC_DELETE' | haveAction : sectionLayout.actionset[0].actions | async))">
            <div class="row align-items-center">
              <div ngbDropdown>
                <custom-btn id="dropdownBasic1" color="primary" ngbDropdownToggle>
                  Actions
                </custom-btn>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="deleteArchive(archive.DOCUMENTS_ID)"
                    *ngIf="('ACT_DOC_DELETE' | haveAction : sectionLayout.actionset[0].actions | async)">Delete</button>
                  <button ngbDropdownItem (click)="renameSection(archive)"
                    *ngIf="('ACT_DOC_NEW' | haveAction : sectionLayout.actionset[0].actions | async)">Rename</button>
                  <button ngbDropdownItem (click)="uploadDoc(archive.DOCUMENTS_ID)"
                    *ngIf="('ACT_DOC_UPLOAD' | haveAction : sectionLayout.actionset[0].actions | async)">Upload</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="row align-items-center">
              <ng-container *ngIf="!!section && section.DOCUMENTS_ID == archive.DOCUMENTS_ID else default">
                <div class="col-4">
                  <custom-text-field [appearance]="'fill'" [placeholder]="'Section Name'" [(value)]="section.SECTION">
                  </custom-text-field>
                </div>
                <div class="col-8">
                  <div class="row align-items-center">
                    <div class="col-2">
                      <custom-btn color="accent" (click)="saveSection(section)">Confirm</custom-btn>
                    </div>
                    <div class="col-2">
                      <custom-btn (click)="renameSection(null)">Cancel</custom-btn>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #default>
                <label class="fs-4 fw-bold section-title">{{ archive.SECTION }}</label>
              </ng-template>
            </div>
          </div>
          <div class="col-auto"></div>
        </div>
        <div class="row pt-1 pb-1 w-100 pl-5 rounded align-items-center document-row"
          *ngFor="let document of archive['L_DOCUMENT']">
          <div class="col-2">
            <div class="row align-items-center">
              <ng-container
                *ngIf="!!sectionLayout.actionset[0] && ('ACT_DOC_DELETE' | haveAction : sectionLayout.actionset[0].actions | async)">
                <div class="col-2">
                  <div class="row">
                    <custom-btn [iconButton]="true" [iconClasses]="'delete'"
                      (click)="deleteDoc(archive.DOCUMENTS_ID, document.DOCUMENT_ID)">
                    </custom-btn>
                  </div>
                </div>
              </ng-container>

              <div class="col-2">
                <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'"
                  (click)="downloadDoc(archive.DOCUMENTS_ID, document.DOCUMENT_ID, document.FILE_TYPE, document.FILE_NAME)">
                </custom-btn>
              </div>
            </div>
          </div>
          <div class="col-5">
            <label class="fs-6 fw-normal section-title">{{ document.FILE_NAME }}</label>
          </div>
          <div class="col-auto"></div>

        </div>
      </ng-container>
      <!--<ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
        <ng-container
          *ngIf="data.columnName != 'SHIPMENT_ID' && data.columnName != 'DELIVERY_TYPE_ID' && data.columnName != 'DELIVERY_MODE_ID' && data.columnName != 'CARRIER_ID' && data.columnName != 'WAGON' && data.columnName != 'TRUCK_PLATE' && data.columnName != 'OTM_SHIPMENT_ID' && data.columnName != 'CONTAINER' && data.columnName != 'TRAILER_PLATE' && data.columnName != 'REMARKS'">
          <ng-container *ngIf="data.type != 'DTY_LIST' else listBlock">
            <ng-container *ngIf="data.type == 'DTY_DATE' else listBlock">
              <div class="detail-content">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel}}</label>
                <custom-datepicker *ngIf="data.type == 'DTY_DATE'" [disabled]="!data.editable"
                                   [(value)]="detail[data.columnName]">
                </custom-datepicker>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #listBlock>
            <ng-container *ngIf="data.type == 'DTY_LIST' && !!data.optionValues else defaultBlock">

              <div class="detail-content ">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel}}</label>

                <div class="select-wrapper">
                  <custom-select [values]="data.optionValues" [disabled]="!data.editable"
                                 [(defaultValue)]="detail[data.columnName]" [extraClass]="'likeText'">
                  </custom-select>
                </div>

              </div>
            </ng-container>
            <ng-template #defaultBlock>
              <div class="detail-content">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel}}</label>
                <custom-text-field [appearance]="'fill'" [placeholder]="data.columnLabel" [disabled]="!data.editable"
                                   [(value)]="detail[data.columnName]"></custom-text-field>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </ng-container>-->
    </div>
  </div>
</ng-container>
