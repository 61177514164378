<h2 mat-dialog-title>{{ data['title'] }}</h2>
<mat-dialog-content class="mat-typography">

  <div *ngIf="pictures.length > 0 else noData">
    <!-- heading text -->
    <ul class="image-gallery">
      <li *ngFor="let picture of pictures">
        <img [src]="picture.pictureUrl" [alt]="picture.pictureName" />
        <div class="overlay">
          <span class="fs-8">{{picture.pictureName}}</span>
          <div class="row-btn-container">
            <custom-btn [iconButton]="true" [iconClasses]="'delete'" [fontSize]="'16px'"
                        (click)="deletePicture(picture.pictureId)">
            </custom-btn>
            <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'" [fontSize]="'16px'"
                        (click)="downloadPicture(picture.pictureId, picture.pictureName)">
            </custom-btn>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <ng-template #noData>
    <div class="container">
      <h3 class="fs-6 fw-bold text-center align-items-center justify-content-center">{{ data['content'].length == pictures.length ? 'No picture found' : 'Getting pictures...' }}</h3>
    </div>
  </ng-template>





  <!--<div class="images-area">
    <div class="image-container" *ngFor="let picture of pictures">
      <img class="image-content" [src]="picture.pictureUrl" [alt]="picture.pictureName">
      <div class="row-btn-container">
        <custom-btn [iconButton]="true" [iconClasses]="'delete'" [fontSize]="'16px'"
                    (click)="deleteDoc(picture.pictureId)">
        </custom-btn>
        <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'" [fontSize]="'16px'"
                    (click)="downloadDoc(picture.pictureId, picture.pictureName)">
        </custom-btn>
      </div>
    </div>
  </div>-->
</mat-dialog-content>
<div class="buttons-container" mat-dialog-actions>
  <input #imageInput
         type="file"
         accept="image/*"
         (change)="processFile(imageInput)" style="display: none">
  <custom-icon-btn [btnClasses]="'fill-available'" color="accent" [iconClasses]="'add_a_photo'" (click)="imageInput.click()"></custom-icon-btn>
  
  <!--<custom-icon-btn [btnClasses]="'fill-available'" color="accent" [iconClasses]="'close'" [mat-dialog-close]="{send: false}"></custom-icon-btn>-->
<custom-icon-btn [mat-dialog-close]="{send: false}" color="accent" [iconClasses]="'save'" (click)="update()"></custom-icon-btn>
</div>
