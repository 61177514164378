import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss']
})
export class EmailPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
