import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  errorMessage: string = '';

  constructor(private route: ActivatedRoute) {
    this.route.queryParamMap.subscribe( params => {
      this.errorMessage = params.get('message') ?? '';
    });
  }

  ngOnInit(): void {

  }

  onCopyClick() {
    navigator.clipboard.writeText(this.errorMessage);
  }
}
