import {
  Component,
  EventEmitter,
  Input,
  isDevMode,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { EMPTY, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Clipboard } from '@angular/cdk/clipboard';
import {GeneralService} from "../../../services/crud/general.service";
import {MatDialog} from "@angular/material/dialog";
import {version} from '../../../version'
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Input()
  pageTitle = '';
  @Input()
  version = '';
  @Input()
  actions: string[] = [];

  @ViewChild('account') public account!: MatMenu;

  @Output() onClickLogin: EventEmitter<any> = new EventEmitter();
  @Output() onClickInfo: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogout: EventEmitter<any> = new EventEmitter();
  @Output() onClickLogo: EventEmitter<any> = new EventEmitter();
  @Output() toggleSidenav = new EventEmitter<void>();

  name: string | undefined;
  email: string | undefined;
  role: string | undefined;

  appVersion: string = '';
  beVersion: string = '';

  usr!: string;

  constructor(
    protected router: Router,
    private msalService: MsalService,
    private authService: AuthService,
    private clipboard: Clipboard,
    private generalService: GeneralService,
    private dialog: MatDialog
  ) {}

  public errorHandler = (error: any) => {
    return EMPTY;
  };

  ngOnInit(): void {
    this.authService.emailObs.subscribe((email) => {
      this.email = email;
    });
    this.authService.nameObs.subscribe((name) => {
      this.name = name;
    });
    this.authService.roleObs.subscribe((role) => {
      this.role = role;
    });
  }

  isDevMode() {
    return isDevMode();
  }

  login() {
    this.onClickLogin.emit();
  }

  userInfo() {
    this.onClickInfo.emit();
  }

  logout() {
    this.onClickLogout.emit();
  }

  onLogoClick() {
    this.onClickLogo.emit();
  }

  isActionPresent(action: string) {
    if (this.actions.indexOf(action) > -1) return true;
    else return false;
  }

  arrayFrom(string: string) {
    return Array.from(string)['0'] + Array.from(string)['1'];
  }

  onMenuClick() {
    this.toggleSidenav.emit();
  }

  copyToken(): void {
    lastValueFrom(
      this.msalService.acquireTokenSilent({
        scopes: ['openid', 'profile', 'User.Read', 'email'],
      })
    ).then((token) => {
      this.clipboard.copy(token.idToken);
    });
  }

  info() {
    this.generalService.getVersion().subscribe(res => {
      this.beVersion = res['oContent'] ?? '';
      this.appVersion = version;
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'DDT Application Information',
          content: `Web application version: ` + this.appVersion,
          content2: `Server application version: ` + this.beVersion,
          confirmButtonLabel: 'Refresh layout & Close',
          confirmButtonColor: 'basic',
          cancelButtonLabel: 'Close',
          cancelButtonColor: 'accent'
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res && res == true) {
          localStorage.removeItem('layout');
          window.location.reload();
        }
      });
    })
  }
}
