<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">




  <div *ngIf="titlePage.toLowerCase().trim() === 'warehouse'; else primaryView" class="warehouse">

    <h1 class="fs-2 fw-bold text-left">{{ titlePage | titlecase }}</h1>
    <div class="edit"></div>

  </div>
  <ng-template #primaryView>

    <div class="primary">

      <h1 class="fs-2 fw-bold text-left">{{ titlePage | titlecase }}</h1>
      <div class="edit"></div>
    </div>
  </ng-template>

 <!-- <div class="edit"> -->





  <div class="detail-content-row" style="margin-bottom: 18px;">
    <label class="fs-6 fw-bold filter-title">Scorecard: </label>
    <label class="fs-6 filter-title">{{' ' + detail['SCORECARD_ID']}}</label>
  </div>

  <div class="body-content">

    <ng-container *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">

      <div
        *ngIf="sectionLayout['id'] == 'LYO_SEC_SCORECARD_DETAIL' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas && !!detail"
        class="detail-container" >

        <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
          <ng-container
            *ngIf="data.columnName == 'SCORECARD_APPLIANCES_QTY' || data.columnName == 'SCORECARD_PACKAGES_QTY' ||( data.columnName == 'SHIPMENT_ID' && apiParent!='warehousing')" >
            <div class="detail-content-row">

              <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}:</label>
              <label class="fs-6 filter-title">{{''+detail[data.columnName]}}</label>
            </div>
          </ng-container>
        </ng-container>
      </div>


      <div
        *ngIf="!!sectionLayout['id'] &&  sectionLayout['id'] == 'LYO_SEC_SCORECARD_PROBLEM'  && !!detail && detail.L_PROBLEMS && detail.L_PROBLEMS.length > 0"
        id="scorecard_problem">
        <div class="scrolling-wrapper-flexbox"
          *ngIf="sectionLayout['id'] == 'LYO_SEC_SCORECARD_PROBLEM' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
          (contextmenu)="foo($event)">
          <span class="card no-selectable-item" id="{{'scorecard_'+i}}" (contextmenu)="foo($event)"
            *ngFor="let data of problemsTableController.dataSet; let i= index" (click)="selectProblem(data, i)"
            (longPress)="onLongPressClick(data, i, sectionLayout.actionset[0].actions)"
            [ngClass]="{'selected-scorecard': problemsTableController.rowNumber == i}">
            <ng-container *ngFor="let column of (sectionLayout['dataset'])[0].datas; let j= index">
              <div class="card-content card-sticky-title" *ngIf="column.columnName == 'SCORECARD_PROBLEM_ID'"><label
                  class="fw-bold">{{'Problem' | translate}}: {{data[column.columnName]}}</label></div>
              <div class="card-content" *ngIf="column.columnName != 'SCORECARD_PROBLEM_ID'"><label
                  style="max-width: 15rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;height: 1.1rem;">{{column.columnLabel
                  | translate}}
                  :
                  <ng-container *ngIf="column.type == 'DTY_LIST'; else noList" [matTooltip]="(column | getListTooltip: problemsTableController.dataSet[i][column.columnName] :
  problemsTableController.dataSet[i][column.columnName] : i)">
    {{ (column | getListTooltip: problemsTableController.dataSet[i][column.columnName] :
    problemsTableController.dataSet[i][column.columnName] : i) }}
</ng-container>
<ng-template #noList>
    {{ data[column.columnName] }}
</ng-template>

                </label></div>
            </ng-container>
            <div class="card-index fw-bold"><label>{{i + 1}}
                / {{problemsTableController.dataSet.length}}</label></div>
          </span>
        </div>
      </div>


    </ng-container>
  </div>
  <div class="actions-content">
    <ng-container class="mt-lg-5" *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
      <ng-container *ngIf="sectionLayout['id'] == 'LYO_SEC_SCORECARD_DETAIL' && !!detail">
        <action-bar>
          <div class="col-btn" leftBtn>
            <custom-icon-btn [compareTo]="detail" [masterFrom]="detailBck" backNavigation color="accent"
              [iconClasses]="'arrow_back'" [overriderPath]="(nuovo==='true') ? '/' :(this.titlePage.trim()=='Primary' ? 'page/primary/scorecards': (('page/warehousing/scorecards') ) ) " ></custom-icon-btn>


          </div>

          <ng-container *ngFor="let action of sectionLayout.actionset[0].actions" centerBtn>
            <div class="col-btn" *ngIf="action.id == 'ACT_ADD'">
              <custom-icon-btn (click)="goToProblem()" [disabled]="!!detail && (detail.STATUS == 'CANCELED')"
                [btnClasses]="'fill-available'" color="accent" [iconClasses]="action.icon"></custom-icon-btn>
            </div>
            <div class="col-btn" *ngIf="action.id == 'ACT_SAVE'">
              <custom-icon-btn (click)="onSaveClick($event, true)"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')" [btnClasses]="'fill-available'" color="accent"
                [iconClasses]="'save'" backNavigation  [overriderPath]="(nuovo==='true') ? '/' :(this.titlePage.trim()=='Primary' ? 'page/primary/scorecards': (('page/warehousing/scorecards') ) ) "  ></custom-icon-btn>
            </div>
          </ng-container>


          <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0]?.actions">
            <div class="col-auto" *ngIf="action.id == 'ACT_WITHDRAW'">
              <custom-btn color="accent" (click)="onChangeStatusClick('W')"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Cancel Claim
              </custom-btn>
            </div>
            <div class="col-auto" *ngIf="action.id == 'ACT_REOPEN'">
              <custom-btn color="accent" (click)="onChangeStatusClick('D')"
                [disabled]="!!detail && !(detail.STATUS == 'CANCELED')">Delete
              </custom-btn>
            </div>
          </ng-container>
          <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0]?.actions">
            <div class="col-auto" *ngIf="action.id == 'ACT_SAVE_SEND'">
              <custom-btn color="accent" (click)="onSaveAndActionClick('S')"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Save & Send
              </custom-btn>
            </div>
            <div class="col-auto" *ngIf="action.id == 'ACT_SAVE_PREVIEW'">
              <custom-btn color="accent" (click)="onSaveAndActionClick('P')"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Save & Preview
              </custom-btn>
            </div>
          </ng-container>

          <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0]?.actions">
            <div class="col-auto" *ngIf="action.id == 'ACT_EDIT_PROBLEM'">
              <custom-btn color="accent" (click)="openEfficiencyProblem()"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Eff. Problem
              </custom-btn>
            </div>
            <div class="col-auto" *ngIf="action.id == 'ACT_EDIT'">
              <custom-btn color="accent" (click)="actionCatcher(action.id + '_ID')"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Edit
              </custom-btn>
            </div>
            <div class="col-auto" *ngIf="action.id == 'ACT_DELETE'">
              <custom-btn color="accent" (click)="actionCatcher(action.id + '_ID')"
                [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Delete
              </custom-btn>
            </div>

            <div class="col-auto" *ngIf="action.id == 'ACT_EDIT'">
              <custom-btn color="accent" (click)="preview()">Attachments
              </custom-btn>
            </div>
            <div class="col-auto" *ngIf="action.id == 'ACT_REMARKS'">
              <custom-btn color="accent" (click)="actionCatcher(action.id + '_ID')">
                Remarks
              </custom-btn>
            </div>


          </ng-container>





        </action-bar>

      </ng-container>
    </ng-container>
  </div>
<!--</div>-->

</ng-container>
