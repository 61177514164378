import {Injectable} from "@angular/core";
import {Alert} from "./alert.interface";
import {ALERT_TYPE} from "./alert.enumerate";


@Injectable({
  providedIn: 'root',
})
export class AlertService{

  lAlerts: Alert[] = [];

  constructor() {
    this.lAlerts = [];
  }

  close(alert: Alert) {
    this.lAlerts.splice(this.lAlerts.indexOf(alert), 1);
  }

  closeTimeout(alert: Alert){
    if(alert.type !== ALERT_TYPE.DANGER){
      setTimeout(() => this.close(alert), alert.timeout);
    }
  }

  reset() {
    this.lAlerts = [];
  }

  add(alert: Alert){
    alert.selfClose = this.closeTimeout(alert)
    this.lAlerts.push(alert)
  }


  closeAll() {

  }
}
