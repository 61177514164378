

<div class="form-group custom-select-container">
  <label class="fs-6 fw-bold filter-title" *ngIf="!!title">{{title}}</label>
  <table class="table table-striped ">
    <thead>
    <tr>

      <th>
        <span></span>
      </th>

      <th >
        <span></span>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let option of options; let i=index">
      <td>
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? select(option['ID']) : null"
                      [checked]="option['SELECTED']"
                      [aria-label]="option['SELECTED']" [disabled]="disable">
        </mat-checkbox>
      </td>
      <td>
        <label class="fs-7">{{option['LABEL']}}</label>
      </td>
    </tr>
    </tbody>
  </table>
</div>
