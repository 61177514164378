<!-- <h1 class="fs-2 fw-bold text-left">{{ titlePage +'Scorecard: '}}<span class="fw-normal">{{detail['SCORECARD_ID']}}</span></h1>-->
<!--<h1 class="fs-2 fw-bold text-left">{{ titlePage }}</h1> -->


<div *ngIf="titlePage.toLowerCase().trim() === 'warehouse'; else primaryView" class="warehouse">
  <h1 class="fs-2 fw-bold text-left">{{ titlePage }} </h1>
  <div class="edit"></div>
</div>

<ng-template #primaryView>
  <div class="primary">
    <h1 class="fs-2 fw-bold text-left">{{ titlePage }} </h1>
    <div class="edit"></div>
  </div>
</ng-template>

<!--
<div class="edit"> -->

<div class="detail-content-row">
<label class="fs-6 fw-bold filter-title">Scorecard:</label>
<label class="fs-6 filter-title">{{' ' + detail['SCORECARD_ID']}}</label>
</div>

<div *ngIf="!!layout && !!layout['sectionLayouts']" class="body-content" >
  <ng-container class="mt-lg-1 " *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
    <div
         *ngIf="sectionLayout['id'] == 'LYO_SEC_PROBLEM_DT_DETAIL' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas && !!detail"
         class="detail-container" id="problem-info">
      <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
        <ng-container *ngIf="(data.columnName != 'SCORECARD_ID' && data.columnName != 'REASON_ID' && data.columnName != 'REASON_D_ID' && data.columnName != 'CATEGORY_ID') else editableBlock">
          <div class="detail-content-row">
            <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}:</label>
            <label class="fs-6 filter-title">{{''+detail[data.columnName]}}</label>
          </div>
        </ng-container>

        <ng-template #editableBlock>
          <ng-container *ngIf="data.columnName != 'SCORECARD_ID'">
            <ng-container *ngIf="data.type != 'DTY_LIST' else listBlock">
              <ng-container *ngIf="data.type == 'DTY_DATE' else listBlock">
                <div class="detail-content">
                  <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                  <custom-datepicker *ngIf="data.type == 'DTY_DATE'" [disabled]="!data.editable || !isEditable"
                                     [(value)]="detail[data.columnName]">
                  </custom-datepicker>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #listBlock>
              <ng-container *ngIf="data.type == 'DTY_LIST' && !!data.optionValues else defaultBlock">

                <div class="detail-content">
                  <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>

                  <div class="select-wrapper">
                    
                    <custom-select  
                    [values]="(pageData.get(data.columnName))?.optionValues" [disabled]="!data.editable || !isEditable"
                                   [(defaultValue)]="detail[data.columnName]" (columnChange)="onColumnChange($event, data.columnName)" [extraClass]="'likeText'">
                    </custom-select>
                  </div>

                </div>
              </ng-container>
              <ng-template #defaultBlock>
                <div class="detail-content">
                  <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                  <custom-text-field [appearance]="'fill'" [placeholder]="data.columnLabel | translate" [disabled]="!data.editable || !isEditable"
                                     [(value)]="detail[data.columnName]"></custom-text-field>
                </div>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-template>
      </ng-container>


    </div>

    <div class="table-container" id="problem-pnc">

      <div *ngIf="sectionLayout['id'] == 'LYO_SEC_PROBLEM_DT_PNC'">
        <data-table
          *ngIf="sectionLayout['id'] == 'LYO_SEC_PROBLEM_DT_PNC' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
          [datasets]="(sectionLayout['dataset'])[0]" [actionsets]="isEditable ? (sectionLayout['actionset'])[0] : []"
          (onActionEmit)="pncActionCatcher($event)"
          (onRowClick)="onRowClick($event)"
          (longPress)="onPncClick('ACT_EDIT_PNC')"
          [tableController]="pncTableController" [numElementsLoaded]="pncTableController.numElementsLoaded"
          [length]="pncTableController.numElementsSize" [disabledColumns]="this.detail && this.detail.CATEGORY_ID == 'FA' ? disabledColums : []">
        </data-table>

      </div>
    </div>
    <!--<div class="table-container">
      <div *ngIf="sectionLayout['id'] == 'LYO_SEC_PROBLEM_DT_SERIAL' && this.detail && this.detail.CATEGORY_ID == 'FA'">
        <data-table
          *ngIf="sectionLayout['id'] == 'LYO_SEC_PROBLEM_DT_SERIAL' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
          [datasets]="(sectionLayout['dataset'])[0]" [actionsets]="isEditable ? (sectionLayout['actionset'])[0] : []"
          (onActionEmit)="serialActionCatcher($event)" [tableController]="serialTableController" [defaultCheckedValue]="defaultCheckedButton"
          [numElementsLoaded]="serialTableController.numElementsLoaded"
          [length]="serialTableController.numElementsSize"></data-table>
      </div>
    </div>-->
    <ng-container *ngIf="sectionLayout['id'] == 'LYO_SEC_PROBLEM_DT_BUTTON'">
      <action-bar>
        <div class="col-btn" leftBtn>
          
          <custom-icon-btn (click)="onCancelClick()" color="accent" [iconClasses]="'arrow_back'"></custom-icon-btn>
        </div>

        <ng-container centerBtn *ngFor="let action of sectionLayout.actionset[0].actions">
          <div class="col-btn" *ngIf="action.id == 'ACT_ADD_PNC'">
            <custom-icon-btn (click)="onPncClick(action.id)" [btnClasses]="'fill-available'" color="accent" [iconClasses]="action.icon"></custom-icon-btn>
          </div>
          <div class="col-btn" *ngIf="action.id == 'ACT_ADD_PNC_WH'">
            <custom-icon-btn (click)="onPncClick(action.id)" [btnClasses]="'fill-available'" color="accent"
                             [iconClasses]="action.icon"></custom-icon-btn>
          </div>
          <div class="col-btn" *ngIf="action.id == 'ACT_SAVE'">
            <custom-icon-btn (click)="onSaveClick($event, true)" [btnClasses]="'fill-available'" color="accent" [iconClasses]="'save'"></custom-icon-btn>
            <!--<custom-btn color="accent" (click)="onSaveClick($event, true)">Ok</custom-btn>-->
          </div>
        </ng-container>

        <div rightBtn class="col-auto">
          <!--<custom-icon-btn (click)="openCameraRoll()" [btnClasses]="'fill-available'" color="accent" [iconClasses]="'camera_roll'"></custom-icon-btn>-->
          
          <custom-icon-btn [btnClasses]="'fill-available'" color="accent" [iconClasses]="'add_a_photo'" (click)="openCameraRoll()"  ></custom-icon-btn>
        </div>
        
        
        <!-- <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0].actions">
          <div class="col-auto" *ngIf="action.id == 'ACT_EDIT_PNC'">-->
            <!--<custom-icon-btn (click)="onPncClick('edit')" [btnClasses]="'fill-available'" color="accent" [iconClasses]="'edit_note'"></custom-icon-btn>-->
           <!-- <custom-btn color="accent" (click)="onPncClick(action.id)">Edit PNC</custom-btn>-->
        <!--  </div>
        </ng-container>
      -->


      </action-bar>
    </ng-container>
    <!--<filter-panel
      *ngIf="sectionLayout['id'] == 'LYO_SECFILT' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].filterset && !!filterController.filterModels"
      (onFilterClick)="onFilterClick($event)" [filterController]="filterController"></filter-panel>
    <data-table
      *ngIf="sectionLayout['id'] == 'LYO_SECTABLE' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
      [datasets]="(sectionLayout['dataset'])[0]" [actionsets]="(sectionLayout['actionset'])[0]"
      (onActionEmit)="actionCatcher($event)" [tableController]="tableController"
      [numElementsLoaded]="tableController.numElementsLoaded" [length]="tableController.numElementsSize"></data-table>
    <action-bar>
      <custom-btn cancelBtn (click)="onCancelClick()">Cancel</custom-btn>
      <span class="spacer"></span>
      <custom-btn saveBtn color="accent" (click)="onSaveClick($event)">Save</custom-btn>
    </action-bar>-->
  
  </ng-container>


