import { Component, OnInit } from '@angular/core';
import Integer from "@zxing/library/es2015/core/util/Integer";
import {AlertService} from "./alert.service";


@Component({
  selector: 'custom-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  constructor(public alertService: AlertService) {
  }


}
