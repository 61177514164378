import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableControllerService } from '../../../../services/table-controller.service';
import { WarehouseService } from '../../../../services/crud/warehouse.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
} from 'rxjs';
import { map, first, take } from 'rxjs/operators';
import { GeneralService } from '../../../../services/crud/general.service';
import { FilterControllerService } from '../../../../services/filter-controller.service';
import * as saveAs from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { LaneSelectionComponent } from '../../../pages/lane-selection/lane-selection.component';
import { hasSubArray, refactorName } from '../../../../utils/generic';
import { PageEventType } from '../../custom-table-paginator/custom-table-paginator.component';
import {
  ActionSet,
  Data,
  DataSet,
  Layout,
  SectionLayout,
} from '../../../../models/layout';
import { DamageService } from 'src/app/services/crud/damage.service';
import { DraftDialogComponent } from '../../draft-dialog/draft-dialog.component';
import * as _ from 'lodash';
import { SpinnerService } from '../../../../services/spinner.service';
import { LayoutServiceService } from '../../../../services/layout.service';
import { ALERT_TYPE } from '../../alert/alert.enumerate';
import { AlertService } from '../../alert/alert.service';
import { NavigationService } from '../../../../services/utils/navigation.service';
import { DndComponent } from '../../dnd/dnd.component';
import { RESTORED_VIEW_CONTEXT_NAME } from '@angular/compiler/src/render3/view/util';

@Component({
  selector: 'layout-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  providers: [TableControllerService, FilterControllerService],
})
export class GeneralComponent {
  constructor(
    private spinner: SpinnerService,
    public tableController: TableControllerService,
    public filterController: FilterControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private generalDataService: GeneralService,
    public dialog: MatDialog,
    private damageService: DamageService,
    public layoutService: LayoutServiceService,
    public alertService: AlertService,
    private navigation: NavigationService
  ) {
    this.route.url
      .pipe(map((segments) => segments.join('/')))
      .pipe(
        map((url) => {
          this.spinner.hideAll();
          this.apiUrl = url;
          this.route.parent?.url
            .pipe(map((segments) => segments.join('/')))
            .subscribe((finalParentUrl) => {
              this.apiParent = finalParentUrl;
            });
          return url;
        })
      )
      .subscribe((url) => {
        this.getQueryParam();
      });

    this.tableController.onPaginationChange.subscribe((event) => {
      this.savedPaginator = event;
      this.onSearch(
        event.pageIndex,
        event.loader,
        new Map<string, { order: number; type: 'asc' | 'desc' }>(),
        this.savedFilter
      );
    });

    this.tableController.onSortChange.subscribe((event) => {
      this.sortedColumns = event.sorting;
      this.onSearch(
        0,
        this.tableController.dataSet.length,
        event.sorting,
        this.savedFilter,
        true
      );
    });
  }

  _LayoutSubject: BehaviorSubject<Layout | null> =
    new BehaviorSubject<Layout | null>(null);
  _LayoutObservable = this._LayoutSubject.asObservable();

  layout: Layout | undefined;

  pageActionset: ActionSet[] = [];
  pageDataset: DataSet[] = [];

  dataSet: any[] = [];

  apiUrl: string = '';

  apiParent: string = '';

  savedFilter: any = [];

  titlePage: string = '';
  pageId: string = '';

  savedParams: HttpParams = new HttpParams();

  savedPaginator: PageEventType = {
    pageIndex: 0,
    previousPageIndex: 0,
    loader: 50,
    size: 0,
  };

  sortedColumns: Map<string, { order: number; type: 'asc' | 'desc' }> =
    new Map();

  toDamageDetail = {};

  disabledColumns: string[] = ['LOADING_CARDS_ID', 'FILE_NAME'];

  refreshData() {
    this.onSearch(
      0,
      this.savedPaginator.loader,
      new Map<string, { order: number; type: 'asc' | 'desc' }>(),
      new Array<string>(),
      true
    );
  }

  getQueryParam() {
    this.route.queryParams
      .pipe(
        map((params) => {
          this.pageId = params['id'] ?? [];
          this.titlePage = params['title'] ?? '';

          this.getPageLayout(this.pageId);

          /*const layoutsString = localStorage.getItem('layout');
        if (!!layoutsString) {
          const layouts = JSON.parse(layoutsString);
          if (!!layouts && !!layouts[this.pageId]) {
            const pageLayout = layouts[this.pageId];
            if (
              Math.abs(
                (new Date(pageLayout['instant']).getTime() -
                  new Date().getTime()) /
                60000
              ) < 360
            ) {
              this.layout = pageLayout['instance'];
              this.filterController.filterModels =
                this.layout?.sectionLayouts
                  .filter((sectionLayout) => sectionLayout.id == 'LYO_SECFILT')
                  .pop()?.dataset[0]?.filterset ?? [];
              if (!!this.layout) {
                this.catchAllSelectedOptions(this.layout);
              }
              this.refreshData();
            } else {
              this.getPageLayout(this.pageId);
            }
          } else {
            this.getPageLayout(this.pageId);
          }
        } else {
          this.getPageLayout(this.pageId);
        }*/

          return params;
        })
      )
      .subscribe({
        next: (params) => {},
        error: (err) => {
          //(err)
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: 'Some error occurred on layout retrive',
            timeout: 5000,
            selfClose: null,
          });
        },
      });
  }

  onFilterClick(event: any) {
    const newFilter: any = [];
    const _filterList = this.filterController.filterModels.filter(
      (model) => !model.isHide
    );
    //(_filterList);
    _filterList.forEach((filter) => {
      //(filter)
      if (
        filter.filterSelectedOption.haveSecondParam &&
        filter.filterSelectedOption.id == '_-d_'
      ) {
        if (!!filter.firstValue)
          newFilter.push(filter.columnName + '_>d_' + filter.firstValue);
        if (!!filter.secondValue)
          newFilter.push(filter.columnName + '_<d_' + filter.secondValue);
      } else if (
        filter.filterSelectedOption.haveSecondParam &&
        filter.filterSelectedOption.id == '_-_'
      ) {
        if (!!filter.firstValue)
          newFilter.push(filter.columnName + '_>_' + filter.firstValue);
        if (!!filter.secondValue)
          newFilter.push(filter.columnName + '_<_' + filter.secondValue);
      } else {
        if (!!filter.firstValue || !filter.filterSelectedOption.haveFirstParam)
          newFilter.push(
            filter.columnName +
              filter.filterSelectedOption.id +
              filter.firstValue
          );
      }
    });
    this.savedFilter = newFilter;
    this.tableController.dataSet = [];
    this.onSearch(
      0,
      this.savedPaginator.loader,
      new Map<string, { order: number; type: 'asc' | 'desc' }>(),
      newFilter
    );
  }

  onCancelClick() {
    this.tableController.deletedRows = [];
    this.tableController.selection.clear();
    this.tableController.dataSet = [];
    this.onSearch(
      0,
      this.savedPaginator.loader,
      new Map<string, { order: number; type: 'asc' | 'desc' }>(),
      this.savedFilter
    );
  }

  onSaveClick(event) {
    event.stopPropagation();

    this.spinner.show();
    if (
      !!this.tableController.deletedRows &&
      this.tableController.deletedRows.length > 0
    ) {
      lastValueFrom(
        this.generalDataService.deleteData(
          this.apiParent + '/' + this.apiUrl,
          this.tableController.deletedRows
        )
      )
        .then((res) => {
          this.tableController.deletedRows = [];
          this.spinner.hide();
        })
        .finally(() => {
          this.spinner.show();
          lastValueFrom(
            this.generalDataService.postData(
              this.apiParent + '/' + this.apiUrl,
              this.tableController.dataSet
                .filter((row) => row.edited != undefined)
                .map((elem) => {
                  delete elem.edited;
                  delete elem.checked;
                  return elem;
                })
            )
          ).finally(() => {
            this.spinner.hide();
            this.onCancelClick();
          });
        });
    } else {
      lastValueFrom(
        this.generalDataService.postData(
          this.apiParent + '/' + this.apiUrl,
          this.tableController.dataSet
            .filter((row) => row.edited != undefined)
            .map((elem) => {
              delete elem.edited;
              delete elem.checked;
              return elem;
            })
        )
      ).finally(() => {
        this.spinner.hide();
        this.onCancelClick();
      });
    }

    /*this.tableController.saveAll(
      this.tableController.dataSet.filter(
        (row) => row.edited != undefined || row.checked != undefined
      )
    );
    this.tableController.dataSet = this.tableController.dataSet.map((row) => {
      delete row.edited;
      delete row.checked;
      return row;
    });*/
  }

  onExportAllClick(event) {
    event.stopPropagation();

    if (!!this.layout)
      this.savedParams = this.savedParams.set(
        'dataset',
        this.layout['sectionLayouts']
          .filter((sectionLayout) => sectionLayout.id == 'LYO_SECTABLE')
          .pop()?.dataset[0].id ?? ''
      );

    this.generalDataService
      .exportDataExcel(
        this.apiParent + '/' + this.apiUrl,
        'excel',
        this.savedParams,
        []
      )
      .subscribe((blob) => {
        saveAs(blob, 'excel.xlsx');
        this.spinner.hide();
      });
  }

  onSearch(
    page: number,
    size: number,
    sorting: Map<string, { order: number; type: 'asc' | 'desc' }>,
    filters: Array<string>,
    refresh: boolean = false
  ) {
    let params = new HttpParams().set('page', page).set('size', size);
    new Map(
      [...sorting.entries()].sort((a, b) => a[1].order - b[1].order)
    ).forEach((value, key, map) => {
      if (!!!params.get('sort'))
        params = params.set(
          'sort',
          refactorName(key) + (value.type == 'desc' ? ',desc' : '')
        );
      else
        params = params.append(
          'sort',
          refactorName(key) + (value.type == 'desc' ? ',desc' : '')
        );
    });
    filters.forEach((filter) => {
      if (!filter.includes('&')) {
        if (!!!params.get('search')) params = params.set('search', filter);
        else params = params.append('search', filter);
      } else {
        filter.split('&').forEach((value) => {
          if (!!!params.get('search')) params = params.set('search', value);
          else params = params.append('search', value);
        });
      }
    });

    this.savedParams = params;

    this.spinner.show();
    lastValueFrom(
      this.generalDataService.getData(
        this.apiParent + '/' + this.apiUrl,
        undefined,
        params
      )
    )
      .then((res) => {
        //this.dataSet = res['oContent']['content'];
        ////(this.dataSet);
        if (refresh) {
          this.tableController.dataSet = res['oContent']['content'];
        } else {
          this.tableController.dataSet = !!this.tableController.dataSet
            ? hasSubArray(
                this.tableController.dataSet,
                res['oContent']['content']
              )
            : res['oContent']['content'];
        }

        this.tableController.numElementsLoaded =
          this.tableController.dataSet.length;
        this.tableController.numElementsSize = res['oContent'].totalElements;
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  actionCatcher(event: string) {
    if (event.endsWith('_ALL')) {
      if (event.startsWith('ACT_EXPORT')) {
        this.spinner.show();
        this.generalDataService
          .exportDataExcel(
            this.apiParent + '/' + this.apiUrl,
            'excel',
            this.savedParams.set(
              'dataset',
              this.layout!['sectionLayouts'].filter(
                (sectionLayout) => sectionLayout.id == 'LYO_SECTABLE'
              ).pop()?.dataset[0].id ?? ''
            ),
            []
          )
          .subscribe((blob) => {
            saveAs(blob, 'excel.xlsx');
            this.spinner.hide();
          });
      }
      if (event.startsWith('ACT_TO_LANE')) {
        const dialogRef = this.dialog.open(LaneSelectionComponent, {
          data: { title: 'Lane Selection', dmgType: this.apiParent },
          /*minHeight: 'calc(100vh - 90px)',
          height: 'auto',*/
          width: '800px',
          disableClose: true,
        });

        lastValueFrom(dialogRef.afterClosed()).then((result) => {
          if (!!result) {
            this.router.navigate(
              ['page/' + this.apiParent + '/' + this.apiUrl + '/detail'],
              {
                state: result,
                queryParams: {
                  id:
                    this.navigation.changeQueryParmas(
                      'page/' + this.apiParent + '/' + this.apiUrl + '/detail'
                    ) ?? '',
                  n: true,
                  t: this.apiParent,
                },
              }
            );
          }
        });
      }

      if (event.startsWith('ACT_ADD_DAM_WH')) {
        const pageId =
          this.navigation.changeQueryParmas(
            'page/' + this.apiParent + '/' + this.apiUrl + '/detail'
          ) ?? '';
        this.router.navigate(['page/' + this.apiParent + '/damages/detail'], {
          state: {
            data: {
              DAMAGE_ID: '',
              SENDING_WH: '',
              RECEIVING_WH: '',
              DAMAGE_TYPE_ID: this.apiParent.toUpperCase(),
              SENDING_DATE: '',
              RECEIVING_DATE: '',
              RECEIVING_WEEK: '',
              DESPATCHED_QTY: 0,
              DAMAGED_APP_TOT_QTY: 0,
              DAMAGED_PKG_TOT_QTY: 0,
              STATUS: 'OPEN',
              INSERT_USER: '',
              INSERT_DATE: '',
              UPDATE_USER: '',
              UPDATE_DATE: '',
              SHIPMENT_ID: '',
              DELIVERY_TYPE_ID: '',
              DELIVERY_MODE_ID: '',
              CARRIER_ID: '',
              WAGON: '',
              TRUCK_PLATE: '',
              OTM_SHIPMENT_ID: '',
              CONTAINER: '',
              TRAILER_PLATE: '',
              REMARKS: '',
              L_EFFICIENCY_PROBLEMS: [],
              L_PROBLEMS: [],
            },
          },
          queryParams: { id: pageId, t: this.apiParent, n: true },
        });
      }
    } else if (event.endsWith('_ID')) {
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'Delete confirmation',
            content: `Are you sure to proceed?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          ////(res);
          if (res) {
            this.spinner.show();
            this.generalDataService
              .deleteData(
                this.apiParent + '/' + this.apiUrl,
                this.tableController.deletedRows
              )
              .subscribe((res) => {
                this.tableController.deletedRows = [];
                this.spinner.hide();
                this.onCancelClick();
              });
          } else {
            this.tableController.deletedRows = [];
          }
        });
      }

      if (event.startsWith('ACT_EDIT_DAM')) {
        const pageId =
          this.navigation.changeQueryParmas(
            'page/' + this.apiParent + '/' + this.apiUrl + '/detail'
          ) ?? '';
        this.router.navigate(['page/' + this.apiParent + '/damages/detail'], {
          state: { data: this.tableController.row },
          queryParams: {
            id: pageId,
            t: this.apiParent,
            damage: this.tableController.row['DAMAGE_ID'],
          },
        });
      }

      if (event.startsWith('ACT_SAVE')) {
        this.spinner.show();
        this.generalDataService
          .postData(
            this.apiParent + '/' + this.apiUrl,
            this.tableController.dataSet
              .filter((row) => row.edited)
              .map((elem) => {
                delete elem.editing;
                delete elem.checked;
                return elem;
              })
          )
          .subscribe((res) => {
            this.spinner.hide();
            this.onCancelClick();
          });
      }
      if (event.startsWith('ACT_CARD_UPLOAD')) {
        const dialogEvent = this.dialog.open(DndComponent, {
          data: {
            title: 'UPLOADING',
            confirmBtn: 'Upload',
            cancelBtn: 'Cancel',
            type: 'any',
          },
        });
        dialogEvent.afterClosed().subscribe((dndResponse) => {
          if (!!dndResponse && dndResponse.send) {
            const loadingCard = this.tableController.row;

            let params = new HttpParams();
            params = params
              .set('id', loadingCard['LOADING_CARDS_ID'])
              .set('description', loadingCard['DESCRIPTION'])
              .set('sending', loadingCard['SENDING_WH'])
              .set('receiving', loadingCard['RECEIVING_WH'])
              .set('file_name', dndResponse.files[0].name)
              .set('file_type', dndResponse.files[0].type);


            lastValueFrom(
              this.generalDataService.uploadLoadingCard(
                this.apiParent + '/' + this.apiUrl + '/upload',
                params,
                dndResponse.files[0]
              )
            )
              .then((res) => {
                if (res.iResponseCode == 200) {
                  this.alertService.add({
                    type: ALERT_TYPE.SUCCESS,
                    message: 'File uploaded.',
                    timeout: 5000,
                    selfClose: null,
                  });
                }

                if (res.iResponseCode == 409) {
                  this.alertService.add({
                    type: ALERT_TYPE.WARNING,
                    message: res.sResponseMessage,
                    timeout: 5000,
                    selfClose: null,
                  });
                }
              })
              .catch((err) => {

                this.alertService.add({
                  type: ALERT_TYPE.WARNING,
                  message:
                    'Something went wrong, please try again or contact the admin.',
                  timeout: 5000,
                  selfClose: null,
                });
              })
              .finally(() => {
                this.spinner.hide();
                this.refreshData();
              });
          }
        });
      }
      if (event.startsWith('ACT_CARD_DOWNLOAD')) {
        const loadingCardIds = new Array();
        loadingCardIds.push(this.tableController.row['LOADING_CARDS_ID']);

        lastValueFrom(
          this.generalDataService.downloadCard(
            this.apiParent + '/' + this.apiUrl + '/download',
            loadingCardIds
          )
        )
          .then((blob) => {
            if (blob.size == 0) {
              this.alertService.add({
                type: ALERT_TYPE.WARNING,
                message:
                  'There is no file associated with the loading card ' +
                  this.tableController.row['LOADING_CARDS_ID'],
                timeout: 5000,
                selfClose: null,
              });
              return;
            } else {
              saveAs(blob, this.tableController.row['FILE_NAME']);
            }
          })
          .catch((err) => {
            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: 'Something went wrong during the download ',
              timeout: 5000,
              selfClose: null,
            });
          });
      }
    } else {
      if (this.tableController.selection.selected.length == 0) {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            title: 'No row selected',
            content: `Please select at least 1 row...`,
            confirmButtonLabel: 'Ok',
            confirmButtonColor: 'basic',
          },
        });
        return;
      }
      if (event.startsWith('ACT_EXPORT')) {
        if (!!this.layout)
          this.savedParams = this.savedParams.append(
            'dataset',
            this.layout['sectionLayouts']
              .filter((sectionLayout) => sectionLayout.id == 'LYO_SECTABLE')
              .pop()?.dataset[0].id ?? ''
          );

        this.generalDataService
          .exportDataExcel(
            this.apiParent + '/' + this.apiUrl,
            'excel',
            this.savedParams.set(
              'dataset',
              this.layout!['sectionLayouts'].filter(
                (sectionLayout) => sectionLayout.id == 'LYO_SECTABLE'
              ).pop()?.dataset[0].id ?? ''
            ),
            this.tableController.selection.selected
          )
          .subscribe((blob) => {
            saveAs(blob, 'excel.xlsx');
            this.spinner.hide();
          });
      }
      if (event.startsWith('ACT_DELETE')) {
        const dialogRef = this.dialog.open(DialogComponent, {
          // width: '250px',
          data: {
            title: 'Delete confirmation',
            content: `Are you sure to delete all selected rows data?`,
            //content: `Are you sure you want map Original Final Destination [ ${data.oldRecord.finalDestination} ] Destination CID ["${data.oldRecord.destinationCid}"] to "${data.record.rdcName}"?`,
            cancelButtonLabel: 'No',
            cancelButtonColor: 'basic',
            confirmButtonLabel: 'Yes',
            confirmButtonColor: 'warn',
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          if (!!res) {
            this.generalDataService
              .deleteData(
                this.apiParent + '/' + this.apiUrl,
                this.tableController.selection.selected
              )
              .subscribe((res) => {
                this.tableController.selection.clear();
                this.onCancelClick();
              });
          }
        });
      }
      if (event.startsWith('ACT_DRAFT')) {
        if (!!this.tableController.selection.selected) {
          const dialog = this.dialog.open(DraftDialogComponent, {
            width: '65vw',
            data: {
              title: 'Notify Warehouse',
              damageIds: this.tableController.selection.selected.map(
                (row) => row['DAMAGE_ID']
              ),
              typology: 'default',
              notificationId: 'default',
              url: this.apiParent + '/' + this.apiUrl,
              closeButtonLabel: 'Close',
              confirmButtonLabel: 'Send',
            },
            backdropClass: 'backdropBackground',
          });

          dialog.afterClosed().subscribe((res) => {
            if (!!res && res) {
              ////(res);
            }
          });

          this.tableController.selection.clear();
        }
      }
      if (event.startsWith('ACT_SEND')) {
        if (!!this.tableController.selection.selected) {
          lastValueFrom(
            this.damageService.directNotify(
              this.tableController.selection.selected.map(
                (row) => row['DAMAGE_ID']
              ),
              'default',
              'default',
              this.apiParent + '/' + this.apiUrl
            )
          )
            .then((res) => {
              this.alertService.add({
                type: ALERT_TYPE.SUCCESS,
                message: 'A mail was send to selected mail',
                timeout: 5000,
                selfClose: null,
              });
              this.tableController.selection.clear();
            })
            .catch((err) => {
              this.alertService.add({
                type: ALERT_TYPE.DANGER,
                message: 'Unable to send mail to selected damages',
                timeout: 5000,
                selfClose: null,
              });
            });
        }
      }
      if (event.startsWith('ACT_CARDS_DOWNLOAD_MULTI')) {
        const loadingCardIds = this.tableController.selection.selected.map(
          (card) => card.LOADING_CARDS_ID
        );

        lastValueFrom(
          this.generalDataService.downloadCard(
            this.apiParent + '/' + this.apiUrl + '/download',
            loadingCardIds
          )
        )
          .then((blob) => {
            if (blob.size == 0) {
              this.alertService.add({
                type: ALERT_TYPE.WARNING,
                message:
                  'There is no file associated with the selected loading card.',
                timeout: 5000,
                selfClose: null,
              });
              return;
            } else {
              saveAs(
                blob,
                loadingCardIds.length == 1
                  ? this.tableController.selection.selected[0].FILE_NAME
                  : 'CARDS'
              );
            }
            this.tableController.selection.clear();
          })
          .catch((err) => {

            this.alertService.add({
              type: ALERT_TYPE.WARNING,
              message: 'Something went wrong during the download ',
              timeout: 5000,
              selfClose: null,
            });

            this.tableController.selection.clear();
          });
      }
    }
  }

  deleteOneRow() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Deletion Confirmation',
        content: `Are you sure to proceed?`,
        cancelButtonLabel: 'No',
        cancelButtonColor: 'basic',
        confirmButtonLabel: 'Yes',
        confirmButtonColor: 'warn',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        this.spinner.show();
        this.generalDataService
          .deleteData(
            this.apiParent + '/' + this.apiUrl,
            this.tableController.deletedRows
          )
          .subscribe((res) => {
            this.tableController.deletedRows = [];
            this.spinner.hide();
            this.onCancelClick();
          });
      }
    });
  }

  private catchAllSelectedOptions(layout?: Layout) {
    layout?.sectionLayouts.map((sectionLayout: SectionLayout) => {
      sectionLayout.dataset.map((dataset: DataSet) => {
        dataset.datas?.map((data: Data) => {
          if (data.valuesFrom.length > 0) {
            this.generalDataService
              .getSelectionOption(data.valuesFrom)
              .then((res) => {
                data.optionValues = res;
              });
          }
        });
      });
    });
  }

  private getPageLayout(pageId: string) {
    this.spinner.show();
    this.layoutService
      .getPageLayout(pageId)
      .pipe(
        map((res) => {
          this.layout = res?.layouts[0] ?? undefined;
          const filterSettings = localStorage.getItem('filter');
          if (!!filterSettings) {
            const filters = JSON.parse(filterSettings);
            if (!!filters[this.pageId] && !!filters[this.pageId].instance) {
              this.filterController.filterModels =
                filters[this.pageId].instance;
            } else {
              this.filterController.filterModels =
                this.layout?.sectionLayouts
                  .filter((sectionLayout) => sectionLayout.id == 'LYO_SECFILT')
                  .pop()?.dataset[0]?.filterset ?? [];
            }
          } else {
            this.filterController.filterModels =
              this.layout?.sectionLayouts
                .filter((sectionLayout) => sectionLayout.id == 'LYO_SECFILT')
                .pop()?.dataset[0]?.filterset ?? [];
          }

          const layoutsString = localStorage.getItem('layout');
          if (!!layoutsString) {
            let layouts = JSON.parse(layoutsString);
            layouts[pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          } else {
            let layouts = {};
            layouts[pageId] = {
              instance: res?.layouts[0],
              instant: new Date(),
            };
            localStorage.setItem('layout', JSON.stringify(layouts));
          }

          /* controllo dataset per popolare disabledColumns
          if(this.layout?.sectionLayouts[2].dataset.filter(dataset => dataset.id == 'DTS_CARDS'))*/

          if (!!this.layout) {
            this.catchAllSelectedOptions(this.layout);
          }
          /* if(!!this.pageLayouts && this.pageLayouts.length > 0){
                 this.pageActionset = this.pageLayouts[0].actionset ?? [];
                 this.pageDataset = this.pageLayouts[0].dataset ?? [];
                 ////(this.pageActionset)
               }*/
        })
      )
      .subscribe((res) => {
        this.spinner.hide();
        this.refreshData();
      });
  }

  resetFilter(isResetting: boolean){
    if(isResetting){
      this.getPageLayout(this.pageId);
    }
  }

  historySize() {
    return this.navigation.size()
  }
}
