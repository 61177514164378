<div class="mt-3 table-upper-bar">

  <div class="d-flex align-items-center">
    <div ngbDropdown *ngIf="datasets.multiselect
         && !!actionsets
         && !!actionsets.actions
         && actionsets?.actions.length > 0
         && (('ACT_EXPORT' | haveAction: actionsets?.actions | async)
           || ('ACT_DELETE' | haveAction: actionsets?.actions | async)
           || ('ACT_SEND' | haveAction: actionsets?.actions | async)
           || ('ACT_DRAFT' | haveAction: actionsets?.actions | async)
           || ('ACT_CARDS_DOWNLOAD' | haveAction: actionsets?.actions | async))">
      <custom-btn id="dropdownBasic1" color="primary" ngbDropdownToggle>
        Actions <i class="bi bi-caret-down"></i>
      </custom-btn>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="!!actionsets && !!actionsets.actions
           && actionsets?.actions.length > 0
           && (('ACT_EXPORT' | haveAction: actionsets?.actions | async)
           || ('ACT_DELETE' | haveAction: actionsets?.actions | async)
           || ('ACT_SEND' | haveAction: actionsets?.actions | async)
           || ('ACT_DRAFT' | haveAction: actionsets?.actions | async)
           || ('ACT_CARDS_DOWNLOAD' | haveAction: actionsets?.actions | async))">
        <ng-container *ngFor="let action of actionsets?.actions">
          <button ngbDropdownItem *ngIf="action.id == 'ACT_EXPORT'" (click)="actionEmitter(action.id)">Export</button>
          <button ngbDropdownItem *ngIf="action.id == 'ACT_DELETE'" (click)="actionEmitter(action.id)">Delete</button>
          <button ngbDropdownItem *ngIf="action.id == 'ACT_SEND'" (click)="actionEmitter(action.id)">Send</button>
          <button ngbDropdownItem *ngIf="action.id == 'ACT_DRAFT'" (click)="actionEmitter(action.id)">Draft</button>
          <button ngbDropdownItem *ngIf="action.id == 'ACT_CARDS_DOWNLOAD'"
                  (click)="actionEmitter(action.id + '_MULTI')">Download
          </button>
        </ng-container>
      </div>
    </div>


    <ng-container *ngFor="let action of actionsets?.actions">
      <custom-btn (click)="actionEmitter(action.id+'_ALL')" [iconButton]="true" [iconColor]="'green'"
                  [iconClasses]="'insert_drive_file'" *ngIf="action.id == 'ACT_EXPORT'" matTooltip="Export excel file">
      </custom-btn>
    </ng-container>
  </div>

  <span class="spacer" *ngIf="!datasets.multiselect && (actionsets?.actions ?? []).length == 0"></span>

  <div class="d-flex">

    <custom-table-paginator *ngIf="length > 0" class="right" [paginatorLoaderTitle]="'Loading batch: '"
                            [page]="pageEvent.pageIndex" [paginatorLoader]="50" [paginatorLoaderList]="[50, 100, 250]"
                            [numElementsLoaded]="numElementsLoaded" [numElementsSize]="length"
                            (onPaginatorChange)="onPageChange($event)">
    </custom-table-paginator>
    <!--<ng-container *ngFor="let action of actionsets?.actions">
      <custom-btn color="primary" (click)="onAddClick()" class="addBtn" *ngIf="action.id == 'ACT_ADD'">Add</custom-btn>
      <custom-btn color="primary" (click)="actionEmitter(action.id + '_ALL')" class="addBtn"
                  *ngIf="action.id == 'ACT_TO_LANE'">Add
      </custom-btn>
      <custom-btn color="primary" (click)="actionEmitter(action.id + '_ALL')" class="addBtn"
                  *ngIf="action.id == 'ACT_TO_PROBLEM'">Add
      </custom-btn>
      <custom-btn color="primary" (click)="actionEmitter(action.id + '_ALL')" class="addBtn"
                  *ngIf="action.id == 'ACT_ADD_DAM_WH'">Add
      </custom-btn>
    </ng-container>-->
  </div>

</div>

<div class="table-responsive">
  <div class="table-container">
    <table class="table table-striped ">
      <thead>
      <tr>

        <th *ngIf="datasets.multiselect">
          <mat-checkbox (change)="$event ? tableController.toggleAllRows() : null"
                        [checked]="tableController.selection.hasValue() && tableController.isAllSelected()"
                        [indeterminate]="tableController.selection.hasValue() && !tableController.isAllSelected()"
                        [aria-label]="tableController.checkboxLabel()">
          </mat-checkbox>
        </th>

        <th *ngFor="let column of datasets['datas']; let i = index" (click)="sortColumn(column.columnName)"
            class="pointer">
            <span class="table-button-container">
              {{column.columnLabel | translate}}
              <mat-icon
                *ngIf="!!sortedColumns.get(column.columnName) && sortedColumns.get(column.columnName)?.type == 'asc'">
                arrow_upward</mat-icon>
              <mat-icon
                *ngIf="!!sortedColumns.get(column.columnName) && sortedColumns.get(column.columnName)?.type == 'desc'"
                class="sorter-icon">arrow_downward</mat-icon>
              <label class="sort-order"
                     *ngIf="!!sortedColumns.get(column.columnName) && (sortedColumns.get(column.columnName)?.type == 'asc' || sortedColumns.get(column.columnName)?.type == 'desc')">{{sortedColumns.get(column.columnName)?.order}}</label>
            </span>
        </th>

      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let dataRow of tableController.dataSet; let i=index" (click)="onRowClick.emit(i)"
          [class.selected-row]="tableController.rowNumber == i">

        <td *ngIf="datasets.multiselect" class="align-middle">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? tableController.selection.toggle(dataRow) : null"
                        [checked]="tableController.selection.isSelected(dataRow)"
                        [aria-label]="tableController.checkboxLabel(dataRow)">
          </mat-checkbox>
        </td>

        <td *ngFor="let column of datasets['datas'];" class="align-middle">

          <ng-container *ngIf="!(!!dataRow.editing) && !(!!dataRow.newRow)  else editMode">
            <ng-container *ngIf="column.isPk else notPk">
              <ng-container [ngSwitch]="column.type">
                <ng-container *ngSwitchCase="'DTY_LIST'">
                  <ng-container *ngIf="('ACT_EDIT_DAM' | haveAction: actionsets?.actions | async) else notEditDam">
                      <span class="text-nowrap pointer text-decoration-underline "
                            [matTooltip]="(column | getListTooltip: tableController.dataSet[i][column.columnName] : tableController.dataSet[i][column.columnName] : i ) ?? tableController.dataSet[i][column.columnName]"
                            (click)="editRowId(i, 'ACT_EDIT_DAM')">
                        {{tableController.dataSet[i][column.columnName]}}
                      </span>
                  </ng-container>
                  <ng-template #notEditDam>
                    <ng-container
                      *ngIf="('ACT_EDIT_PROBLEM' | haveAction: actionsets?.actions | async) else notEditProblem">
                        <span class="text-nowrap pointer text-decoration-underline"
                              (click)="editRowId(i, 'ACT_EDIT_PROBLEM')"
                              [matTooltip]="(column | getListTooltip: tableController.dataSet[i][column.columnName] : tableController.dataSet[i][column.columnName] : i ) ?? tableController.dataSet[i][column.columnName]">
                          {{tableController.dataSet[i][column.columnName]}}
                        </span>
                    </ng-container>
                    <ng-template #notEditProblem>
                        <span class="text-nowrap"
                              [matTooltip]="(column | getListTooltip: tableController.dataSet[i][column.columnName] : tableController.dataSet[i][column.columnName] : i ) ?? tableController.dataSet[i][column.columnName]">
                          {{tableController.dataSet[i][column.columnName]}}
                        </span>
                    </ng-template>
                  </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="'DTY_RADIO'">
                  <i class="bi bi-record-circle" *ngIf="tableController.dataSet[i][column.columnName]"></i>
                  <i class="bi bi-circle" *ngIf="!tableController.dataSet[i][column.columnName]"></i>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ng-container *ngIf="('ACT_EDIT_DAM' | haveAction: actionsets?.actions | async) else notEditDam">
                      <span class="text-nowrap pointer text-decoration-underline"
                            (click)="editRowId(i, 'ACT_EDIT_DAM')">
                        {{tableController.dataSet[i][column.columnName]}}
                      </span>
                  </ng-container>
                  <ng-template #notEditDam>
                    <ng-container
                      *ngIf="('ACT_EDIT_PROBLEM' | haveAction: actionsets?.actions | async) else notEditProblem">
                        <span class="text-nowrap pointer text-decoration-underline"
                              (click)="editRowId(i, 'ACT_EDIT_PROBLEM')">
                          {{tableController.dataSet[i][column.columnName]}}
                        </span>
                    </ng-container>
                    <ng-template #notEditProblem>
                        <span class="text-nowrap">
                          {{tableController.dataSet[i][column.columnName]}}
                        </span>
                    </ng-template>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #notPk>
              <ng-container [ngSwitch]="column.type">
                <ng-container *ngSwitchCase="'DTY_LIST'">
                    <span class="text-nowrap"
                          [matTooltip]="(column | getListTooltip: tableController.dataSet[i][column.columnName] : tableController.dataSet[i][column.columnName] : i ) ?? tableController.dataSet[i][column.columnName]">
                      {{tableController.dataSet[i][column.columnName]}}
                    </span>
                </ng-container>
                <ng-container *ngSwitchCase="'DTY_RADIO'">
                  <i class="bi bi-record-circle" *ngIf="tableController.dataSet[i][column.columnName]"></i>
                  <i class="bi bi-circle" *ngIf="!tableController.dataSet[i][column.columnName]"></i>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span class="text-nowrap">
                      {{tableController.dataSet[i][column.columnName]}}
                    </span>
                </ng-container>
              </ng-container>

            </ng-template>


          </ng-container>


          <ng-template #editMode>

            <custom-text-field *ngIf="column.type == 'DTY_NVAR' || column.type == 'DTY_INT'"
                               [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)"
                               [isNumber]="column.type == 'DTY_INT'"
                               [(value)]="tableController.dataSet[i][column.columnName]"
                               (change)="onInputChange(i)">
            </custom-text-field>

            <ng-container
              *ngIf="column.type == 'DTY_LIST' && (!(!!tableController.dataSet[i].newRow) || !column.isPk) else listElseBlock">
              <custom-select *ngIf="column.type == 'DTY_LIST'" [values]="column.optionValues"
                             [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)"
                             [(defaultValue)]="tableController.dataSet[i][column.columnName]"
                             (change)="onInputChange(i)">
              </custom-select>
            </ng-container>
            <ng-template #listElseBlock>
              <custom-text-field *ngIf="column.type == 'DTY_LIST'"
                                 [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)"
                                 [isNumber]="column.type == 'DTY_INT'"
                                 [(value)]="tableController.dataSet[i][column.columnName]"
                                 (change)="onInputChange(i)">
              </custom-text-field>
            </ng-template>

            <custom-datepicker *ngIf="column.type == 'DTY_DATE'"
                               [disabled]="(!column.editable && !(!!tableController.dataSet[i].newRow)) || (disabledColumns.indexOf(column.columnName) > -1)"
                               [(value)]="tableController.dataSet[i][column.columnName]"
                               (valueChange)="onInputChange(i)">
            </custom-datepicker>

            <ng-container *ngIf="column.type == 'DTY_RADIO'">
              <svg *ngIf="(tableController.dataSet[i][column.columnName]) else noChecked"
                   (click)="onRadioButtonClick(column, i)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="16" height="16" viewBox="0 0 1080 1080" xml:space="preserve">
                <g transform="matrix(1 0 0 1 540 540)" id="cecba6d2-22c4-412d-813e-4c5f33181f02"  >
                <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-540" y="-540" rx="0" ry="0" width="1080" height="1080" />
                </g>
                <g transform="matrix(1 0 0 1 540 540)" id="20fee9bb-4806-4f36-b1d0-e03c37859aac"  >
                </g>
                <g transform="matrix(NaN NaN NaN NaN 0 0)"  >
                <g style=""   >
                </g>
                </g>
                <g transform="matrix(14.92 0 0 14.92 537.4 537.4)" id="14c1cfa6-3eb0-4294-87b4-53fec07a944d"  >
                <circle style="stroke: rgb(1,30,65); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" cx="0" cy="0" r="35" />
                </g>
                <g transform="matrix(67.5 0 0 67.5 540 540)"  >
                <g style=""   >
                    <g transform="matrix(1 0 0 1 0 0)"  >
                <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(1,30,65); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-8, -8)" d="M 8 15 C 4.134006751184446 15 1 11.865993248815554 1 8 C 1 4.134006751184446 4.134006751184446 1 8 1 C 11.865993248815554 1 15 4.134006751184444 15 7.999999999999998 C 15 11.865993248815553 11.865993248815556 14.999999999999998 8.000000000000002 15 z M 8 16 C 12.418277998646348 16 16 12.418277998646348 16 8 C 16 3.5817220013536524 12.418277998646348 0 8 0 C 3.5817220013536524 0 0 3.5817220013536524 0 8 C 0 12.418277998646348 3.5817220013536524 16 8 16 z" stroke-linecap="round" />
                </g>
                  <g transform="matrix(1 0 0 1 0 0)"  >
                <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(1,30,65); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-8, -8)" d="M 11 8 C 11 9.65685424949238 9.65685424949238 11 8 11 C 6.343145750507619 11 5 9.65685424949238 5 8 C 5 6.343145750507619 6.343145750507619 5 8 5 C 9.65685424949238 5 11 6.3431457505076185 11 7.999999999999999 z" stroke-linecap="round" />
                </g>
                </g>
                </g>
              </svg>
              <ng-template #noChecked>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     width="16" height="16" viewBox="0 0 1080 1080" xml:space="preserve"
                     (click)="onRadioButtonClick(column, i)">
                  <g transform="matrix(1 0 0 1 540 540)" id="f1f3d078-6b09-4804-bd2e-b24aedd87810">
                  <rect
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1; visibility: hidden;"
                    vector-effect="non-scaling-stroke" x="-540" y="-540" rx="0" ry="0" width="1080" height="1080"/>
                  </g>
                  <g transform="matrix(1 0 0 1 540 540)" id="7e8a0f25-93ee-4b5e-8ac7-13ea41c4eabf">
                  </g>
                  <g transform="matrix(NaN NaN NaN NaN 0 0)">
                  <g style="">
                  </g>
                  </g>
                  <g transform="matrix(NaN NaN NaN NaN 0 0)">
                  <g style="">
                  </g>
                  </g>
                  <g transform="matrix(NaN NaN NaN NaN 0 0)">
                  <g style="">
                  </g>
                  </g>
                  <g transform="matrix(13.74 0 0 13.74 539.95 539.95)" id="3f1dd5db-e4df-4081-b09f-4e22389903ef">
                  <circle
                    style="stroke: rgb(1,30,65); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                    vector-effect="non-scaling-stroke" cx="0" cy="0" r="35"/>
                  </g>
                  <g transform="matrix(67.5 0 0 67.5 540 540)">
                  <path
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(1,30,65); fill-rule: nonzero; opacity: 1;"
                    transform=" translate(-8, -8)"
                    d="M 8 15 C 4.134006751184446 15 1 11.865993248815554 1 8 C 1 4.134006751184446 4.134006751184446 1 8 1 C 11.865993248815554 1 15 4.134006751184444 15 7.999999999999998 C 15 11.865993248815553 11.865993248815556 14.999999999999998 8.000000000000002 15 z M 8 16 C 12.418277998646348 16 16 12.418277998646348 16 8 C 16 3.5817220013536524 12.418277998646348 0 8 0 C 3.5817220013536524 0 0 3.5817220013536524 0 8 C 0 12.418277998646348 3.5817220013536524 16 8 16 z"
                    stroke-linecap="round"/>
                  </g>
                </svg>
              </ng-template>

            </ng-container>

          </ng-template>
        </td>
        <td>
            <span class="table-button-container">

              <!--<ng-container
                *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="editRow(i)" matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_DAM' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="editRowId(i, 'ACT_EDIT_DAM')"
                        matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_EDIT_PROBLEM' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="editRowId(i, 'ACT_EDIT_PROBLEM')"
                        matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="!keyExistsIn(dataRow, 'editing') && !keyExistsIn(dataRow, 'newRow') && ('ACT_SHOW_CAMERA' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="editRowId(i, 'ACT_SHOW_CAMERA')"
                        matTooltip="Camera roll">
                  <mat-icon>camera_roll</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="(dataRow.newRow || dataRow.editing) && ('ACT_SAVE' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="commitEdit(i, 'ACT_SAVE_ID')"
                        matTooltip="Save">
                  <mat-icon>save</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="!dataRow.newRow && !dataRow.editing && ('ACT_CARD_UPLOAD' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="editRowId(i, 'ACT_CARD_UPLOAD')"
                        matTooltip="Upload file">
                  <mat-icon>cloud_upload</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="!dataRow.newRow && !dataRow.editing &&  ('ACT_CARD_DOWNLOAD' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" matTooltip="Download file"
                        (click)="editRowId(i, 'ACT_CARD_DOWNLOAD')">
                  <mat-icon>cloud_download</mat-icon>
                </button>
              </ng-container>
              <ng-container
                *ngIf="(dataRow.newRow || dataRow.editing) && ('ACT_EDIT' | haveAction: actionsets?.actions | async)">
                <button mat-icon-button color="primary" class="table-button" (click)="exitRow(i)" matTooltip="Cancel">
                  <mat-icon>cancel</mat-icon>
                </button>
              </ng-container>-->
              <button mat-icon-button color="primary" class="table-button" (click)="deleteRow(i)" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
              </button>

            </span>
        </td>

      </tr>

      </tbody>
    </table>
  </div>
</div>

<br/>

<ngx-spinner style="color: white" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-loader"
             [fullScreen]="true">
  <p style="color: white" class="fs-3 fw-bold text-center"> Loading... </p>
</ngx-spinner>
