import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {
  DamageService,
  NotificationSL, NotificationSLBody,
} from 'src/app/services/crud/damage.service';
import { TABLE_STYLE } from 'src/app/utils/generic';
import { ALERT_TYPE } from '../alert/alert.enumerate';
import { AlertService } from '../alert/alert.service';
import { DndComponent } from '../dnd/dnd.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { WarehouseService } from 'src/app/services/crud/warehouse.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThrottlingUtils } from '@azure/msal-common';

@Component({
  selector: 'app-draft-dialog',
  templateUrl: './draft-dialog.component.html',
  styleUrls: ['./draft-dialog.component.scss'],
})
export class DraftDialogComponent implements OnInit {
  //dataSourceArr;
  contactGroupCols: any[] = [
    { field: 'shippingLine', label: 'Shipping Line' },
    { field: 'container', label: 'Container' },
  ];

  contactGroupDataSource;

  htmlConfig = new Map<number, AngularEditorConfig>();
  htmlSubjectConfig = new Map<number, AngularEditorConfig>();
  htmlContent: string[] = [];
  htmlSubjectContent: string[] = [];

  columns: any = [];

  contactGroups;

  headersSubject = new BehaviorSubject<any[]>([]);
  headers = this.headersSubject.asObservable();

  //displayedHeaders: any[] = [];
  //displayedColumns: string[] = [];

  //selectedFields!: Map<String, String>;
  expansionPanelTitles: string[] = [];

  sendTo!: Array<any>;

  manualSendTo!: Array<any>;

  sendCc!: Array<any>;

  manualSendCc!: Array<any>;

  sendingMailList!: Array<any>;

  sendingMailCcList!: Array<any>;

  sendAttachment!: Array<any>;

  isNewMail: boolean = false;

  newMail: string = '';

  isNewMailCc: boolean = false;

  newMailCc: string = '';

  fullBtn = false;

  @ViewChildren('table') tables;
  @ViewChild('alertMessageMonitor') alertMessageMonitor;

  htmlArr!: Array<string>;

  acceptFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  objectKeys = Object.keys;

  constructor(
    public dialogRef: MatDialogRef<DraftDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private damageService: DamageService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private warehouseService: WarehouseService,
    private spinner: NgxSpinnerService
  ) {
    dialogRef.disableClose = true;
    if (!!data['type'] && data['type'] == 'any') {
      this.acceptFileType = '*/*';
    }
  }

  ngOnInit(): void {
    this.sendTo = new Array<any>();
    this.manualSendTo = new Array<any>();
    this.sendingMailList = new Array<any>();
    this.sendingMailCcList = new Array<any>();
    this.sendAttachment = new Array<any>();
    this.getGroupedRecords();
    this.getWarehouses();
  }

  ngAfterViewInit(): void {
    this.htmlArr = new Array<string>();

    this.tables.changes.subscribe((el) => {
      el.toArray().forEach((item) => {
        this.htmlArr.push(
          TABLE_STYLE + item._elementRef.nativeElement.outerHTML
        );
      });
    });
  }

  getGroupedRecords() {
    /*this.damageService
      .groupDamagesByWh(
        this.tableController.selection.selected,
        'default',
        'default'
      )
      .subscribe((res) => {
        ////(res);
      });*/

      if (!!this.data['groups']) {
        this.createGroup(this.data['groups']);
      } else {
        this.damageService
          .groupDamagesByWh(
            this.data['damageIds'],
            this.data['typology'],
            this.data['notificationId'],
            this.data['url']
          )
          .subscribe((res) => {
            this.createGroup(res);
          });
      }


  }

  createGroup(res: any) {
    if (!!res && !!res['contactGroups']) {
      this.contactGroupDataSource = res['contactGroups'];
      this.columns = new Array<String>();
      //this.dataSourceArr = new Array<any>();
      this.sendTo = new Array<any>();
      this.manualSendTo = new Array<any>();
      this.sendCc = new Array<any>();
      this.manualSendCc = new Array<any>();
      this.sendAttachment = new Array<any>();
      this.contactGroupDataSource.forEach(
        (value, index) => {
          //(contactGroup);
          //this.selectedFields.set('', '');
          //this.dataSourceArr.push(value['records']);
          this.htmlContent[index] = value['htmlBody'];
          this.htmlSubjectContent[index] = value['mailSubject'];
          this.expansionPanelTitles.push('Mail ' + index);
          this.htmlConfig.set(index, {
            editable: true,
            spellcheck: true,
            height: '20rem',
            minHeight: '15rem',
            placeholder: 'Write your custom body...',
            translate: 'no',
            defaultParagraphSeparator: 'p',
            defaultFontName: 'Arial',
            toolbarHiddenButtons: [['insertVideo', 'toggleEditorMode']],
          });
          this.htmlSubjectConfig.set(index, {
            editable: true,
            spellcheck: true,
            height: '3rem',
            minHeight: '2rem',
            placeholder: 'Write your custom body...',
            translate: 'no',
            defaultParagraphSeparator: 'p',
            defaultFontName: 'Arial',
            toolbarHiddenButtons: [
              [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'heading',
                'fontName',
              ],
              [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode',
              ],
            ],
          });

          this.sendTo.push(value['sendTo']);
          this.manualSendTo.push([]);
          this.sendCc.push(value['sendCc']);
          this.manualSendCc.push([]);
          this.sendingMailList.push(value['sendTo']);
          this.sendingMailCcList.push(value['sendCc']);
          this.sendAttachment.push([]);
        }
      );

      for (let col of this.contactGroupCols) {
        this.columns.push(col.field);
      }

    }
    if (!!res && !!res['groups']) {
      this.contactGroups = new Array<string>();
      res['groups'].forEach((el) => {
        this.contactGroups.push(el['WAREHOUSE_ID']);
      });
    }
  }

  getWarehouses() {
    this.contactGroups = new Array<string>();
    this.warehouseService.getWarehousesWithNoParam().subscribe((res) => {
      if (!!res) {
        res.forEach((el) => {
          this.contactGroups.push(el['WAREHOUSE_ID']);
        });
      }
    });
  }



  notify(index: number) {
    if (
      !!!this.sendingMailList[index] ||
      this.sendingMailList[index].length < 1
    ) {
      this.alertService.add({
        type: ALERT_TYPE.WARNING,
        message: 'Missing recipient, please add an recipient.',
        timeout: 5000,
        selfClose: null,
      });
      return;
    }

    let payload: NotificationSLBody;

    payload = {
      sendCc: this.sendingMailCcList[index],
      sendTo: this.sendingMailList[index],
      htmlBody: TABLE_STYLE + this.htmlContent[index],
      mailSubject: this.htmlSubjectContent[index],
      files: this.sendAttachment[index].map((attachment) => {
        return {
          name: attachment.name,
          contentBytes: attachment.blob,
          contentType: attachment.type,
          size: attachment.size,
        };
      }),
    };

    lastValueFrom(this.damageService
      .notify(
        { contactGroups: [payload] },
        this.data.typology,
        this.data.notificationId,
        this.data['url']
      ))
      .then(
        (res) => {

          this.expansionPanelTitles.splice(index, 1);
          if (!this.expansionPanelTitles) this.expansionPanelTitles = [];

          this.sendingMailCcList.splice(index, 1);
          if (!this.sendingMailCcList) this.sendingMailCcList = [];
          this.sendingMailList.splice(index, 1);
          if (!this.sendingMailList) this.sendingMailList = [];
          this.htmlContent.splice(index, 1);
          if (!this.htmlContent) this.htmlContent = [];
          this.htmlSubjectContent.splice(index, 1);
          if (!this.htmlSubjectContent) this.htmlSubjectContent = [];
          this.sendAttachment.splice(index, 1);
          if (!this.sendAttachment) this.sendAttachment = [];

          this.sendTo.splice(index, 1);
          if (!this.sendTo) this.sendTo = [];
          this.manualSendTo.splice(index, 1);
          if (!this.manualSendTo) this.manualSendTo = [];
          this.sendCc.splice(index, 1);
          if (!this.sendCc) this.sendCc = [];
          this.manualSendCc.splice(index, 1);
          if (!this.manualSendCc) this.manualSendCc = [];

          ////(this.selectedFields);

          /* TODO
          this.selectedFields.delete(index, 1);
          if (!!!this.selectedFields) this.selectedFields = [];*/
          this.alertService.add({
            type: ALERT_TYPE.SUCCESS,
            message: 'You have successfully sent the notification.',
            timeout: 10000,
            selfClose: null,
          });

          if (this.expansionPanelTitles.length <= 0) {

            this.dialog.closeAll();
            this.dialogRef.close(true);
          }
        }
      ).catch((err) => {
      if (err.status == 403)
        this.alertService.add({
          type: ALERT_TYPE.WARNING,
          message: 'Error 403, missing permission.',
          timeout: 10000,
          selfClose: null,
        });
      else
        this.alertService.add({
          type: ALERT_TYPE.DANGER,
          message: 'An error occurred while sending the notification.',
          timeout: 10000,
          selfClose: null,
        });
    });
  }

  addMail(isCc?: boolean) {
    if (!!isCc) {
      this.isNewMailCc = true;
    } else {
      this.isNewMail = true;
    }
  }

  done(i: number, isCc?: boolean) {
    if (!!isCc) {
      if (!!this.newMailCc && this.checkEmail(this.newMailCc)) {
        if (this.sendingMailCcList[i].indexOf(this.newMailCc) < 0) {
          this.manualSendCc[i].push(this.newMailCc);
          this.sendingMailCcList[i] = this.sendCc[i].concat(
            this.manualSendCc[i]
          );
        }
        this.newMailCc = '';
      }
    } else {
      if (!!this.newMail && this.checkEmail(this.newMail)) {
        if (this.sendingMailList[i].indexOf(this.newMail) < 0) {
          this.manualSendTo[i].push(this.newMail);
          this.sendingMailList[i] = this.sendTo[i].concat(this.manualSendTo[i]);
        }
        this.newMail = '';
      }

      if (
        this.sendingMailList.filter((el) => !!el && el.length > 0).length ==
        this.expansionPanelTitles.length
      )
        this.alertMessageMonitor.closeAllAlerts();
    }
  }

  cancel() {
    this.newMail = '';
  }

  deleteEmail(id: string, index: number, array: string) {
    if (array == 'sendTo') {
      const i = this.sendTo[index].indexOf(id);
      if (i > -1) this.sendTo[index].splice(i, 1);
    } else if (array == 'manualSendTo') {
      const i = this.manualSendTo[index].indexOf(id);
      if (i > -1) this.manualSendTo[index].splice(i, 1);
    } else if (array == 'sendCc') {
      const i = this.sendCc[index].indexOf(id);
      if (i > -1) this.sendCc[index].splice(i, 1);
    } else if (array == 'manualSendCc') {
      const i = this.manualSendCc[index].indexOf(id);
      if (i > -1) this.manualSendCc[index].splice(i, 1);
    } else if (array == 'sendAttachment') {
      const i = this.sendAttachment[index]
        .map((attachment) => attachment.name)
        .indexOf(id);
      if (i > -1) this.sendAttachment[index].splice(i, 1);
    }

    this.sendingMailList[index] = this.sendTo[index].concat(
      this.manualSendTo[index]
    );
  }

  checkEmail(email: string) {
    const regex = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$');
    if (regex.test(email)) return true;
    else return false;
  }

  typeof(x: any) {
    return typeof x;
  }

  attachFile(i: number) {
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
      },
    });

    dialogEvent.afterClosed().subscribe((result) => {
      if (!!result && !!result.files && result.files.length > 0) {
        result.files.forEach((file) => {
          this.readBase64(file).then((result) => {
            let r = result;
            if (typeof result === 'string') {
              r = result.split(',')[1];
            }
            this.sendAttachment[i].push({
              name: file.name,
              size: file.size,
              blob: r,
              type: file.type,
            });
          });
        });
      }
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private readBase64(file) {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }

  openFull() {
    this.dialogRef.updateSize('100%', '100%');
    this.fullBtn = true;
  }

  exitFull() {
    this.dialogRef.updateSize('65vw', '');
    this.fullBtn = false;
  }
}
