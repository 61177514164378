<ng-container *ngIf="!!layout && !!layout['sectionLayouts']">
  <h1 class="fs-1 fw-bold text-left mt-3">{{ isNew == 'true' ? 'New Damage' : 'Edit Damage'}}</h1>
  <div class="mt-lg-5" *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
    <div
      *ngIf="sectionLayout['id'] == 'LYO_SEC_DETAIL' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas && !!detail"
      class="detail-container p-3 mb-5">
      <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
        <ng-container
          *ngIf="data.columnName != 'SHIPMENT_ID' && data.columnName != 'DELIVERY_TYPE_ID' && data.columnName != 'DELIVERY_MODE_ID' && data.columnName != 'CARRIER_ID' && data.columnName != 'WAGON' && data.columnName != 'TRUCK_PLATE' && data.columnName != 'OTM_SHIPMENT_ID' && data.columnName != 'CONTAINER' && data.columnName != 'TRAILER_PLATE' && data.columnName != 'REMARKS'">
          <ng-container *ngIf="data.type != 'DTY_LIST' else listBlock">
            <ng-container *ngIf="data.type == 'DTY_DATE' else listBlock">
              <div class="detail-content">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                <custom-datepicker *ngIf="data.type == 'DTY_DATE'" [disabled]="!data.editable || !isEditable"
                  [(value)]="detail[data.columnName]">
                </custom-datepicker>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #listBlock>
            <ng-container *ngIf="data.type == 'DTY_LIST' && !!data.optionValues else defaultBlock">

              <div class="detail-content ">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>

                <div class="select-wrapper">
                  <custom-select [values]="data.optionValues" [disabled]="!data.editable || !isEditable"
                    [(defaultValue)]="detail[data.columnName]" [extraClass]="'likeText'">
                  </custom-select>
                </div>

              </div>
            </ng-container>
            <ng-template #defaultBlock>
              <div class="detail-content">
                <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                <custom-text-field [appearance]="'fill'" [placeholder]="data.columnLabel | translate"
                  [disabled]="!data.editable || !isEditable" [(value)]="detail[data.columnName]"></custom-text-field>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>

    <ng-container class="shadow p-3 mb-5 bg-white roundeds">
      <div class="detail-info-content shadow p-3 mb-5 mt-3 bg-white rounded"
        *ngIf="!!sectionLayout['id'] &&  sectionLayout['id'] == 'LYO_SEC_DETAIL_INFO' && !!detail">
        <div *ngIf="!!sectionLayout['id'] &&  sectionLayout['id'] == 'LYO_SEC_DETAIL_INFO' && !!detail"
          class="mt-1 detail-container">

          <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
            <ng-container *ngIf="data.columnName == 'SHIPMENT_ID'">
              <div class="detail-container">
                <div class="detail-content">
                  <label class="fs-5 fw-bold filter-title">{{data.columnLabel | translate}}&nbsp;{{detail[data.columnName]}}</label>
                </div>
              </div>

            </ng-container>
          </ng-container>
        </div>

        <ng-container
          *ngIf="!!sectionLayout['id'] &&  sectionLayout['id'] == 'LYO_SEC_DETAIL_INFO' && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas && (sectionLayout['dataset'])[0].datas.length > 0 && !!detail">
          <div class="row mt-5">
            <div class="col-12">
              <div class="row p-1">
                <div class="col-11 ">
                  <div class="detail-container">
                    <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
                      <ng-container
                        *ngIf="data.columnName == 'DELIVERY_TYPE_ID' || data.columnName == 'DELIVERY_MODE_ID' || data.columnName == 'CARRIER_ID' || data.columnName == 'WAGON' || data.columnName == 'TRUCK_PLATE' || data.columnName == 'OTM_SHIPMENT_ID' || data.columnName == 'CONTAINER' || data.columnName == 'TRAILER_PLATE'">
                        <ng-container *ngIf="data.type != 'DTY_LIST' else listBlock">
                          <ng-container *ngIf="data.type == 'DTY_DATE' else listBlock">
                            <div class="detail-content">
                              <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                              <custom-datepicker *ngIf="data.type == 'DTY_DATE'"
                                [disabled]="!data.editable || !isEditable" [(value)]="detail[data.columnName]">
                              </custom-datepicker>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-template #listBlock>
                          <ng-container *ngIf="data.type == 'DTY_LIST' && !!data.optionValues else defaultBlock">

                            <div class="detail-content ">
                              <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>

                              <div class="select-wrapper">
                                <custom-select [values]="data.optionValues" [disabled]="!data.editable || !isEditable"
                                  [(defaultValue)]="detail[data.columnName]" [extraClass]="'likeText'">
                                </custom-select>
                              </div>

                            </div>
                          </ng-container>
                          <ng-template #defaultBlock>
                            <div class="detail-content">
                              <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                              <custom-text-field [appearance]="'fill'" [placeholder]="data.columnLabel | translate"
                                [disabled]="!data.editable || !isEditable" [(value)]="detail[data.columnName]">
                              </custom-text-field>
                            </div>
                          </ng-template>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!!sectionLayout['id'] &&  sectionLayout['id'] == 'LYO_SEC_DETAIL_INFO'">
          <div class="row mt-5 " *ngIf="!!detail">
            <div class="col-12">
              <div class="row p-1">
                <div class="col-8 ">
                  <ng-container *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
                    <data-table
                      *ngIf="!!sectionLayout['id'] && sectionLayout['id'] == 'LYO_SEC_PROBLEM' && !!detail && !!(sectionLayout['dataset'])[0] && !!(sectionLayout['dataset'])[0].datas"
                      [datasets]="(sectionLayout['dataset'])[0]" [actionsets]="(sectionLayout['actionset'])[0]"
                      [tableController]="problemsTableController"
                      [numElementsLoaded]="problemsTableController.numElementsLoaded"
                      [length]="problemsTableController.numElementsSize" (onActionEmit)="actionCatcher($event)">
                    </data-table>
                  </ng-container>
                </div>
                <div class="col-4 table-alignment">
                  <ng-container *ngFor="let sectionLayout of layout['sectionLayouts']; let i = index">
                    <custom-select-values [(options)]="detail.L_EFFICIENCY_PROBLEMS" [disable]="!isEditable"
                      *ngIf="!!sectionLayout['id'] && sectionLayout['id'] == 'LYO_SEC_EFFICIENCY' && sectionLayout.dataset.length > 0 &&  !!detail && !!detail.L_EFFICIENCY_PROBLEMS"
                      [title]="'Efficiency Problems'">
                    </custom-select-values>
                  </ng-container>
                </div>
              </div>

            </div>

          </div>

          <div class="row mt-5">
            <div class="col-8">
              <div class="row align-items-center attachment-title">
                <div class="d-flex flex-row align-items-center">
                  <label class="fs-6 fw-bold">Attachments</label>
                  <custom-btn [iconButton]="true" [iconClasses]="'cloud_upload'" (click)="uploadDoc()">
                  </custom-btn>
                </div>
              </div>
              <div class="attachments-area">
                <div class="row pt-1 pb-1 w-100 pl-5 rounded align-items-center document-row"
                  *ngFor="let document of attachments">
                  <div class="col-11">
                    <label class="fs-6 fw-normal section-title">{{ document.FILE_NAME }}</label>
                  </div>
                  <div class="col-1">
                    <div class="row-btn-container">
                      <custom-btn [iconButton]="true" [iconClasses]="'delete'" [fontSize]="'16px'"
                        (click)="deleteDoc(detail.DAMAGE_ID , document.DOCUMENT_ID)">
                      </custom-btn>
                      <custom-btn [iconButton]="true" [iconClasses]="'cloud_download'" [fontSize]="'16px'"
                        (click)="downloadDoc(detail.DAMAGE_ID , document.DOCUMENT_ID, document.FILE_TYPE, document.FILE_NAME)">
                      </custom-btn>
                    </div>
                  </div>


                </div>
              </div>

            </div>

            <div class="col-4" *ngIf="!!detail">
              <ng-container *ngFor="let data of (sectionLayout['dataset'])[0].datas">
                <ng-container *ngIf="data.columnName == 'REMARKS'">
                  <div class="row align-items-center justify-content-between attachment-title">
                    <div class="col-12">
                      <label class="fs-6 fw-bold filter-title">{{data.columnLabel | translate}}</label>
                    </div>
                  </div>
                  <div class="text-area">
                    <textarea class="form-control text-area" matInput [(ngModel)]="detail[data.columnName]"
                      placeholder="{{data.columnLabel | translate}}" [disabled]="!isEditable"></textarea>
                  </div>

                </ng-container>
              </ng-container>
            </div>

          </div>
        </ng-container>




      </div>
    </ng-container>

    <ng-container *ngIf="sectionLayout['id'] == 'LYO_SEC_BUTTON' && !!detail">
      <action-bar>
        <div leftBtn class="col-auto">
          <custom-btn backNavigation color="primary" [masterFrom]="detailBck" [compareTo]="detail">{{historySize() > 1 ? 'Back' : 'Home'}}</custom-btn>
        </div>
        <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0].actions">
          <div class="col-auto" *ngIf="action.id == 'ACT_WITHDRAW'">
            <custom-btn color="accent" (click)="onChangeStatusClick('W')"
              [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Cancel Claim</custom-btn>
          </div>
          <div class="col-auto" *ngIf="action.id == 'ACT_REOPEN'">
            <custom-btn color="accent" (click)="onChangeStatusClick('D')"
              [disabled]="!!detail && !(detail.STATUS == 'CANCELED')">Delete</custom-btn>
          </div>
        </ng-container>
        <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0].actions">
          <div class="col-auto" *ngIf="action.id == 'ACT_SAVE_SEND'">
            <custom-btn color="accent" (click)="onSaveAndActionClick('S')"
              [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Save & Send</custom-btn>
          </div>
          <div class="col-auto" *ngIf="action.id == 'ACT_SAVE_PREVIEW'">
            <custom-btn color="accent" (click)="onSaveAndActionClick('P')"
              [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Save & Preview</custom-btn>
          </div>
        </ng-container>
        <ng-container rightBtn *ngFor="let action of sectionLayout.actionset[0].actions">
          <div class="col-auto" *ngIf="action.id == 'ACT_SAVE'">
            <custom-btn color="accent" (click)="onSaveClick($event, false)"
              [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Save</custom-btn>
          </div>
          <div class="col-auto" *ngIf="action.id == 'ACT_SAVE'">
            <custom-btn color="accent" (click)="onSaveClick($event, true)"
              [disabled]="!!detail && (detail.STATUS == 'CANCELED')">Save & Close</custom-btn>
          </div>
        </ng-container>


      </action-bar>
    </ng-container>
  </div>
</ng-container>
