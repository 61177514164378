import { Component, Injectable, Input, Output } from '@angular/core';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  DateFormat,
  DATEMODEL,
} from './custom-datepicker.component.service';

const dateFormat: DateFormat = {
  firstValue: DATEMODEL.DD,
  secondValue: DATEMODEL.MM,
  thirdValue: DATEMODEL.YYYY,
  delimiter: '/',
};

@Component({
  selector: 'custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  // NOTE: For this example we are only providing current component, but probably
  // NOTE: you will want to provide your main App Module
  providers: [
    {
      provide: 'dateFormat',
      useValue: dateFormat,
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class CustomDatepickerComponent {
  //model: String | undefined;

  @Input()
  value: String = '';

  @Input()
  disabled: Boolean | undefined = false;

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  model: NgbDateStruct | undefined;

  constructor(
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    if (this.value.includes('/')) {
      this.model = {
        day: +this.value.split('/')[0].replace('/', '').padStart(2, '0'),
        month: +this.value.split('/')[1].replace('/', '').padStart(2, '0'),
        year: +this.value.split('/')[2].replace('/', ''),
      };
    }
  }

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }

  onDateChange(e: any) {
    this.valueChange.emit(e);
  }

  onFocusOut() {
    if (!!this.value && this.value.includes('/')) {
      this.model = {
        day: +this.value.split('/')[0].replace('/', '').padStart(2, '0'),
        month: +this.value.split('/')[1].replace('/', '').padStart(2, '0'),
        year: +this.value.split('/')[2].replace('/', ''),
      };
      this.valueChange.emit(
        '' + this.model.day + '/' + this.model.month + '/' + this.model.year
      );
    }
  }
}
