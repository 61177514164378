import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {AppTranslation} from "../models/response/AppTranslation";

@Injectable({
	providedIn: 'root'
})
export class TranslationService
{

	constructor(private httpClient: HttpClient) { }

	getLanguage(lang: string)
	{
		const httpHeader: Object = {
			params: new HttpParams().set('language-id', lang)
		};
		return this.httpClient.get<any>(
			environment.apiUrl + 'translation/app-translation',
			httpHeader
		).pipe(
			map(res =>
			{
				if (res && res['oContent'] && res['oContent']['content'])
				{
					return JSON.parse(res['oContent']['content'])
				}
				return res
			})
		);
	}

	getAvailableTranslations(languageId: string, first: number, rows: number, totalRecords: number, translationId: string, value: string ): Observable<any>
	{
		let page: number = totalRecords === 0 ? 0 : first / rows;

		const httpHeader: Object = {
			params: new HttpParams().set("language-id", languageId).set("size", rows).set("page", page)
			.set("translation-id", translationId).set("value", value)
		};
		return this.httpClient.get<Array<string>>(
			//'http://localhost:8082/v1/app-translation/list-paginated',
			environment.apiUrl + 'translation/app-translation/list-paginated',
			httpHeader
		);
	}

	updateTranslation(recordToUpdate: AppTranslation): Observable<AppTranslation>
	{

		return this.httpClient.put<AppTranslation>(
			environment.apiUrl + 'translation/app-translation',
			recordToUpdate
		);

	}

	insertTranslation(recordToUpdate: AppTranslation): Observable<AppTranslation>
	{

		return this.httpClient.post<AppTranslation>(
			environment.apiUrl + 'translation/app-translation',
			recordToUpdate
		);

	}

	deleteTranslation(languageId: string, translationId: string): Observable<void>
	{
		const httpHeader: Object = {
			params: new HttpParams().set("language-id", languageId).set("translation-id", translationId)
		};
		return this.httpClient.delete<void>(
			environment.apiUrl + 'translation/app-translation',
			httpHeader
		);

	}

	exportTranslation(appId: string, languageId: string): Observable<any>
	{
		const httpHeader: Object = {
			params: new HttpParams().set("language-id", languageId),
			responseType: 'blob'
		};
		return this.httpClient.get(environment.apiUrl + 'translation/app-translation/export-upload-excel', httpHeader)
	}

	importTranslation(languageId: string, formData: FormData): Observable<any>
	{
		const httpHeader: Object = {
			params: new HttpParams().set("language-id", languageId)
		};

		// Make the POST request to the backend
		return this.httpClient.post(environment.apiUrl + 'translation/app-translation/upload-excel', formData, httpHeader);
	}

	deleteTranslations(languageId: string, body: AppTranslation[]): Observable<any>
	{
		// Make the POST request to the backend
		return this.httpClient.post(environment.apiUrl + 'translation/app-translation/delete-translations', body);
	}

	getAvailableLanguages()
	{
		const httpHeader: Object = {
			params: new HttpParams()
		};
		return this.httpClient.get<Array<string>>(
			environment.apiUrl + 'translation/app-language/available-languages',
			httpHeader
		);
	}


}