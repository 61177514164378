import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-scorecard-efficiency-problem-dialog',
  templateUrl: './scorecard-efficiency-problem-dialog.component.html',
  styleUrls: ['./scorecard-efficiency-problem-dialog.component.scss']

})
export class ScorecardEfficiencyProblemDialogComponent {

  isEditable: Boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ScorecardEfficiencyProblemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

}
