import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {FilterInterface} from "../components/shared/filter/custom-filter/filter.interface";
import {Observable} from "rxjs";

@Injectable()
export class FilterControllerService {

  filterModels: FilterInterface[] = [];

  constructor() {}





}
