<button type="button" *ngIf="!(!!iconButton) || !iconButton" [disabled]="disabled" class="normal-btn btn-m fill-available" mat-raised-button
  style="margin: 0.1rem;
  padding: 0;" color="{{color!='basic'?color:''}}" [ngClass]="btnClasses ? btnClasses : ''">
  <ng-content select="[position=left]"></ng-content>
  <label [ngClass]="color==='primary' || color==='warn' ?  'white-label' : ''" class="mobile-btn">

    <ng-content></ng-content>

  </label>
  <ng-content select="[position=right]"></ng-content>
</button>

<button *ngIf="!!iconButton" mat-icon-button color="primary" class="icon-btn">
  <mat-icon [style.color]="iconColor != null ? iconColor : ''">{{iconClasses}}</mat-icon>
  <div class="divider"></div>
</button>
