<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.content}}</p>
  <p *ngIf="data.content2 != undefined">{{data.content2}}</p>
</div>
<div class="buttons-container" mat-dialog-actions>
  <custom-icon-btn [btnClasses]="'fill-available'" color="accent" [iconClasses]="'undo'" [mat-dialog-close]="'discard'"></custom-icon-btn>

  <custom-icon-btn [btnClasses]="'fill-available'" color="accent" [iconClasses]="'save'" [mat-dialog-close]="'save'"></custom-icon-btn>

  <custom-icon-btn [btnClasses]="'fill-available'" color="accent" [iconClasses]="'close'" [mat-dialog-close]="'stay'"></custom-icon-btn>

</div>
