import { Pipe, PipeTransform } from '@angular/core';
import {refactorName} from "../utils/generic";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {cloneDeep} from "lodash";
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
  name: 'file2Picture'
})
export class FileToPicturePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: File, ...args: unknown[]): Observable<SafeUrl> {

    return from(new Promise((resolve, _) => {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = () => {
        resolve('');
      }
      fileReader.readAsDataURL(value)
    })).pipe(map((v) => {
      return cloneDeep(this.sanitizer.bypassSecurityTrustUrl(v as string));
    }));
  }

}
