<div class="dialog-container">

  <h1 mat-dialog-title class="mb-3">{{ data.isNew ? 'New Product' : 'Edit Product'}}</h1>

  <div mat-dialog-content class="my-2">

    <div class="detail-content">
      <label class="fs-6 fw-bold filter-title">Serial</label>
      <custom-text-field [appearance]="'fill'" [placeholder]="data.columnLabel | translate"
                         [disabled]="data.isNew"
                         [(value)]="serial" [maxlength]="8" [pattern]="'[A-Za-z0-9]{8}'" [regexps]="serialRegExp"></custom-text-field>
    </div>

    <div class="d-flex flex-row justify-content-around mt-3">

      <p>App <i class="bi bi-record-circle" *ngIf="isApp" (click)="onRadioClick()"></i>
        <i class="bi bi-circle" *ngIf="!isApp" (click)="onRadioClick()"></i>
      </p>
      <p>Pack <i class="bi bi-record-circle" *ngIf="!isApp" (click)="onRadioClick()"></i>
        <i class="bi bi-circle" *ngIf="isApp" (click)="onRadioClick()"></i>
      </p>
    </div>

  </div>

  <div class="d-flex justify-content-between" mat-dialog-actions>
    <custom-icon-btn mat-dialog-close color="accent" [iconClasses]="'arrow_back'"></custom-icon-btn>
    <custom-icon-btn (click)="onAddClick()" class="float-right" color="accent" [iconClasses]="data.isNew ? 'add' : 'save'"></custom-icon-btn>
    <custom-icon-btn (click)="onAddAndCloseClick()" *ngIf="data.isNew"  class="float-right" color="accent" [iconClasses]="'save'"></custom-icon-btn>
  </div>
</div>
