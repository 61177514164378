<mat-accordion class="example-headers-align" multi
  *ngIf="!!filterController && !!filterController.filterModels && filterController.filterModels.length > 0">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <label></label>
        <mat-icon style="float: right" (click)="openDndDialog($event)">settings</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="row" >
      <div class="column-filter" >
        <div class="filter-panel-container">
          <ng-container *ngFor="let filterModel of filterController.filterModels; let i = index" >
              <custom-filter *ngIf="!filterModel.isHide"
                             [(filterModel)]="filterController.filterModels[i]"></custom-filter>
          </ng-container>

        </div>
      </div>

      <div class="column-btn">
        <custom-btn [color]="'primary'" (click)="applyFilter()">
          Search
        </custom-btn>

        <custom-btn [color]="'accent'" (click)="clearFilter()">
          Clear
        </custom-btn>

        <custom-btn [color]="'accent'" (click)="resetFilter()">
          Reset
        </custom-btn>
      </div>
    </div>



  </mat-expansion-panel>
</mat-accordion>
