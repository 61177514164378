import {HttpParams} from '@angular/common/http';
import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {WarehouseService} from 'src/app/services/crud/warehouse.service';
import {DndComponent} from '../../shared/dnd/dnd.component';
import {DialogData} from '../../shared/filter/filter-settings/filter-settings.component';
import {FromOtmComponent} from "../from-otm/from-otm.component";
import {QrCodeComponent} from "../../shared/qr-code/qr-code.component";
import * as _ from "lodash"
import {GeneralService} from "../../../services/crud/general.service";
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {SpinnerService} from "../../../services/spinner.service";
import {AlertService} from "../../shared/alert/alert.service";
import {NavigationService} from "../../../services/utils/navigation.service";
import {ALERT_TYPE} from "../../shared/alert/alert.enumerate";
import {lastValueFrom, of} from "rxjs";
import {ScorecardDetail} from "../../../models/response";
import {cloneDeep, isEmpty} from "lodash";
import {TranslateService} from "@ngx-translate/core";





@Component({
  selector: 'app-lane-selection',
  templateUrl: './lane-selection.component.html',
  styleUrls: ['./lane-selection.component.scss'],
})




export class LaneSelectionComponent implements OnInit {
  IMAGE_FILE = ['image/*', 'image/webp', 'image/avif', 'image/bmp', 'image/gif', 'image/vnd.microsoft.icon', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff']

  sources: any[] = [];
  destinations: any[] = [];

  selectedSource: String = '';
  selectedDestination: String = '';

  shipmentId?: string;

  fileToSend: File[] = [];

  damageType: string = '';

  apiUrl: string = '';

  apiParent: string = '';

  private pageId: string = '';



  detail: ScorecardDetail = {
    CARRIER_ID: "",
    CONTAINER: "",
    SCORECARD_APPLIANCES_QTY: 0,
    SCORECARD_PACKAGES_QTY: 0,
    DAMAGE_ID: "",
    DAMAGE_TYPE_ID: "",
    DELIVERY_MODE_ID: "",
    DELIVERY_TYPE_ID: "",
    DESPATCHED_QTY: 0,
    INSERT_DATE: "",
    INSERT_USER: "",
    L_EFFICIENCY_PROBLEMS: [],
    L_PROBLEMS: [],
    OTM_SHIPMENT_ID: "",
    RECEIVING_DATE: "",
    RECEIVING_WEEK: "",
    RECEIVING_WH: "",
    REMARKS: "",
    SCORECARD_ID: "",
    SENDING_DATE: "",
    SENDING_WH: "",
    SHIPMENT_ID: "",
    STATUS: "",
    TRAILER_PLATE: "",
    TRUCK_PLATE: "",
    UPDATE_DATE: "",
    UPDATE_USER: "",
    WAGON: "",
    DESTINATION_WAREHOUSE_ID:"",

  };

  constructor(
    private warehouseService: WarehouseService,
    private generalDataService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: SpinnerService,
    public alertService: AlertService,
    private navigation: NavigationService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    //private document:Document
  ) {
    this.route.url
      .pipe(map((segments) => segments.join('/')))
      .pipe(
        map((url) => {
          this.spinner.hideAll();
          this.apiUrl = url;
          this.route.parent?.url
            .pipe(map((segments) => segments.join('/')))
            .subscribe((finalParentUrl) => {
              this.apiParent = finalParentUrl;
            });
          return url;
        })
      )
      .subscribe((url) => {
        this.getQueryParam();
      });
  }

    // Function to prevent default scrolling behavior
  /*  private _preventDefault = (e) => e.preventDefault();

    // Function to disable scrolling
    private _disableScroll() {
      const options: AddEventListenerOptions = { passive: false };
      document.addEventListener('touchmove', this._preventDefault, options);
      document.addEventListener('touchforcechange', this._preventDefault, options);
  }
  
  private _enableScroll() {
      const options: AddEventListenerOptions = { passive: false };
      document.removeEventListener('touchmove', this._preventDefault, options);
      document.removeEventListener('touchforcechange', this._preventDefault, options);
  }*/
  
  
    // Method called when search input is focused
    /*onSearchFocus() {
      this.searchFocus.emit(true); // If you have EventEmitter defined in your component
      this._disableScroll(); // Disable scrolling when search input is focused
    }
  
    // Method called when search input is blurred
    onSearchBlur() {
      this.searchBlur.emit(true); // If you have EventEmitter defined in your component
      this._enableScroll(); // Enable scrolling when search input is blurred
    }*/

  getQueryParam() {
    this.route.queryParams
      .pipe(
        map((params) => {
          this.damageType = (params['t'] ?? '').toUpperCase();
          //this.isNew = params['n'] ?? 'false';
          this.pageId = params['id'] ?? '';
          return params;
        })
      )
      .subscribe({
        next: (params) => {
        },
        error: (err) => {
          //(err)
          this.alertService.add({
            type: ALERT_TYPE.DANGER,
            message: 'Some error occurred on layout retrive',
            timeout: 5000,
            selfClose: null,
          });
        },
      });
  }

  ngOnInit(): void {
    console.log("ciao_16")
    let params = new HttpParams();

    params = params.set('page', 1);
    params = params.set('size', 1000);

    this.generalDataService
      .getSelectionOption("table=\"DDT_WAREHOUSE\"; search_key=\"WAREHOUSE_ID\";return_value=\"[WAREHOUSE_ID_&_DESCRIPTION]\"")
      .then((res) => {

        this.sources = res;
        this.destinations = res;
      });

    // Add event listener to window scroll
    window.addEventListener("scroll", this.handleScroll, false);


  }

  /*
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Controlla se la larghezza del viewport è cambiata
    if (window.innerHeight > event.target.innerHeight) {
      // Esegui lo scrolling della finestra alla posizione desiderata
      window.scrollTo({
        top: -100,
        left: 0,
        behavior: "instant" as ScrollBehavior,
      });
    }
  }

*/


  
  ngOnDestroy(): void {
    // Remove event listener when component is destroyed
    window.removeEventListener("scroll", this.handleScroll, false);
  }
  handleScroll(event) {
    
    // Prevent default scroll behavior
    event.preventDefault();

    window.scrollTo({
      top: -100,
      left: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }
  


  onNextClick() {
    if (this.shipmentId != undefined && this.shipmentId != '' ) {
      this.detail.SHIPMENT_ID = this.shipmentId
      this.generalDataService.postDataDetail(
        this.apiParent + '/scorecards/detail',
        this.detail
      ).subscribe({
        next: value => {
          this.detail = value['oContent'];
          if (this.fileToSend) {
            Promise.all(
              this.fileToSend.map(file => lastValueFrom(
                this.generalDataService.uploadAttachment(
                  this.detail?.SCORECARD_ID ?? "null",
                  "null",
                  "SCORECARD",
                  "SCORECARD/"+this.detail?.SCORECARD_ID + "/",
                  file
                )
            ))).finally(() => {
              if (this.detail) {
                this.router.navigate(['page/' + this.apiParent + '/scorecards/detail'], {
                  state: {data: this.detail},
                  queryParams: {
                    id: this.pageId + '_DETAIL',
                    t: this.apiParent,
                    damage: this.detail['DAMAGE_ID'],
                    scr: this.detail['SCORECARD_ID'],
                    shp: this.shipmentId,
                    file:this.fileToSend
                  },
                });
              }
            });

          }else{
            if (this.detail) {
              this.router.navigate(['page/' + this.apiParent + '/scorecards/detail'], {
                state: {data: this.detail},
                queryParams: {
                  id: this.pageId + '_DETAIL',
                  t: this.apiParent,
                  damage: this.detail['DAMAGE_ID'],
                  scr: this.detail['SCORECARD_ID'],
                  shp: this.shipmentId
                },
              });
            }
          }

        }
      })
    }else{
      alert(this.translateService.instant('LABEL_INVALID_SHIPMENT'))
    }




  }


  qrCodeScanner() {
    const qrCodeDialog = this.dialog.open(QrCodeComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh'
    })

    qrCodeDialog.afterClosed().subscribe(closeResult => {
      if (!!closeResult) {

        if(closeResult.includes(';')){
          this.shipmentId = closeResult.split(';')[0]
        }else{
          if(closeResult.length > 9){
            this.shipmentId = closeResult.split('-')[0].slice(-9);
          }else{
            this.shipmentId = closeResult;
          }
        }

      }
    })
  }

  formatBytes(bytes: any, decimals: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  removeFile(event: MouseEvent, index: number) {
    this.fileToSend.splice(index, 1);
  }


  observable(number: number) {
    return of(number);
  }

  historySize() {
    return this.navigation.size()
  }


  uploadDoc() {
    const dialogEvent = this.dialog.open(DndComponent, {
      data: {
        title: 'UPLOADING',
        code: 'Upload file: ',
        message: 'Are you sure you want to upload this file?',
        btn: 'Upload',
        type: 'any',
        files: this.fileToSend,
        cancelBtn: 'Ext',
        confirmBtn: 'Ok'
      },
    });
    dialogEvent.afterClosed().subscribe((dndResponse) => {
      if (!!dndResponse && dndResponse.send) {
        this.fileToSend = dndResponse.files;
      }
    });
  }

  fileBrowseHandler(evt: FileList | null) {
    this.prepareFilesList(evt)
  }

  prepareFilesList(files: FileList | null) {
    console.log(files)
    for(let i=0; i< (files?.length ?? 0); i++){
      const file: File | null | undefined = files?.item(i);
      console.log(file)
      if(!!file){
        this.fileToSend.push(file);
      }
    }
  }




}
