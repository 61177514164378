import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {RegExpInterface} from "../../../models/RegExpInterface";

@Component({
  selector: 'app-serial',
  templateUrl: './serial.component.html',
  styleUrls: ['./serial.component.scss']
})
export class SerialComponent implements OnInit {

  serial: String = '';
  isApp: Boolean = false;

  serialRegExp:  RegExpInterface[] = [{"regExp":"/[^A-Za-z0-9]/g","replaceValue":""},{"regExp":"/(\\..*)\\./g","replaceValue":"$1"}];

  constructor(public dialogRef: MatDialogRef<SerialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
     }

  ngOnInit(): void {
    this.serial = this.data.pncSerial.serial;
    this.isApp = this.data.isApp;
  }

  onAddClick() {
    this.dialogRef.close({
      //pnc: this.data.pncSerial.pnc,
      serial: this.data.isNew ? this.data.pncSerial.serial : this.serial,
      isApp: this.isApp
    });
  }

  onRadioClick() {
    this.isApp = !this.isApp;
  }

  onAddAndCloseClick() {
    this.dialogRef.close({
      //pnc: this.data.pncSerial.pnc,
      serial: this.data.isNew ? this.data.pncSerial.serial : this.serial,
      isApp: this.isApp,
      stop: true
    });
  }
}
