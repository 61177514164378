import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'back-dialog',
  templateUrl: './back-dialog.component.html',
  styleUrls: ['./back-dialog.component.scss']
})
export class BackDialogComponent {

  @Output()
  onConfirm: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<BackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  onStayClick() {
    this.onConfirm.emit('stay');
    this.dialogRef.close();
  }

  onDeleteClick() {
    this.onConfirm.emit('delete');
    this.dialogRef.close();
  }

  onSaveClick() {
    this.onConfirm.emit('save');
    this.dialogRef.close()
  }
}
